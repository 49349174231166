import {
  EnumMultiSelectFieldV2Props,
  EnumRadioFieldProps,
  EnumSingleSelectFieldV2Props,
  FieldSchemaFormBuilderV2,
  NumericFieldProps,
  SchemaFactoryV2,
  TextFieldProps,
} from "@ucl/library";
import { Evaluation_HomeNCIUAEvaluationFormModel } from "../../../evaluation/types/HomeNCIUAEvaluationFormModel";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import {
  WildfireBoxContentExplorer,
  WildfireBoxContentExplorerProps,
} from "../../../../../../wildfire/components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";
import { RoofCoverMinSlopeTypes } from "../../../../common/types/BaseHomeEvaluationRoofingComplianceGeneralInformationFormModel";
import {
  MechanicalAdhesiveRoofTileAttachmentTypes,
  RoofCoverTypes,
  ShingleHailRatingTypes,
  StarterStripInstallationEavesTypes,
  StarterStripInstallationGlobeRakesTypes,
} from "../../../../common/types/BaseHomeEvaluationRoofingComplianceRoofCoverFormModel";
import {
  ReadonlyWarningBannerField,
  ReadonlyWarningBannerFieldProps,
} from "../../../../../Common/components/Fields/ReadonlyWarningBanner/ReadonlyWarningBannerField";
import { YesOrNoSelection } from "../../../../../../wildfire/types/FormFields";
import { HazardTypes } from "../../../../../Common/types/Evaluation/BaseEvaluation";
import {
  DistanceToSaltWaterShorelineTypes,
  NewConstructionOrExistingHomeTypes,
  NewOrExistingRoofTypes,
} from "../../../../common/types/BaseHomeEvaluationEligibilityFormModel";
import { permissionStore } from "../../../../../../foritfied/stores/PermissionStore";
import { SectionHeaderProps } from "@ucl/library/lib/components/FieldSchema/types";

const modelName = "Evaluation";
const subModelName = "HomeNCIUAEvaluation";
const formName = "RoofingComplianceRoofCoverForm";

const buildFieldKey = (fieldName: string) =>
  `${modelName}__${subModelName}__${formName}__${fieldName}`;

// Part 1: Roof Cover Information
const roofCoverInformationFields = (
  form: Evaluation_HomeNCIUAEvaluationFormModel
) => [
  buildFieldKey("RoofCoverType"),
  buildFieldKey("RoofCoverTypeMinSlope"),
  {
    fieldKey: buildFieldKey("RoofCoverTypeMinSlopeWarningBanner1"),
    hidden: !(
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .roofCoverTypeMinSlope === RoofCoverMinSlopeTypes.MinSlope012 &&
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.AsphaltShingle
      )
    ),
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey("RoofCoverTypeMinSlopeWarningBanner2"),
    hidden: !(
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .roofCoverTypeMinSlope === RoofCoverMinSlopeTypes.MinSlope112 &&
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.AsphaltShingle
      )
    ),
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey("RequiredWindRatingZone1"),
    hidden:
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.AsphaltShingle
      ),
  } as TextFieldProps,
  {
    fieldKey: buildFieldKey("RequiredWindRatingZone2"),
    hidden:
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.AsphaltShingle
      ),
  } as TextFieldProps,
  {
    fieldKey: buildFieldKey("RequiredWindRatingZone3"),
    hidden:
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.AsphaltShingle
      ),
  } as TextFieldProps,
  {
    fieldKey: buildFieldKey("AllZonesRequiredWindRatingDocumentation"),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden:
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.AsphaltShingle
      ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("FullyInstalledRoofCoverPhotos"),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden: !(
      form.homeNCIUAEvaluation.eligibilityForm.newOrExistingRoof !==
        NewOrExistingRoofTypes.ExistingRoof ||
      form.homeNCIUAEvaluation.eligibilityForm.newConstructionOrExistingHome !==
        NewConstructionOrExistingHomeTypes.ExistingHome
    ),
  } as WildfireBoxContentExplorerProps,
];

const roofCoverInformationRequiredFields = [
  buildFieldKey("RoofCoverType"),
  buildFieldKey("RoofCoverTypeMinSlope"),
  buildFieldKey("RequiredWindRatingZone1"),
  buildFieldKey("RequiredWindRatingZone2"),
  buildFieldKey("RequiredWindRatingZone3"),
  buildFieldKey("AllZonesRequiredWindRatingDocumentation"),
];

export const roofingComplianceRoofCoverInformationBuilderNCIUA = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeNCIUAEvaluationFormModel>(
        modelName,
        roofCoverInformationFields(form),
        onValueChange,
        form,
        errors,
        roofCoverInformationRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
          WarningBanner: ReadonlyWarningBannerField,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel>;
};

// Part 2: Asphalt Shingle Roof Cover Details
const asphaltShingleRoofCoverDetailsFields = (
  form: Evaluation_HomeNCIUAEvaluationFormModel
) => [
  {
    fieldKey: buildFieldKey(
      "AreAsphaltShingleManufacturerAndProductDetailsKnown"
    ),
    hidden:
      !form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.AsphaltShingle
      ),
  },
  {
    fieldKey: buildFieldKey("AsphaltShingleManufacturerName"),
    hidden: !(
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .areAsphaltShingleManufacturerAndProductDetailsKnown ===
        YesOrNoSelection.Yes &&
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.AsphaltShingle
      )
    ),
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey("AsphaltShingleProductName"),
    hidden: !(
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .areAsphaltShingleManufacturerAndProductDetailsKnown ===
        YesOrNoSelection.Yes &&
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.AsphaltShingle
      )
    ),
  } as TextFieldProps,
  {
    fieldKey: buildFieldKey("ShingleManufacturerAndProductPhoto"),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden: !(
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .areAsphaltShingleManufacturerAndProductDetailsKnown ===
        YesOrNoSelection.Yes &&
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.AsphaltShingle
      )
    ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey(
      "DoesAsphaltShingleWindRatingMeetASTMClassFOrClassH"
    ),
    hidden: !(
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .areAsphaltShingleManufacturerAndProductDetailsKnown ===
        YesOrNoSelection.Yes &&
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.AsphaltShingle
      )
    ),
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey("AsphaltShingleWindRatingTypes"),
    hidden: !(
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .doesAsphaltShingleWindRatingMeetASTMClassFOrClassH ===
        YesOrNoSelection.Yes &&
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.AsphaltShingle
      )
    ),
  } as EnumMultiSelectFieldV2Props,
  {
    fieldKey: buildFieldKey("AsphaltShingleWindRatingTypesWarningBanner"),
    hidden: !(
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .doesAsphaltShingleWindRatingMeetASTMClassFOrClassH ===
      YesOrNoSelection.No
    ),
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey("ShingleWindRatingPhoto"),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden: !(
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .doesAsphaltShingleWindRatingMeetASTMClassFOrClassH ===
        YesOrNoSelection.Yes &&
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.AsphaltShingle
      )
    ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("AsphaltShingleHailRatingType"),
    hidden: !(
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .areAsphaltShingleManufacturerAndProductDetailsKnown ===
        YesOrNoSelection.Yes &&
      form.homeNCIUAEvaluation.eligibilityForm.hasHailSupplement ===
        YesOrNoSelection.Yes &&
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.AsphaltShingle
      )
    ),
  } as EnumSingleSelectFieldV2Props,
  {
    fieldKey: buildFieldKey("AsphaltShingleHailRatingTypeWarningBanner"),
    hidden: !(
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .asphaltShingleHailRatingType === ShingleHailRatingTypes.NoneOfTheAbove
    ),
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey("ShingleHailRatingPhoto"),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden: !(
      form.homeNCIUAEvaluation.eligibilityForm.hasHailSupplement ===
        YesOrNoSelection.Yes &&
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .asphaltShingleHailRatingType !==
        ShingleHailRatingTypes.NoneOfTheAbove &&
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .areAsphaltShingleManufacturerAndProductDetailsKnown ===
        YesOrNoSelection.Yes &&
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.AsphaltShingle
      )
    ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey(
      "DoAsphaltShingleNailsMeetFortifiedCorrosionResistanceRequirements"
    ),
    hidden: !(
      form.hazard === HazardTypes.Hurricane &&
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.AsphaltShingle
      ) &&
      form.homeNCIUAEvaluation.eligibilityForm
        .distanceToSaltWaterShorelineType !==
        DistanceToSaltWaterShorelineTypes.GreaterThanThreeThousandFeet
    ),
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey(
      "DoAsphaltShingleNailsMeetFortifiedCorrosionResistanceRequirementsWarningBanner"
    ),
    hidden: !(
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .doAsphaltShingleNailsMeetFortifiedCorrosionResistanceRequirements ===
      YesOrNoSelection.No
    ),
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey(
      "AreAsphaltShinglesInstalledPerManufacturerRecommendationsForHighWindRegions"
    ),
    hidden: !(
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .areAsphaltShingleManufacturerAndProductDetailsKnown ===
        YesOrNoSelection.Yes &&
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.AsphaltShingle
      )
    ),
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey(
      "AreAsphaltShinglesInstalledPerManufacturerRecommendationsForHighWindRegionsWarningBanner"
    ),
    hidden: !(
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .areAsphaltShinglesInstalledPerManufacturerRecommendationsForHighWindRegions ===
      YesOrNoSelection.No
    ),
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey("NumberOfInstalledNailsPerAsphaltShingle"),
    hidden:
      !form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.AsphaltShingle
      ),
    showIncrementButtons: false,
    min: 1,
    max: 10,
    format: "0",
    decimals: 0,
  } as NumericFieldProps,
  {
    fieldKey: buildFieldKey("StartStripInstallationAtEavesTypes"),
    hidden:
      !form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.AsphaltShingle
      ),
  } as EnumMultiSelectFieldV2Props,
  {
    fieldKey: buildFieldKey("StartStripInstallationAtEavesTypesWarningBanner"),
    hidden:
      !form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.startStripInstallationAtEavesTypes?.includes(
        StarterStripInstallationEavesTypes.NoneOfTheAbove
      ),
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey("StartStripInstallationAtGlobeRakesTypes"),
    hidden:
      !form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.AsphaltShingle
      ),
  } as EnumMultiSelectFieldV2Props,
  {
    fieldKey: buildFieldKey(
      "StartStripInstallationAtGlobeRakesTypesWarningBanner"
    ),
    hidden:
      !form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.startStripInstallationAtGlobeRakesTypes?.includes(
        StarterStripInstallationGlobeRakesTypes.NoneOfTheAbove
      ),
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey("StarterStripManufacturerAndProductPhotos"),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden:
      !form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.AsphaltShingle
      ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("StarterStripInstallationPhotos"),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden:
      !form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.AsphaltShingle
      ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("AsphaltShingleRoofCoverMinSlopePhoto"),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden:
      !form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.AsphaltShingle
      ),
  } as WildfireBoxContentExplorerProps,
];

const asphaltShingleRoofCoverDetailsFieldsRequiredFields = [
  buildFieldKey("AreAsphaltShingleManufacturerAndProductDetailsKnown"),
  buildFieldKey("AsphaltShingleManufacturerName"),
  buildFieldKey("AsphaltShingleProductName"),
  buildFieldKey("AsphaltShingleWindRatingTypes"),
  buildFieldKey("DoesAsphaltShingleWindRatingMeetASTMClassFOrClassH"),
  buildFieldKey("AsphaltShingleHailRatingType"),
  buildFieldKey(
    "DoAsphaltShingleNailsMeetFortifiedCorrosionResistanceRequirements"
  ),
  buildFieldKey(
    "AreAsphaltShinglesInstalledPerManufacturerRecommendationsForHighWindRegions"
  ),
  buildFieldKey("NumberOfInstalledNailsPerAsphaltShingle"),
  buildFieldKey("StartStripInstallationAtEavesTypes"),
  buildFieldKey("StartStripInstallationAtGlobeRakesTypes"),
  buildFieldKey("StarterStripManufacturerAndProductPhotos"),
  buildFieldKey("ShingleManufacturerAndProductPhoto"),
  buildFieldKey("ShingleWindRatingPhoto"),
  buildFieldKey("ShingleHailRatingPhoto"),
  buildFieldKey("StarterStripInstallationPhotos"),
  buildFieldKey("AsphaltShingleRoofCoverMinSlopePhoto"),
];

export const asphaltShingleRoofCoverDetailsBuilderNCIUA = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeNCIUAEvaluationFormModel>(
        modelName,
        asphaltShingleRoofCoverDetailsFields(form),
        onValueChange,
        form,
        errors,
        asphaltShingleRoofCoverDetailsFieldsRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
          WarningBanner: ReadonlyWarningBannerField,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel>;
};

// Part 3: Metal Roof Cover Details
const metalRoofCoverDetailsFields = (
  form: Evaluation_HomeNCIUAEvaluationFormModel
) => [
  {
    fieldKey: buildFieldKey("AreMetalPanelManufacturerAndProductDetailsKnown"),
    hidden:
      !form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.MetalPanel
      ),
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey("MetalPanelManufacturerName"),
    hidden: !(
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .areMetalPanelManufacturerAndProductDetailsKnown ===
        YesOrNoSelection.Yes &&
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.MetalPanel
      )
    ),
  } as TextFieldProps,
  {
    fieldKey: buildFieldKey("MetalPanelProductName"),
    hidden: !(
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .areMetalPanelManufacturerAndProductDetailsKnown ===
        YesOrNoSelection.Yes &&
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.MetalPanel
      )
    ),
  } as TextFieldProps,
  {
    fieldKey: buildFieldKey(
      "MetalPanelCertifiedTestReportProductApprovalNumber"
    ),
    hidden: !(
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .areMetalPanelManufacturerAndProductDetailsKnown ===
        YesOrNoSelection.Yes &&
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.MetalPanel
      )
    ),
  } as TextFieldProps,
  {
    fieldKey: buildFieldKey("MetalPanelCertifiedTestReportDocumentation"),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden: !(
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .areMetalPanelManufacturerAndProductDetailsKnown ===
        YesOrNoSelection.Yes &&
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.MetalPanel
      )
    ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey(
      "MetalPanelSelectNumberOfDifferentSystemsFromCertifiedTestReport"
    ),
    hidden: !(
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .areMetalPanelManufacturerAndProductDetailsKnown ===
        YesOrNoSelection.Yes &&
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.MetalPanel
      )
    ),
    min: 1,
    max: 3,
    format: "0",
    decimals: 0,
  } as NumericFieldProps,
  {
    fieldKey: buildFieldKey(
      "MetalPanelFirstInstalledSystemNumberPerCertifiedTestReport"
    ),
    hidden: !(
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .areMetalPanelManufacturerAndProductDetailsKnown ===
        YesOrNoSelection.Yes &&
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.MetalPanel
      )
    ),
  } as TextFieldProps,
  {
    fieldKey: buildFieldKey(
      "MatelPanelFirstInstalledSystemDesignPressureOrMomentResistanceRating"
    ),
    hidden: !(
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .areMetalPanelManufacturerAndProductDetailsKnown ===
        YesOrNoSelection.Yes &&
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.MetalPanel
      )
    ),
  } as TextFieldProps,
  {
    fieldKey: buildFieldKey(
      "MetalPanelSecondInstalledSystemNumberPerCertifiedTestReport"
    ),
    hidden: !(
      Number(
        form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
          .metalPanelSelectNumberOfDifferentSystemsFromCertifiedTestReport
      ) > 1 &&
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.MetalPanel
      )
    ),
  } as TextFieldProps,
  {
    fieldKey: buildFieldKey(
      "MetalPanelSecondInstalledSystemDesignPressureOrMomentResistanceRating"
    ),
    hidden: !(
      Number(
        form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
          .metalPanelSelectNumberOfDifferentSystemsFromCertifiedTestReport
      ) > 1 &&
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.MetalPanel
      )
    ),
  } as TextFieldProps,
  {
    fieldKey: buildFieldKey(
      "MetalPanelThirdInstalledSystemNumberPerCertifiedTestReport"
    ),
    hidden: !(
      Number(
        form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
          .metalPanelSelectNumberOfDifferentSystemsFromCertifiedTestReport
      ) > 2 &&
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.MetalPanel
      )
    ),
  } as TextFieldProps,
  {
    fieldKey: buildFieldKey(
      "MetalPanelThirdInstalledSystemDesignPressureOrMomentResistanceRating"
    ),
    hidden: !(
      Number(
        form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
          .metalPanelSelectNumberOfDifferentSystemsFromCertifiedTestReport
      ) > 2 &&
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.MetalPanel
      )
    ),
  } as TextFieldProps,
  {
    fieldKey: buildFieldKey(
      "MetalPanelAreDesignRatingsOfInstalledSystemsListedInCertifiedTestReport"
    ),
    hidden: !(
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .areMetalPanelManufacturerAndProductDetailsKnown ===
        YesOrNoSelection.Yes &&
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.MetalPanel
      )
    ),
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey(
      "MetalPanelAreDesignRatingsOfInstalledSystemsListedInCertifiedTestReportWarningBanner"
    ),
    hidden:
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .metalPanelAreDesignRatingsOfInstalledSystemsListedInCertifiedTestReport !==
      YesOrNoSelection.No,
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey(
      "DoMetalPanelsAndExposedFastenersMeetFortifiedCorrosionResistanceRequirements"
    ),
    hidden: !(
      form.hazard === HazardTypes.Hurricane &&
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.MetalPanel
      ) &&
      form.homeNCIUAEvaluation.eligibilityForm
        .distanceToSaltWaterShorelineType !==
        DistanceToSaltWaterShorelineTypes.GreaterThanThreeThousandFeet
    ),
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey(
      "DoMetalPanelsAndExposedFastenersMeetFortifiedCorrosionResistanceRequirementsWarningBanner"
    ),
    hidden:
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .doMetalPanelsAndExposedFastenersMeetFortifiedCorrosionResistanceRequirements !==
      YesOrNoSelection.No,
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey("InstalledMetalPanelMaterial"),
    hidden:
      !form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.MetalPanel
      ),
  } as TextFieldProps,
  {
    fieldKey: buildFieldKey("InstalledMetalPanelGaugeThickness"),
    hidden:
      !form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.MetalPanel
      ),
  } as TextFieldProps,
  {
    fieldKey: buildFieldKey("InstalledMetalPanelFastenerTypeAndSize"),
    hidden:
      !form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.MetalPanel
      ),
  } as TextFieldProps,
  {
    fieldKey: buildFieldKey("MetalPanelFastenersPhoto"),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden:
      !form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.MetalPanel
      ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldName: "",
    fieldKey: "",
    type: "Section",
    label: "Installed fastener pattern and spacing",
  } as SectionHeaderProps,
  {
    fieldKey: buildFieldKey(
      "InstalledMetalPanelFastenerPatternAndSpacingZone1"
    ),
    hidden:
      !form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.MetalPanel
      ),
  } as TextFieldProps,
  {
    fieldKey: buildFieldKey(
      "InstalledMetalPanelFastenerPatternAndSpacingZone2"
    ),
    hidden:
      !form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.MetalPanel
      ),
  } as TextFieldProps,
  {
    fieldKey: buildFieldKey(
      "InstalledMetalPanelFastenerPatternAndSpacingZone3"
    ),
    hidden:
      !form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.MetalPanel
      ),
  } as TextFieldProps,
  {
    fieldKey: buildFieldKey(
      "MetalPanelInstalledFastenerPatternAndSpacingPhotos"
    ),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden:
      !form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.MetalPanel
      ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey(
      "WasMetalPanelRoofCoverSystemInstalledInAccordanceToStandards"
    ),
    hidden: !(
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .areMetalPanelManufacturerAndProductDetailsKnown ===
        YesOrNoSelection.Yes &&
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.MetalPanel
      )
    ),
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey(
      "WasMetalPanelRoofCoverSystemInstalledInAccordanceToStandardsWarningBanner"
    ),
    hidden:
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .wasMetalPanelRoofCoverSystemInstalledInAccordanceToStandards !==
      YesOrNoSelection.No,
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey("DoesMetalPanelSystemHaveUL2218Class4ImpactRating"),
    hidden: !(
      form.homeNCIUAEvaluation.eligibilityForm.hasHailSupplement ===
      YesOrNoSelection.Yes
    ),
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey(
      "DoesMetalPanelSystemHaveUL2218Class4ImpactRatingWarningBanner"
    ),
    hidden:
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .doesMetalPanelSystemHaveUL2218Class4ImpactRating !==
      YesOrNoSelection.No,
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey("MetalPanelRoofCoverMinSlopePhoto"),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden:
      !form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.MetalPanel
      ),
  } as WildfireBoxContentExplorerProps,
];

const metalRoofCoverDetailsFieldsRequiredFields = [
  buildFieldKey("AreMetalPanelManufacturerAndProductDetailsKnown"),
  buildFieldKey("MetalPanelManufacturerName"),
  buildFieldKey("MetalPanelProductName"),
  buildFieldKey("MetalPanelCertifiedTestReportProductApprovalNumber"),
  buildFieldKey("MetalPanelCertifiedTestReportDocumentation"),
  buildFieldKey(
    "MetalPanelSelectNumberOfDifferentSystemsFromCertifiedTestReport"
  ),
  buildFieldKey("MetalPanelFirstInstalledSystemNumberPerCertifiedTestReport"),
  buildFieldKey(
    "MatelPanelFirstInstalledSystemDesignPressureOrMomentResistanceRating"
  ),
  buildFieldKey("MetalPanelSecondInstalledSystemNumberPerCertifiedTestReport"),
  buildFieldKey(
    "MetalPanelSecondInstalledSystemDesignPressureOrMomentResistanceRating"
  ),
  buildFieldKey("MetalPanelThirdInstalledSystemNumberPerCertifiedTestReport"),
  buildFieldKey(
    "MetalPanelThirdInstalledSystemDesignPressureOrMomentResistanceRating"
  ),
  buildFieldKey(
    "MetalPanelAreDesignRatingsOfInstalledSystemsListedInCertifiedTestReport"
  ),
  buildFieldKey(
    "DoMetalPanelsAndExposedFastenersMeetFortifiedCorrosionResistanceRequirements"
  ),
  buildFieldKey("InstalledMetalPanelMaterial"),
  buildFieldKey("InstalledMetalPanelGaugeThickness"),
  buildFieldKey("InstalledMetalPanelFastenerTypeAndSize"),
  buildFieldKey("MetalPanelFastenersPhoto"),
  buildFieldKey("InstalledMetalPanelFastenerPatternAndSpacingZone1"),
  buildFieldKey("InstalledMetalPanelFastenerPatternAndSpacingZone2"),
  buildFieldKey("InstalledMetalPanelFastenerPatternAndSpacingZone3"),
  buildFieldKey("MetalPanelInstalledFastenerPatternAndSpacingPhotos"),
  buildFieldKey("WasMetalPanelRoofCoverSystemInstalledInAccordanceToStandards"),
  buildFieldKey("DoesMetalPanelSystemHaveUL2218Class4ImpactRating"),
  buildFieldKey("MetalPanelRoofCoverMinSlopePhoto"),
];

export const metalRoofCoverDetailsFieldsBuilderNCIUA = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeNCIUAEvaluationFormModel>(
        modelName,
        metalRoofCoverDetailsFields(form),
        onValueChange,
        form,
        errors,
        metalRoofCoverDetailsFieldsRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
          WarningBanner: ReadonlyWarningBannerField,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel>;
};

// Part 4: Clay Concrete Tile Roof Cover Details
const clayConcreteTileRoofCoverDetailsFields = (
  form: Evaluation_HomeNCIUAEvaluationFormModel
) => [
  {
    fieldKey: buildFieldKey(
      "AreTheClayConcreteTileManufacturerAndProductDetailsKnown"
    ),
    hidden:
      !form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.ClayOrConcreteTile
      ),
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey("ClayConcreteTileManufacturerName"),
    hidden: !(
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.ClayOrConcreteTile
      ) &&
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .areTheClayConcreteTileManufacturerAndProductDetailsKnown ===
        YesOrNoSelection.Yes
    ),
  } as TextFieldProps,
  {
    fieldKey: buildFieldKey("ClayConcreteTileProductName"),
    hidden: !(
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.ClayOrConcreteTile
      ) &&
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .areTheClayConcreteTileManufacturerAndProductDetailsKnown ===
        YesOrNoSelection.Yes
    ),
  } as TextFieldProps,
  {
    fieldKey: buildFieldKey("ClayConcreteTileTestReportApprovalNumber"),
    hidden: !(
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.ClayOrConcreteTile
      ) &&
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .areTheClayConcreteTileManufacturerAndProductDetailsKnown ===
        YesOrNoSelection.Yes
    ),
  } as TextFieldProps,
  {
    fieldKey: buildFieldKey("ClayConcreteTileCertifiedTestReportDocumentation"),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden: !(
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.ClayOrConcreteTile
      ) &&
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .areTheClayConcreteTileManufacturerAndProductDetailsKnown ===
        YesOrNoSelection.Yes
    ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey(
      "ClayConcreteTileNumberOfInstalledSystemsFromTestReport"
    ),
    hidden: !(
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.ClayOrConcreteTile
      ) &&
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .areTheClayConcreteTileManufacturerAndProductDetailsKnown ===
        YesOrNoSelection.Yes
    ),
    min: 1,
    max: 3,
    format: "0",
    decimals: 0,
  } as NumericFieldProps,
  {
    fieldKey: buildFieldKey(
      "ClayConcreteTileFirstInstalledSystemNumberPerCertifiedTestReport"
    ),
    hidden: !(
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.ClayOrConcreteTile
      ) &&
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .areTheClayConcreteTileManufacturerAndProductDetailsKnown ===
        YesOrNoSelection.Yes
    ),
  } as TextFieldProps,
  {
    fieldKey: buildFieldKey(
      "ClayConcreteTileFirstInstalledSystemDesignPressureOrMomentResistanceRating"
    ),
    hidden: !(
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.ClayOrConcreteTile
      ) &&
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .areTheClayConcreteTileManufacturerAndProductDetailsKnown ===
        YesOrNoSelection.Yes
    ),
  } as TextFieldProps,
  {
    fieldKey: buildFieldKey(
      "ClayConcreteTileSecondInstalledSystemNumberPerCertifiedTestReport"
    ),
    hidden: !(
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.ClayOrConcreteTile
      ) &&
      Number(
        form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
          .clayConcreteTileNumberOfInstalledSystemsFromTestReport
      ) > 1
    ),
  } as TextFieldProps,
  {
    fieldKey: buildFieldKey(
      "ClayConcreteTileSecondInstalledSystemDesignPressureOrMomentResistanceRating"
    ),
    hidden: !(
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.ClayOrConcreteTile
      ) &&
      Number(
        form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
          .clayConcreteTileNumberOfInstalledSystemsFromTestReport
      ) > 1
    ),
  } as TextFieldProps,
  {
    fieldKey: buildFieldKey(
      "ClayConcreteTileThirdInstalledSystemNumberPerCertifiedTestReport"
    ),
    hidden: !(
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.ClayOrConcreteTile
      ) &&
      Number(
        form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
          .clayConcreteTileNumberOfInstalledSystemsFromTestReport
      ) > 2
    ),
  } as TextFieldProps,
  {
    fieldKey: buildFieldKey(
      "ClayConcreteTileThirdInstalledSystemDesignPressureOrMomentResistanceRating"
    ),
    hidden: !(
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.ClayOrConcreteTile
      ) &&
      Number(
        form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
          .clayConcreteTileNumberOfInstalledSystemsFromTestReport
      ) > 2
    ),
  } as TextFieldProps,
  {
    fieldKey: buildFieldKey("ClayConcreteTileSystemsIdentifiedFromTestReport"),
    hidden: !(
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.ClayOrConcreteTile
      ) &&
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .areTheClayConcreteTileManufacturerAndProductDetailsKnown ===
        YesOrNoSelection.Yes
    ),
  } as TextFieldProps,
  {
    fieldKey: buildFieldKey(
      "ClayConcreteTileAreDesignRatingsOfInstalledSystemsListedInCertifiedTestReport"
    ),
    hidden: !(
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.ClayOrConcreteTile
      ) &&
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .areTheClayConcreteTileManufacturerAndProductDetailsKnown ===
        YesOrNoSelection.Yes
    ),
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey(
      "ClayConcreteTileAreDesignRatingsOfInstalledSystemsListedInCertifiedTestReportWarningBanner"
    ),
    hidden:
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .clayConcreteTileAreDesignRatingsOfInstalledSystemsListedInCertifiedTestReport !==
      YesOrNoSelection.No,
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey(
      "DoesClayConcreteTileRoofCoverSystemHaveFM4473Class4ImpactRating"
    ),
    hidden: !(
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.ClayOrConcreteTile
      ) &&
      form.homeNCIUAEvaluation.eligibilityForm.hasHailSupplement ===
        YesOrNoSelection.Yes
    ),
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey(
      "DoesClayConcreteTileRoofCoverSystemHaveFM4473Class4ImpactRatingWarningBanner"
    ),
    hidden:
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .doesClayConcreteTileRoofCoverSystemHaveFM4473Class4ImpactRating !==
      YesOrNoSelection.No,
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey(
      "ClayConcreteTileCertifiedTestReportProductApprovalNumberForStructuralSupports"
    ),
    hidden:
      !form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.ClayOrConcreteTile
      ),
  } as TextFieldProps,
  {
    fieldKey: buildFieldKey(
      "TileStructuralSupportsCertifiedTestReportDocumentation"
    ),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden:
      !form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.ClayOrConcreteTile
      ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("MechanicalAdhesiveRoofTileAttachmentType"),
    hidden:
      !form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.ClayOrConcreteTile
      ),
  } as EnumSingleSelectFieldV2Props,
  {
    fieldKey: buildFieldKey("MechanicalFastenerType"),
    hidden: !(
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.ClayOrConcreteTile
      ) &&
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .mechanicalAdhesiveRoofTileAttachmentType ===
        MechanicalAdhesiveRoofTileAttachmentTypes.Mechanical
    ),
  } as TextFieldProps,
  {
    fieldKey: buildFieldKey("RoofTileFastenersPhoto"),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden: !(
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.ClayOrConcreteTile
      ) &&
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .mechanicalAdhesiveRoofTileAttachmentType ===
        MechanicalAdhesiveRoofTileAttachmentTypes.Mechanical
    ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("MechanicalFastenerSize"),
    hidden: !(
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.ClayOrConcreteTile
      ) &&
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .mechanicalAdhesiveRoofTileAttachmentType ===
        MechanicalAdhesiveRoofTileAttachmentTypes.Mechanical
    ),
  } as TextFieldProps,
  {
    fieldKey: buildFieldKey("MechanicalFastenerQuantityPerTile"),
    hidden: !(
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.ClayOrConcreteTile
      ) &&
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .mechanicalAdhesiveRoofTileAttachmentType ===
        MechanicalAdhesiveRoofTileAttachmentTypes.Mechanical
    ),
  } as TextFieldProps,
  {
    fieldKey: buildFieldKey(
      "DoMechanicalTileFastenersMeetFortifiedCorrosionResistanceRequirements"
    ),
    hidden: !(
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.ClayOrConcreteTile
      ) &&
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .mechanicalAdhesiveRoofTileAttachmentType ===
        MechanicalAdhesiveRoofTileAttachmentTypes.Mechanical &&
      form.hazard === HazardTypes.Hurricane &&
      form.homeNCIUAEvaluation.eligibilityForm
        .distanceToSaltWaterShorelineType !==
        DistanceToSaltWaterShorelineTypes.GreaterThanThreeThousandFeet
    ),
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey(
      "DoMechanicalTileFastenersMeetFortifiedCorrosionResistanceRequirementsWarningBanner"
    ),
    hidden:
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .doMechanicalTileFastenersMeetFortifiedCorrosionResistanceRequirements !==
      YesOrNoSelection.No,
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey("AdhesiveFoamManufacturerName"),
    hidden: !(
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.ClayOrConcreteTile
      ) &&
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .mechanicalAdhesiveRoofTileAttachmentType ===
        MechanicalAdhesiveRoofTileAttachmentTypes.AdhesiveFoam
    ),
  } as TextFieldProps,
  {
    fieldKey: buildFieldKey("AdhesiveFoamProductName"),
    hidden: !(
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.ClayOrConcreteTile
      ) &&
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .mechanicalAdhesiveRoofTileAttachmentType ===
        MechanicalAdhesiveRoofTileAttachmentTypes.AdhesiveFoam
    ),
  } as TextFieldProps,
  {
    fieldKey: buildFieldKey("AdhesiveFoamTestReportApprovalNumber"),
    hidden: !(
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.ClayOrConcreteTile
      ) &&
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .mechanicalAdhesiveRoofTileAttachmentType ===
        MechanicalAdhesiveRoofTileAttachmentTypes.AdhesiveFoam
    ),
  } as TextFieldProps,
  {
    fieldKey: buildFieldKey(
      "IsAdhesiveFoamInstallerCertifiedByFoamManufacturer"
    ),
    hidden: !(
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.ClayOrConcreteTile
      ) &&
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .mechanicalAdhesiveRoofTileAttachmentType ===
        MechanicalAdhesiveRoofTileAttachmentTypes.AdhesiveFoam
    ),
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey(
      "IsAdhesiveFoamInstallerCertifiedByFoamManufacturerWarningBanner"
    ),
    hidden:
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .isAdhesiveFoamInstallerCertifiedByFoamManufacturer !==
      YesOrNoSelection.No,
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey("AdhesiveFoamInstallerCertificationNumber"),
    hidden: !(
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.ClayOrConcreteTile
      ) &&
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .isAdhesiveFoamInstallerCertifiedByFoamManufacturer ===
        YesOrNoSelection.Yes
    ),
  } as TextFieldProps,
  {
    fieldKey: buildFieldKey("TileUnderlaymentCertifiedTestReportNumber"),
    hidden:
      !form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.ClayOrConcreteTile
      ),
  } as TextFieldProps,
  {
    fieldKey: buildFieldKey("IsTileAndFlashingInstalledPerFRSATRIInstructions"),
    hidden: !(
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.ClayOrConcreteTile
      ) && form.hazard === HazardTypes.Hurricane
    ),
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey(
      "IsTileAndFlashingInstalledPerFRSATRIInstructionsWarningBanner"
    ),
    hidden:
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .isTileAndFlashingInstalledPerFRSATRIInstructions !==
      YesOrNoSelection.No,
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey(
      "IsTileAndFlashingInstalledPerTRIWSRCAInstructions"
    ),
    hidden: !(
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.ClayOrConcreteTile
      ) && form.hazard === HazardTypes.HighWind
    ),
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey(
      "IsTileAndFlashingInstalledPerTRIWSRCAInstructionsWarningBanner"
    ),
    hidden:
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .isTileAndFlashingInstalledPerTRIWSRCAInstructions !==
      YesOrNoSelection.No,
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey("ClayConcreteTileRoofCoverMinSlopePhoto"),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden:
      !form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.ClayOrConcreteTile
      ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("TileRoofCoverSystemInstallationPhoto"),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden:
      !form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.ClayOrConcreteTile
      ),
  } as WildfireBoxContentExplorerProps,
];

const clayConcreteTileRoofCoverDetailsRequiredFields = [
  buildFieldKey("AreTheClayConcreteTileManufacturerAndProductDetailsKnown"),
  buildFieldKey("ClayConcreteTileManufacturerName"),
  buildFieldKey("ClayConcreteTileProductName"),
  buildFieldKey("ClayConcreteTileTestReportApprovalNumber"),
  buildFieldKey("ClayConcreteTileCertifiedTestReportDocumentation"),
  buildFieldKey("ClayConcreteTileNumberOfInstalledSystemsFromTestReport"),
  buildFieldKey(
    "ClayConcreteTileFirstInstalledSystemNumberPerCertifiedTestReport"
  ),
  buildFieldKey(
    "ClayConcreteTileFirstInstalledSystemDesignPressureOrMomentResistanceRating"
  ),
  buildFieldKey(
    "ClayConcreteTileSecondInstalledSystemNumberPerCertifiedTestReport"
  ),
  buildFieldKey(
    "ClayConcreteTileSecondInstalledSystemDesignPressureOrMomentResistanceRating"
  ),
  buildFieldKey(
    "ClayConcreteTileThirdInstalledSystemNumberPerCertifiedTestReport"
  ),
  buildFieldKey(
    "ClayConcreteTileThirdInstalledSystemDesignPressureOrMomentResistanceRating"
  ),
  buildFieldKey("ClayConcreteTileSystemsIdentifiedFromTestReport"),
  buildFieldKey(
    "ClayConcreteTileAreDesignRatingsOfInstalledSystemsListedInCertifiedTestReport"
  ),
  buildFieldKey(
    "DoesClayConcreteTileRoofCoverSystemHaveFM4473Class4ImpactRating"
  ),
  buildFieldKey(
    "ClayConcreteTileCertifiedTestReportProductApprovalNumberForStructuralSupports"
  ),
  buildFieldKey("TileStructuralSupportsCertifiedTestReportDocumentation"),
  buildFieldKey("MechanicalAdhesiveRoofTileAttachmentType"),
  buildFieldKey("MechanicalFastenerType"),
  buildFieldKey("RoofTileFastenersPhoto"),
  buildFieldKey("MechanicalFastenerSize"),
  buildFieldKey("MechanicalFastenerQuantityPerTile"),
  buildFieldKey(
    "DoMechanicalTileFastenersMeetFortifiedCorrosionResistanceRequirements"
  ),
  buildFieldKey("AdhesiveFoamManufacturerName"),
  buildFieldKey("AdhesiveFoamProductName"),
  buildFieldKey("AdhesiveFoamTestReportApprovalNumber"),
  buildFieldKey("IsAdhesiveFoamInstallerCertifiedByFoamManufacturer"),
  buildFieldKey("AdhesiveFoamInstallerCertificationNumber"),
  buildFieldKey("TileUnderlaymentCertifiedTestReportNumber"),
  buildFieldKey("IsTileAndFlashingInstalledPerFRSATRIInstructions"),
  buildFieldKey("IsTileAndFlashingInstalledPerTRIWSRCAInstructions"),
  buildFieldKey("ClayConcreteTileRoofCoverMinSlopePhoto"),
];

export const clayConcreteTileRoofCoverDetailsBuilderNCIUA = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeNCIUAEvaluationFormModel>(
        modelName,
        clayConcreteTileRoofCoverDetailsFields(form),
        onValueChange,
        form,
        errors,
        clayConcreteTileRoofCoverDetailsRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
          WarningBanner: ReadonlyWarningBannerField,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel>;
};

// Part 5: Wood Shake Shingle Roof Cover Details
const woodShakeShingleRoofCoverDetailsFields = (
  form: Evaluation_HomeNCIUAEvaluationFormModel
) => [
  {
    fieldKey: buildFieldKey(
      "AreTheWoodShakeShingleManufacturerAndProductDetailsKnown"
    ),
    hidden:
      !form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.WoodShakeOrShingle
      ),
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey("WoodShakeShingleManufacturerName"),
    hidden: !(
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.WoodShakeOrShingle
      ) &&
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .areTheWoodShakeShingleManufacturerAndProductDetailsKnown ===
        YesOrNoSelection.Yes
    ),
  } as TextFieldProps,
  {
    fieldKey: buildFieldKey("WoodShakeShingleProductName"),
    hidden: !(
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.WoodShakeOrShingle
      ) &&
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .areTheWoodShakeShingleManufacturerAndProductDetailsKnown ===
        YesOrNoSelection.Yes
    ),
  } as TextFieldProps,
  {
    fieldKey: buildFieldKey("WoodShakeShingleTestReportApprovalNumber"),
    hidden: !(
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.WoodShakeOrShingle
      ) &&
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .areTheWoodShakeShingleManufacturerAndProductDetailsKnown ===
        YesOrNoSelection.Yes
    ),
  } as TextFieldProps,
  {
    fieldKey: buildFieldKey("WoodShakeShingleCertifiedTestReportDocumentation"),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden: !(
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.WoodShakeOrShingle
      ) &&
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .areTheWoodShakeShingleManufacturerAndProductDetailsKnown ===
        YesOrNoSelection.Yes
    ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey(
      "WoodShakeShingleDesignPressureWindRatingOfInstalledSystem"
    ),
    hidden: !(
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.WoodShakeOrShingle
      ) &&
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .areTheWoodShakeShingleManufacturerAndProductDetailsKnown ===
        YesOrNoSelection.Yes
    ),
    showIncrementButtons: false,
    min: 0,
    format: "0",
    decimals: 0,
  } as NumericFieldProps,
  {
    fieldKey: buildFieldKey(
      "WoodShakeShingleIsMeanRoofHeightLimitationPerManufacturerProductSpecifications"
    ),
    hidden: !(
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.WoodShakeOrShingle
      ) &&
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .areTheWoodShakeShingleManufacturerAndProductDetailsKnown ===
        YesOrNoSelection.Yes
    ),
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey("WoodShakeShingleMeanRoofHeightLimitation"),
    hidden: !(
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.WoodShakeOrShingle
      ) &&
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .woodShakeShingleIsMeanRoofHeightLimitationPerManufacturerProductSpecifications ===
        YesOrNoSelection.Yes
    ),
    showIncrementButtons: false,
    min: 0,
    format: "0",
    decimals: 0,
  } as NumericFieldProps,
  {
    fieldKey: buildFieldKey("WoodShakeShingleRequiredNumberOfNailsPerShingle"),
    hidden:
      !form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.WoodShakeOrShingle
      ),
    showIncrementButtons: false,
    min: 1,
    max: 10,
    format: "0",
    decimals: 0,
  } as NumericFieldProps,
  {
    fieldKey: buildFieldKey(
      "WoodShakeShingleDoesWindRatingListedInCertifiedTestReportMeetOrExceedDesignConditions"
    ),
    hidden: !(
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.WoodShakeOrShingle
      ) &&
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .areTheWoodShakeShingleManufacturerAndProductDetailsKnown ===
        YesOrNoSelection.Yes
    ),
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey(
      "WoodShakeShingleDoesWindRatingListedInCertifiedTestReportMeetOrExceedDesignConditionsWarningBanner"
    ),
    hidden:
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .woodShakeShingleDoesWindRatingListedInCertifiedTestReportMeetOrExceedDesignConditions !==
      YesOrNoSelection.No,
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey(
      "DoesWoodShakeShingleHaveUL2218Class4OrFM4473Class4ImpactRating"
    ),
    hidden: !(
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.WoodShakeOrShingle
      ) &&
      form.homeNCIUAEvaluation.eligibilityForm.hasHailSupplement ===
        YesOrNoSelection.Yes
    ),
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey(
      "DoesWoodShakeShingleHaveUL2218Class4OrFM4473Class4ImpactRatingWarningBanner"
    ),
    hidden:
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .doesWoodShakeShingleHaveUL2218Class4OrFM4473Class4ImpactRating !==
      YesOrNoSelection.No,
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey("WoodShakeShingleFastenerType"),
    hidden:
      !form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.WoodShakeOrShingle
      ),
  } as TextFieldProps,
  {
    fieldKey: buildFieldKey("WoodShakeShingleFastenersPhoto"),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("WoodShakeShingleFastenerSize"),
    hidden:
      !form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.WoodShakeOrShingle
      ),
  } as TextFieldProps,
  {
    fieldKey: buildFieldKey("WereStaplesUsedToInstallWoodShakesShingles"),
    hidden:
      !form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.WoodShakeOrShingle
      ),
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey(
      "WereStaplesUsedToInstallWoodShakesShinglesWarningBanner"
    ),
    hidden:
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .wereStaplesUsedToInstallWoodShakesShingles !== YesOrNoSelection.Yes,
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey(
      "WoodShakeShingleAreStainlessSteelFastenersRequired"
    ),
    hidden:
      !form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.WoodShakeOrShingle
      ),
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey(
      "WoodShakeShingleAreStainlessSteelFastenersRequiredWarningBanner"
    ),
    hidden:
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .woodShakeShingleAreStainlessSteelFastenersRequired !==
      YesOrNoSelection.No,
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey(
      "WereStainlessFastenersUsedToInstallWoodShakesShingles"
    ),
    hidden:
      !form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.WoodShakeOrShingle
      ),
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey(
      "WereStainlessFastenersUsedToInstallWoodShakesShinglesWarningBanner"
    ),
    hidden: !(
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .woodShakeShingleAreStainlessSteelFastenersRequired ===
        YesOrNoSelection.Yes &&
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .wereStainlessFastenersUsedToInstallWoodShakesShingles ===
        YesOrNoSelection.No
    ),
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey(
      "IsSealedRoofDeckMaterialCompatibleWithWoodShakeShingle"
    ),
    hidden:
      !form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.WoodShakeOrShingle
      ),
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey(
      "IsSealedRoofDeckMaterialCompatibleWithWoodShakeShingleWarningBanner"
    ),
    hidden:
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .isSealedRoofDeckMaterialCompatibleWithWoodShakeShingle !==
      YesOrNoSelection.No,
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey(
      "WasWoodShakeShingleRoofCoverSystemInstalledInAccordanceWithCertifiedTestReport"
    ),
    hidden: !(
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.WoodShakeOrShingle
      ) &&
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .areTheWoodShakeShingleManufacturerAndProductDetailsKnown ===
        YesOrNoSelection.Yes
    ),
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey(
      "WasWoodShakeShingleRoofCoverSystemInstalledInAccordanceWithCertifiedTestReportWarningBanner"
    ),
    hidden:
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .wasWoodShakeShingleRoofCoverSystemInstalledInAccordanceWithCertifiedTestReport !==
      YesOrNoSelection.No,
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey("WoodShakeShingleRoofCoverMinSlopePhoto"),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden:
      !form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.WoodShakeOrShingle
      ),
  } as WildfireBoxContentExplorerProps,
];

const woodShakeShingleRoofCoverDetailsFieldsRequiredFields = [
  buildFieldKey("AreTheWoodShakeShingleManufacturerAndProductDetailsKnown"),
  buildFieldKey("WoodShakeShingleManufacturerName"),
  buildFieldKey("WoodShakeShingleProductName"),
  buildFieldKey("WoodShakeShingleTestReportApprovalNumber"),
  buildFieldKey("WoodShakeShingleCertifiedTestReportDocumentation"),
  buildFieldKey("WoodShakeShingleDesignPressureWindRatingOfInstalledSystem"),
  buildFieldKey(
    "WoodShakeShingleIsMeanRoofHeightLimitationPerManufacturerProductSpecifications"
  ),
  buildFieldKey("WoodShakeShingleMeanRoofHeightLimitation"),
  buildFieldKey("WoodShakeShingleRequiredNumberOfNailsPerShingle"),
  buildFieldKey(
    "WoodShakeShingleDoesWindRatingListedInCertifiedTestReportMeetOrExceedDesignConditions"
  ),
  buildFieldKey(
    "DoesWoodShakeShingleHaveUL2218Class4OrFM4473Class4ImpactRating"
  ),
  buildFieldKey("WoodShakeShingleFastenerType"),
  buildFieldKey("WoodShakeShingleFastenersPhoto"),
  buildFieldKey("WoodShakeShingleFastenerSize"),
  buildFieldKey("WereStaplesUsedToInstallWoodShakesShingles"),
  buildFieldKey("WoodShakeShingleAreStainlessSteelFastenersRequired"),
  buildFieldKey("WereStainlessFastenersUsedToInstallWoodShakesShingles"),
  buildFieldKey("IsSealedRoofDeckMaterialCompatibleWithWoodShakeShingle"),
  buildFieldKey(
    "WasWoodShakeShingleRoofCoverSystemInstalledInAccordanceWithCertifiedTestReport"
  ),
  buildFieldKey("WoodShakeShingleRoofCoverMinSlopePhoto"),
];

export const woodShakeShingleRoofCoverDetailsFieldsBuilderNCIUA = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeNCIUAEvaluationFormModel>(
        modelName,
        woodShakeShingleRoofCoverDetailsFields(form),
        onValueChange,
        form,
        errors,
        woodShakeShingleRoofCoverDetailsFieldsRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
          WarningBanner: ReadonlyWarningBannerField,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel>;
};

// Part 6: Low Slope Roof Cover Details
const lowSlopeRoofCoverDetailsFields = (
  form: Evaluation_HomeNCIUAEvaluationFormModel
) => [
  {
    fieldKey: buildFieldKey(
      "AreTheLowSlopeRoofCoverManufacturerAndProductDetailsKnown"
    ),
    hidden:
      !form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.LowSlope
      ),
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey("LowSlopeRoofCoverManufacturerName"),
    hidden: !(
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.LowSlope
      ) &&
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .areTheLowSlopeRoofCoverManufacturerAndProductDetailsKnown ===
        YesOrNoSelection.Yes
    ),
  } as TextFieldProps,
  {
    fieldKey: buildFieldKey("LowSlopeRoofCoverProductName"),
    hidden: !(
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.LowSlope
      ) &&
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .areTheLowSlopeRoofCoverManufacturerAndProductDetailsKnown ===
        YesOrNoSelection.Yes
    ),
  } as TextFieldProps,
  {
    fieldKey: buildFieldKey("LowSlopeRoofCoverTestReportApprovalNumber"),
    hidden: !(
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.LowSlope
      ) &&
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .areTheLowSlopeRoofCoverManufacturerAndProductDetailsKnown ===
        YesOrNoSelection.Yes
    ),
  } as TextFieldProps,
  {
    fieldKey: buildFieldKey(
      "LowSlopeRoofCoverCertifiedTestReportDocumentation"
    ),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden: !(
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.LowSlope
      ) &&
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .areTheLowSlopeRoofCoverManufacturerAndProductDetailsKnown ===
        YesOrNoSelection.Yes
    ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey(
      "LowSlopeRoofCoverSelectNumberOfDifferentSystemsFromCertifiedTestReport"
    ),
    hidden: !(
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.LowSlope
      ) &&
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .areTheLowSlopeRoofCoverManufacturerAndProductDetailsKnown ===
        YesOrNoSelection.Yes
    ),
    min: 1,
    max: 3,
    format: "0",
    decimals: 0,
  } as NumericFieldProps,
  {
    fieldKey: buildFieldKey(
      "LowSlopeRoofCoverFirstInstalledSystemNumberPerCertifiedTestReport"
    ),
    hidden: !(
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.LowSlope
      ) &&
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .areTheLowSlopeRoofCoverManufacturerAndProductDetailsKnown ===
        YesOrNoSelection.Yes
    ),
  } as TextFieldProps,
  {
    fieldKey: buildFieldKey(
      "LowSlopeRoofCoverFirstInstalledSystemDesignPressureOrMomentResistanceRating"
    ),
    hidden: !(
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.LowSlope
      ) &&
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .areTheLowSlopeRoofCoverManufacturerAndProductDetailsKnown ===
        YesOrNoSelection.Yes
    ),
  } as TextFieldProps,
  {
    fieldKey: buildFieldKey(
      "LowSlopeRoofCoverSecondInstalledSystemNumberPerCertifiedTestReport"
    ),
    hidden: !(
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.LowSlope
      ) &&
      Number(
        form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
          .lowSlopeRoofCoverSelectNumberOfDifferentSystemsFromCertifiedTestReport
      ) > 1
    ),
  } as TextFieldProps,
  {
    fieldKey: buildFieldKey(
      "LowSlopeRoofCoverSecondInstalledSystemDesignPressureOrMomentResistanceRating"
    ),
    hidden: !(
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.LowSlope
      ) &&
      Number(
        form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
          .lowSlopeRoofCoverSelectNumberOfDifferentSystemsFromCertifiedTestReport
      ) > 1
    ),
  } as TextFieldProps,
  {
    fieldKey: buildFieldKey(
      "LowSlopeRoofCoverThirdInstalledSystemNumberPerCertifiedTestReport"
    ),
    hidden: !(
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.LowSlope
      ) &&
      Number(
        form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
          .lowSlopeRoofCoverSelectNumberOfDifferentSystemsFromCertifiedTestReport
      ) > 2
    ),
  } as TextFieldProps,
  {
    fieldKey: buildFieldKey(
      "LowSlopeRoofCoverThirdInstalledSystemDesignPressureOrMomentResistanceRating"
    ),
    hidden: !(
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.LowSlope
      ) &&
      Number(
        form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
          .lowSlopeRoofCoverSelectNumberOfDifferentSystemsFromCertifiedTestReport
      ) > 2
    ),
  } as TextFieldProps,
  {
    fieldKey: buildFieldKey(
      "LowSlopeRoofCoverAreDesignRatingsOfInstalledSystemsListedInCertifiedTestReport"
    ),
    hidden: !(
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.LowSlope
      ) &&
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .areTheLowSlopeRoofCoverManufacturerAndProductDetailsKnown ===
        YesOrNoSelection.Yes
    ),
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey(
      "LowSlopeRoofCoverAreDesignRatingsOfInstalledSystemsListedInCertifiedTestReportWarningBanner"
    ),
    hidden:
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .lowSlopeRoofCoverAreDesignRatingsOfInstalledSystemsListedInCertifiedTestReport !==
      YesOrNoSelection.No,
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey(
      "LowSlopeRoofCoverDoExposedFastenersMeetFortifiedCorrosionResistanceRequirements"
    ),
    hidden: !(
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.LowSlope
      ) &&
      form.hazard === HazardTypes.Hurricane &&
      form.homeNCIUAEvaluation.eligibilityForm
        .distanceToSaltWaterShorelineType !==
        DistanceToSaltWaterShorelineTypes.GreaterThanThreeThousandFeet
    ),
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey(
      "LowSlopeRoofCoverDoExposedFastenersMeetFortifiedCorrosionResistanceRequirementsWarningBanner"
    ),
    hidden:
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .lowSlopeRoofCoverDoExposedFastenersMeetFortifiedCorrosionResistanceRequirements !==
      YesOrNoSelection.No,
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey(
      "DoesLowSlopeRoofCoverSystemHaveADesignatedImpactRating"
    ),
    hidden: !(
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.LowSlope
      ) &&
      form.homeNCIUAEvaluation.eligibilityForm.hasHailSupplement ===
        YesOrNoSelection.Yes
    ),
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey(
      "DoesLowSlopeRoofCoverSystemHaveADesignatedImpactRatingWarningBanner"
    ),
    hidden:
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .doesLowSlopeRoofCoverSystemHaveADesignatedImpactRating !==
      YesOrNoSelection.No,
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey("LowSlopeRoofCoverFastenerTypeAndSize"),
    hidden:
      !form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.LowSlope
      ),
  } as TextFieldProps,
  {
    fieldKey: buildFieldKey("LowSlopeRoofCoverFastenerSpacingZone1"),
    hidden:
      !form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.LowSlope
      ),
  } as TextFieldProps,
  {
    fieldKey: buildFieldKey("LowSlopeRoofCoverFastenerSpacingZone2"),
    hidden:
      !form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.LowSlope
      ),
  } as TextFieldProps,
  {
    fieldKey: buildFieldKey("LowSlopeRoofCoverFastenerSpacingZone3"),
    hidden:
      !form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.LowSlope
      ),
  } as TextFieldProps,
  {
    fieldKey: buildFieldKey(
      "WasLowSlopeRoofCoverInstalledInAccordanceWithCertifiedTestReport"
    ),
    hidden: !(
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.LowSlope
      ) &&
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .areTheLowSlopeRoofCoverManufacturerAndProductDetailsKnown ===
        YesOrNoSelection.Yes
    ),
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey(
      "WasLowSlopeRoofCoverInstalledInAccordanceWithCertifiedTestReportWarningBanner"
    ),
    hidden:
      form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
        .wasLowSlopeRoofCoverInstalledInAccordanceWithCertifiedTestReport !==
      YesOrNoSelection.No,
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey("LowSlopeRoofCoverMinSlopePhoto"),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden:
      !form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm.roofCoverType?.includes(
        RoofCoverTypes.LowSlope
      ),
  } as WildfireBoxContentExplorerProps,
];

const lowSlopeRoofCoverDetailsFieldsRequiredFields = [
  buildFieldKey("AreTheLowSlopeRoofCoverManufacturerAndProductDetailsKnown"),
  buildFieldKey("LowSlopeRoofCoverManufacturerName"),
  buildFieldKey("LowSlopeRoofCoverProductName"),
  buildFieldKey("LowSlopeRoofCoverTestReportApprovalNumber"),
  buildFieldKey("LowSlopeRoofCoverCertifiedTestReportDocumentation"),
  buildFieldKey(
    "LowSlopeRoofCoverSelectNumberOfDifferentSystemsFromCertifiedTestReport"
  ),
  buildFieldKey(
    "LowSlopeRoofCoverFirstInstalledSystemNumberPerCertifiedTestReport"
  ),
  buildFieldKey(
    "LowSlopeRoofCoverFirstInstalledSystemDesignPressureOrMomentResistanceRating"
  ),
  buildFieldKey(
    "LowSlopeRoofCoverSecondInstalledSystemNumberPerCertifiedTestReport"
  ),
  buildFieldKey(
    "LowSlopeRoofCoverSecondInstalledSystemDesignPressureOrMomentResistanceRating"
  ),
  buildFieldKey(
    "LowSlopeRoofCoverThirdInstalledSystemNumberPerCertifiedTestReport"
  ),
  buildFieldKey(
    "LowSlopeRoofCoverThirdInstalledSystemDesignPressureOrMomentResistanceRating"
  ),
  buildFieldKey(
    "LowSlopeRoofCoverAreDesignRatingsOfInstalledSystemsListedInCertifiedTestReport"
  ),
  buildFieldKey(
    "LowSlopeRoofCoverDoExposedFastenersMeetFortifiedCorrosionResistanceRequirements"
  ),
  buildFieldKey("DoesLowSlopeRoofCoverSystemHaveADesignatedImpactRating"),
  buildFieldKey("LowSlopeRoofCoverFastenerTypeAndSize"),
  buildFieldKey("LowSlopeRoofCoverFastenerSpacingZone1"),
  buildFieldKey("LowSlopeRoofCoverFastenerSpacingZone2"),
  buildFieldKey("LowSlopeRoofCoverFastenerSpacingZone3"),
  buildFieldKey(
    "WasLowSlopeRoofCoverInstalledInAccordanceWithCertifiedTestReport"
  ),
  buildFieldKey("LowSlopeRoofCoverMinSlopePhoto"),
];

export const lowSlopeRoofCoverDetailsFieldsBuilderNCIUA = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeNCIUAEvaluationFormModel>(
        modelName,
        lowSlopeRoofCoverDetailsFields(form),
        onValueChange,
        form,
        errors,
        lowSlopeRoofCoverDetailsFieldsRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
          WarningBanner: ReadonlyWarningBannerField,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel>;
};

// Full form builder with all parts included
export const homeNCIUARoofingComplianceRoofCoverFormBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeNCIUAEvaluationFormModel>(
        modelName,
        [
          ...roofCoverInformationFields(form),
          ...asphaltShingleRoofCoverDetailsFields(form),
          ...metalRoofCoverDetailsFields(form),
          ...clayConcreteTileRoofCoverDetailsFields(form),
          ...woodShakeShingleRoofCoverDetailsFields(form),
          ...lowSlopeRoofCoverDetailsFields(form),
        ],
        onValueChange,
        form,
        errors,
        [
          ...roofCoverInformationRequiredFields,
          ...asphaltShingleRoofCoverDetailsFieldsRequiredFields,
          ...metalRoofCoverDetailsFieldsRequiredFields,
          ...clayConcreteTileRoofCoverDetailsRequiredFields,
          ...woodShakeShingleRoofCoverDetailsFieldsRequiredFields,
          ...lowSlopeRoofCoverDetailsFieldsRequiredFields,
        ],
        {
          FileUploader: WildfireBoxContentExplorer,
          WarningBanner: ReadonlyWarningBannerField,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel>;
};

// Full form builder with all parts included, for Iteration Engine
export const homeNCIUAIterationEngineRoofingComplianceRoofCoverFormBuilder = (
  factory: SchemaFactoryV2,
  beforeBuild?: (fields: BaseFieldProps<any>[]) => BaseFieldProps<any>[]
): FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    let fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeNCIUAEvaluationFormModel>(
        modelName,
        [
          ...roofCoverInformationFields(form),
          ...asphaltShingleRoofCoverDetailsFields(form),
          ...metalRoofCoverDetailsFields(form),
          ...clayConcreteTileRoofCoverDetailsFields(form),
          ...woodShakeShingleRoofCoverDetailsFields(form),
          ...lowSlopeRoofCoverDetailsFields(form),
        ],
        onValueChange,
        form,
        errors,
        [
          ...roofCoverInformationRequiredFields,
          ...asphaltShingleRoofCoverDetailsFieldsRequiredFields,
          ...metalRoofCoverDetailsFieldsRequiredFields,
          ...clayConcreteTileRoofCoverDetailsRequiredFields,
          ...woodShakeShingleRoofCoverDetailsFieldsRequiredFields,
          ...lowSlopeRoofCoverDetailsFieldsRequiredFields,
        ],
        {
          FileUploader: WildfireBoxContentExplorer,
          WarningBanner: ReadonlyWarningBannerField,
        }
      ),
    ];
    if (beforeBuild) {
      fields = beforeBuild(fields);
    }
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel>;
};

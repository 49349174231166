import { PrimaryCard, SecondaryCard } from "@ucl/library";
import { Loading } from "@ucl/library/lib/components/Loading/Loading";
import { Navigate } from "react-router-dom";
import FormChecklistProgress from "../../../../../../Common/components/Forms/FormChecklistProgress/FormChecklistProgress";
import { HomeStandardEvaluationEligibilityForm } from "../Form/HomeStandardEvaluationEligibilityForm";
import { fortifiedHomeStandardRoutePrefix } from "../../../../../../../foritfied/pages/index/fortifiedHomeRouteConfig";
import { FieldSchemaFactoryProvider } from "../../../../../customHooks/useHomeFieldSchemaFactory";
import { HomeEligibilityFormParts } from "../../../../../common/types/BaseHomeEvaluationEligibilityFormModel";
import useHomeStandardEligibilityFormContainer from "./useHomeStandardEligibilityFormContainer";
import GoToChecklistButton from "../../../../../common/components/Buttons/GoToChecklist/GoToIterationEngineButton";

export interface HomeStandardEligibilityFormContainerProps {
  evaluationId: string;
  formPart?: HomeEligibilityFormParts;
}

export const HomeStandardEligibilityFormContainer = (
  props: HomeStandardEligibilityFormContainerProps
) => {
  const {
    checklistParts,
    getApplicationPartChecklistStatus,
    setEvaluationFormModel,
    setHasUnsavedChanges,
    formPart,
    getBestFormPart,
    schemaFactory,
  } = useHomeStandardEligibilityFormContainer(props);

  return (
    <>
      {!formPart ||
      !Object.values(HomeEligibilityFormParts).includes(formPart) ? (
        // TODO: Show error page if the application form part is not valid
        <Navigate
          to={`${fortifiedHomeStandardRoutePrefix}/${
            props.evaluationId
          }/eligibility/${getBestFormPart().valueOf()}`}
        />
      ) : !schemaFactory ? (
        <Loading />
      ) : (
        <div className="wildfire-form-page">
          <div className="wildfire-form-progress">
            <div className="wildfire-form-progress__steps">
              <FormChecklistProgress
                checklistParts={checklistParts}
                evaluationId={props.evaluationId}
                formURL={`${fortifiedHomeStandardRoutePrefix}/${props.evaluationId}/eligibility`}
                title={"Eligibility"}
                activeFormPart={formPart}
                getChecklistStatus={getApplicationPartChecklistStatus}
              />
            </div>
          </div>
          <section className="wildfire-form-content">
            <div className="wildfire-form-content__header"></div>
            <PrimaryCard
              className="wildfire-form-primary-card"
              headerContent={
                <div className="wildfire-form-primary-card__header">
                  Eligibility:{" "}
                  {
                    checklistParts.find((value) => value.formPart === formPart)
                      ?.displayName
                  }
                  <GoToChecklistButton
                    evaluationId={props.evaluationId}
                    productTypeRoute={fortifiedHomeStandardRoutePrefix}
                  />
                </div>
              }
            >
              <SecondaryCard
                className="wildfire-form-secondary-card"
                hideBody={false}
                isCollapsible={false}
              >
                <FieldSchemaFactoryProvider schemaFactory={schemaFactory}>
                  <HomeStandardEvaluationEligibilityForm
                    key={formPart}
                    evaluationId={props.evaluationId}
                    isIterationEnginePage={false}
                    formPart={formPart}
                    disableForm={false}
                    setFormModel={setEvaluationFormModel}
                    setHasUnsavedChanges={setHasUnsavedChanges}
                  />
                </FieldSchemaFactoryProvider>
              </SecondaryCard>
            </PrimaryCard>
          </section>
        </div>
      )}
    </>
  );
};
export default HomeStandardEligibilityFormContainer;

import React, { useEffect, useState } from "react";
import "../../pages/styles.scss";
import { FortifiedGridViewPicker } from "../FortifiedGridViewsModule/FortifiedGridViewPicker";
import { FortifiedGridFiltersModule } from "../FortifiedGridFiltersModule/FortifiedGridFiltersModule";
import { useFortifiedGridViewModule } from "../../../common/customHooks/useFortifiedGridViewModule";
import { getGridColumnsConfigurationByRole } from "./configurations/gridColumnsConfiguration";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../../../AppInsights";
import { Button } from "@blueprintjs/core";
import { Observer } from "mobx-react";
import { FortifiedGrid } from "../FortifiedGrid/FortifiedGrid";
import { fortifiedGridViewModuleStore } from "../../stores/FortifiedGridViewModuleStore";
import variables from "../../../common/config/variables";
import { openCommentTemplateDialog } from "../../../fortifiedV2/Common/components/Dialogs/CommentTemplate/CommentTemplateDialog";
import { Dialogs } from "@ucl/library";
import { fortifiedGridStore } from "../../stores/FortifiedGridStore";
import { featureToggleApiClient } from "../../../common/Components/FeatureToggle/class/featureToggleApiClient";
import { FeatureToggleSet } from "../../../common/Components/FeatureToggle/FeatureToggles";
// import { permissionStore } from "../../stores/PermissionStore";

export interface BoardProps {}

const CommentTemplatesGridComponent: React.FC<BoardProps> = () => {
  // Grid View Module
  useFortifiedGridViewModule(
    "fortified-comment-templates-grid",
    "Comment Templates Board",
    getGridColumnsConfigurationByRole,
    (_role) => "All"
  );

  const [featureToggles, setFeatureToggles] = useState<FeatureToggleSet>();

  const getFeatureToggles = async () => {
    const toggles = await featureToggleApiClient.getFeatureToggleSet();

    setFeatureToggles(toggles);
  };

  useEffect(() => {
    getFeatureToggles();
  }, []);

  return (
    <div>
      <Dialogs />
      {featureToggles?.FHAuditorCommentTemplate && (
        <>
          <section className="board-toolbar">
            <ul className="board-toolbar-left">
              <li>
                {/* Grid Views Module - Picker */}
                <FortifiedGridViewPicker />
              </li>
            </ul>
            <ul className="board-toolbar-right">
              <li>
                {/* Grid Filters Module */}
                <FortifiedGridFiltersModule />
              </li>
              <li>
                {/* Note: Add permission store check. */}
                <Button
                  title="Add Comment Template"
                  icon="add"
                  intent="primary"
                  onClick={() => {
                    openCommentTemplateDialog({
                      isWildfireSystem: false,
                      refreshGrid: () => fortifiedGridStore.refresh(),
                    });
                  }}
                >
                  Create New Comment Template
                </Button>
              </li>
            </ul>
          </section>
          <section className="board-grid-container has-tabs">
            {/* Comments Template Grid */}
            <Observer>
              {() => (
                <>
                  {!!fortifiedGridViewModuleStore.currentGridView && (
                    <FortifiedGrid<{ id: string }>
                      key={fortifiedGridViewModuleStore.currentGridViewKey}
                      dataSource={`${variables.apiBaseUrl}odata/commenttemplatesfortifiedodata`}
                      columnsConfiguration={[
                        ...fortifiedGridViewModuleStore.currentGridView
                          .columnConfiguration,
                      ]}
                      filterConfiguration={[
                        ...(fortifiedGridViewModuleStore.currentGridView
                          .filterConfiguration || []),
                      ]}
                      sortConfiguration={[
                        ...(fortifiedGridViewModuleStore.currentGridView
                          .sortConfiguration || []),
                      ]}
                      gridConfiguration={{
                        allowTextWrap: true,
                        textWrapSettings: { wrapMode: "Header" },
                        defaultSort: {
                          field: "createdAt",
                          direction: "Descending",
                        },
                        exportFilename: "CommentTemplates.xlsx",
                        onRecordClick: ({ rowData }) => {
                          // possible role permission check here.
                          openCommentTemplateDialog({
                            refreshGrid: () => fortifiedGridStore.refresh(),
                            commentTemplateId: rowData.id,
                          });
                        },
                      }}
                    />
                  )}
                </>
              )}
            </Observer>
          </section>
        </>
      )}
    </div>
  );
};

export const CommentTemplatesGrid = withAITracking(
  reactPlugin,
  CommentTemplatesGridComponent,
  "CommentTemplatesGrid",
  "tracking-container"
);

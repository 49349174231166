import { PrimaryCard, SecondaryCard } from "@ucl/library";
import { Loading } from "@ucl/library/lib/components/Loading/Loading";
import { Navigate } from "react-router-dom";
import FormChecklistProgress from "../../../../../../Common/components/Forms/FormChecklistProgress/FormChecklistProgress";
import { FieldSchemaFactoryProvider } from "../../../../../customHooks/useHomeFieldSchemaFactory";
import { BaseHomeEvaluationHomeownerAuthorizationFormParts } from "../../../../../common/types/BaseHomeEvaluationHomeownerAuthorizationFormModel";
import useHomeStandardHomeownerAuthorizationFormContainer from "./useHomeStandardHomeownerAuthorizationFormContainer";
import { HomeStandardRedesignationHomeownerAuthorizationForm } from "../Form/HomeStandardRedesignationHomeownerAuthorizationForm";
import { fortifiedHomeStandardRedesignationRoutePrefix } from "../../../../../../../foritfied/pages/index/fortifiedHomeRouteConfig";
import GoToChecklistButton from "../../../../../common/components/Buttons/GoToChecklist/GoToIterationEngineButton";

export interface HomeStandardHomeownerAuthorizationFormContainerProps {
  evaluationId: string;
  homeownerAuthorizationFormPart?: BaseHomeEvaluationHomeownerAuthorizationFormParts;
}

export const HomeStandardHomeownerAuthorizationFormContainer = (
  props: HomeStandardHomeownerAuthorizationFormContainerProps
) => {
  const {
    applicationChecklistParts,
    getApplicationPartChecklistStatus,
    setEvaluationFormModel,
    setHasUnsavedChanges,
    applicationFormPart,
    getBestFormPart,
    commercialSchemaFactory,
  } = useHomeStandardHomeownerAuthorizationFormContainer(props);

  return (
    <>
      {!applicationFormPart ||
      !Object.values(
        BaseHomeEvaluationHomeownerAuthorizationFormParts
      ).includes(applicationFormPart) ? (
        // TODO: Show error page if the application form part is not valid
        <Navigate
          to={`${fortifiedHomeStandardRedesignationRoutePrefix}/${
            props.evaluationId
          }/homeowner-authorization/${getBestFormPart().valueOf()}`}
        />
      ) : !commercialSchemaFactory ? (
        <Loading />
      ) : (
        <div className="wildfire-form-page">
          <div className="wildfire-form-progress">
            <div className="wildfire-form-progress__steps">
              <FormChecklistProgress
                checklistParts={applicationChecklistParts}
                evaluationId={props.evaluationId}
                formURL={`${fortifiedHomeStandardRedesignationRoutePrefix}/${props.evaluationId}/homeowner-authorization`}
                title={"Homeowner Authorization"}
                activeFormPart={applicationFormPart}
                getChecklistStatus={getApplicationPartChecklistStatus}
              />
            </div>
          </div>
          <section className="wildfire-form-content">
            <div className="wildfire-form-content__header"></div>
            <PrimaryCard
              className="wildfire-form-primary-card"
              headerContent={
                <div className="wildfire-form-primary-card__header">
                  Redesignation Homeowner Authorization:{" "}
                  {
                    applicationChecklistParts.find(
                      (value) => value.formPart === applicationFormPart
                    )?.displayName
                  }
                  <GoToChecklistButton
                    evaluationId={props.evaluationId}
                    productTypeRoute={
                      fortifiedHomeStandardRedesignationRoutePrefix
                    }
                  />
                </div>
              }
            >
              <SecondaryCard
                className="wildfire-form-secondary-card"
                hideBody={false}
                isCollapsible={false}
              >
                <FieldSchemaFactoryProvider
                  schemaFactory={commercialSchemaFactory}
                >
                  <HomeStandardRedesignationHomeownerAuthorizationForm
                    key={applicationFormPart}
                    evaluationId={props.evaluationId}
                    isIterationEnginePage={false}
                    formPart={applicationFormPart}
                    disableForm={false}
                    setFormModel={setEvaluationFormModel}
                    setHasUnsavedChanges={setHasUnsavedChanges}
                  />
                </FieldSchemaFactoryProvider>
              </SecondaryCard>
            </PrimaryCard>
          </section>
        </div>
      )}
    </>
  );
};
export default HomeStandardHomeownerAuthorizationFormContainer;

import { PrimaryCard, SecondaryCard } from "@ucl/library";
import { Loading } from "@ucl/library/lib/components/Loading/Loading";
import { Navigate } from "react-router-dom";
import FormChecklistProgress from "../../../../../../Common/components/Forms/FormChecklistProgress/FormChecklistProgress";
import { fortifiedHomeStandardRoutePrefix } from "../../../../../../../foritfied/pages/index/fortifiedHomeRouteConfig";
import { FieldSchemaFactoryProvider } from "../../../../../customHooks/useHomeFieldSchemaFactory";
import { BaseHomeEvaluationEngineeringComplianceContinuousLoadPathFormParts } from "../../../../../common/types/BaseHomeEvaluationEngineeringComplianceContinuousLoadPathFormModel";
import useHomeStandardEngineeringComplianceContinuousLoadPathFormContainer from "./useHomeStandardEngineeringComplianceContinuousLoadPathFormContainer";
import HomeStandardEvaluationEngineeringComplianceContinuousLoadPathForm from "../Form/HomeStandardEvaluationEngineeringComplianceContinuousLoadPathForm";
import GoToChecklistButton from "../../../../../common/components/Buttons/GoToChecklist/GoToIterationEngineButton";

export interface HomeStandardEngineeringComplianceContinuousLoadPathFormContainerProps {
  evaluationId: string;
  formParts?: BaseHomeEvaluationEngineeringComplianceContinuousLoadPathFormParts;
}

export const HomeStandardEngineeringComplianceContinuousLoadPathFormContainer =
  (
    props: HomeStandardEngineeringComplianceContinuousLoadPathFormContainerProps
  ) => {
    const {
      applicationChecklistParts,
      getApplicationPartChecklistStatus,
      setEvaluationFormModel,
      setHasUnsavedChanges,
      formPart,
      getBestFormPart,
      schemaFactory,
    } =
      useHomeStandardEngineeringComplianceContinuousLoadPathFormContainer(
        props
      );

    return (
      <>
        {!formPart ||
        !Object.values(
          BaseHomeEvaluationEngineeringComplianceContinuousLoadPathFormParts
        ).includes(formPart) ? (
          // TODO: Show error page if the application form part is not valid
          <Navigate
            to={`${fortifiedHomeStandardRoutePrefix}/${
              props.evaluationId
            }/engineering-compliance-continuous-load-path/${getBestFormPart().valueOf()}`}
          />
        ) : !schemaFactory ? (
          <Loading />
        ) : (
          <div className="wildfire-form-page">
            <div className="wildfire-form-progress">
              <div className="wildfire-form-progress__steps">
                <FormChecklistProgress
                  checklistParts={applicationChecklistParts}
                  evaluationId={props.evaluationId}
                  formURL={`${fortifiedHomeStandardRoutePrefix}/${props.evaluationId}/engineering-compliance-continuous-load-path`}
                  title={"Engineering Compliance Form"}
                  activeFormPart={formPart}
                  getChecklistStatus={getApplicationPartChecklistStatus}
                />
              </div>
            </div>
            <section className="wildfire-form-content">
              <div className="wildfire-form-content__header"></div>
              <PrimaryCard
                className="wildfire-form-primary-card"
                headerContent={
                  <div className="wildfire-form-primary-card__header">
                    Engineering Compliance Form{" "}
                    {
                      applicationChecklistParts.find(
                        (value) => value.formPart === formPart
                      )?.displayName
                    }
                    <GoToChecklistButton
                      evaluationId={props.evaluationId}
                      productTypeRoute={fortifiedHomeStandardRoutePrefix}
                    />
                  </div>
                }
              >
                <SecondaryCard
                  className="wildfire-form-secondary-card"
                  hideBody={false}
                  isCollapsible={false}
                >
                  <FieldSchemaFactoryProvider schemaFactory={schemaFactory}>
                    <HomeStandardEvaluationEngineeringComplianceContinuousLoadPathForm
                      key={formPart}
                      evaluationId={props.evaluationId}
                      isIterationEnginePage={false}
                      formPart={formPart}
                      disableForm={false}
                      setFormModel={setEvaluationFormModel}
                      setHasUnsavedChanges={setHasUnsavedChanges}
                      formChecklistParts={applicationChecklistParts}
                    />
                  </FieldSchemaFactoryProvider>
                </SecondaryCard>
              </PrimaryCard>
            </section>
          </div>
        )}
      </>
    );
  };
export default HomeStandardEngineeringComplianceContinuousLoadPathFormContainer;

import { PrimaryCard, SecondaryCard } from "@ucl/library";
import { Loading } from "@ucl/library/lib/components/Loading/Loading";
import { Navigate } from "react-router-dom";
import FormChecklistProgress from "../../../../../../Common/components/Forms/FormChecklistProgress/FormChecklistProgress";
import { fortifiedHomeStandardRoutePrefix } from "../../../../../../../foritfied/pages/index/fortifiedHomeRouteConfig";
import { FieldSchemaFactoryProvider } from "../../../../../customHooks/useHomeFieldSchemaFactory";
import { BaseHomeEvaluationEngineeringComplianceGeneralInformationFormParts } from "../../../../../common/types/BaseHomeEvaluationEngineeringComplianceGeneralInformationFormModel";
import useHomeStandardEngineeringComplianceGeneralInformationFormContainer from "./useHomeStandardEngineeringComplianceGeneralInformationFormContainer";
import HomeStandardEvaluationEngineeringComplianceGeneralInformationForm from "../Form/HomeStandardEvaluationEngineeringComplianceGeneralInformationForm";
import GoToChecklistButton from "../../../../../common/components/Buttons/GoToChecklist/GoToIterationEngineButton";

export interface HomeStandardEngineeringComplianceGeneralInformationFormContainerProps {
  evaluationId: string;
  engineeringComplianceGeneralInformationFormPart?: BaseHomeEvaluationEngineeringComplianceGeneralInformationFormParts;
}

export const HomeStandardEngineeringComplianceGeneralInformationFormContainer =
  (
    props: HomeStandardEngineeringComplianceGeneralInformationFormContainerProps
  ) => {
    const {
      applicationChecklistParts,
      getApplicationPartChecklistStatus,
      setEvaluationFormModel,
      setHasUnsavedChanges,
      applicationFormPart,
      getBestFormPart,
      commercialSchemaFactory,
    } =
      useHomeStandardEngineeringComplianceGeneralInformationFormContainer(
        props
      );

    return (
      <>
        {!applicationFormPart ||
        !Object.values(
          BaseHomeEvaluationEngineeringComplianceGeneralInformationFormParts
        ).includes(applicationFormPart) ? (
          // TODO: Show error page if the application form part is not valid
          <Navigate
            to={`${fortifiedHomeStandardRoutePrefix}/${
              props.evaluationId
            }/engineering-compliance-general-information/${getBestFormPart().valueOf()}`}
          />
        ) : !commercialSchemaFactory ? (
          <Loading />
        ) : (
          <div className="wildfire-form-page">
            <div className="wildfire-form-progress">
              <div className="wildfire-form-progress__steps">
                <FormChecklistProgress
                  checklistParts={applicationChecklistParts}
                  evaluationId={props.evaluationId}
                  formURL={`${fortifiedHomeStandardRoutePrefix}/${props.evaluationId}/engineering-compliance-general-information`}
                  title={"Engineering Compliance Form"}
                  activeFormPart={applicationFormPart}
                  getChecklistStatus={getApplicationPartChecklistStatus}
                />
              </div>
            </div>
            <section className="wildfire-form-content">
              <div className="wildfire-form-content__header"></div>
              <PrimaryCard
                className="wildfire-form-primary-card"
                headerContent={
                  <div className="wildfire-form-primary-card__header">
                    Engineering Compliance Form:{" "}
                    {
                      applicationChecklistParts.find(
                        (value) => value.formPart === applicationFormPart
                      )?.displayName
                    }
                    <GoToChecklistButton
                      evaluationId={props.evaluationId}
                      productTypeRoute={fortifiedHomeStandardRoutePrefix}
                    />
                  </div>
                }
              >
                <SecondaryCard
                  className="wildfire-form-secondary-card"
                  hideBody={false}
                  isCollapsible={false}
                >
                  <FieldSchemaFactoryProvider
                    schemaFactory={commercialSchemaFactory}
                  >
                    <HomeStandardEvaluationEngineeringComplianceGeneralInformationForm
                      key={applicationFormPart}
                      evaluationId={props.evaluationId}
                      isIterationEnginePage={false}
                      formPart={applicationFormPart}
                      disableForm={false}
                      setFormModel={setEvaluationFormModel}
                      setHasUnsavedChanges={setHasUnsavedChanges}
                    />
                  </FieldSchemaFactoryProvider>
                </SecondaryCard>
              </PrimaryCard>
            </section>
          </div>
        )}
      </>
    );
  };
export default HomeStandardEngineeringComplianceGeneralInformationFormContainer;

import { useEffect, useState } from "react";
import FortifiedIterationEngineChecklistAndTabs from "../../../../Common/components/IterationEngine/ChecklistAndTabs/FortifiedIterationEngineChecklistAndTabs";
import { EvaluationStep } from "../../../../../foritfied/types/evaluation/Evaluation";
import {
  FieldSchemaFactoryProvider,
  HomeSchemaFactoryV2,
} from "../../../customHooks/useHomeFieldSchemaFactory";
import { useHomeNCIUAIterationEngine } from "./useHomeNCIUAIterationEngine";
import FortifiedIterationEngineCommentPanel from "../../../../Common/components/IterationEngine/FortifiedIterationEngineCommentPanel";
import HomeNCIUAIterationEngineHeader from "./HomeNCIUAIterationEngineHeader";
import HomeNCIUAIterationEngineFormsContainer from "../Forms/EvaluationForm/HomeNCIUAIterationEngineFormsContainer";
import { fortifiedUserStore } from "../../../../../foritfied/stores/FortifiedUserStore";
import { Tab, Tabs } from "@blueprintjs/core";

export interface HomeNCIUAIterationEngineProps {
  evaluationId: string;
  homeSchemaFactory: HomeSchemaFactoryV2;
}

const HomeNCIUAIterationEngine: React.FC<HomeNCIUAIterationEngineProps> = (
  props
) => {
  const {
    //Evaluation
    homeNCIUAEvaluation,
    refreshHomeNCIUAEvaluation,
    setHomeNCIUAEvaluation,
    //Comments
    comments,
    createComment,
    deleteComment,
    selectedKey,
    selectedField,
    setSelectedFieldAndGetComments,
    setSelectedKeyAndGetComments,
    //ChangeRequests
    allChangeRequests,
    resolveChangeRequest,
    unresolveChangeRequest,
    unresolvedChangeRequests,
    getChangeRequests,
    changeRequestCount,
    resolvedChangeRequestCount,
    getEvaluationFieldKeysForAllUnresolvedChangeRequests,
    resolvedChangeRequestsPercentage,
    longestOutstandingChangeRequest,
    addressedAtLeastOneChangeRequest,
    hasNoUnaddressedChangeRequests,
    resolvedAllChangeRequests,
    //Iteration Engine
    setSelectedIterationEngineFormTabId,
    selectedIterationEngineFormTabId,
    canEditForm,
    formTabs,
  } = useHomeNCIUAIterationEngine(
    props.evaluationId,
    props.homeSchemaFactory.schema
  );

  const [evaluationFormInitialized, setEvaluationFormInitialized] =
    useState(false);

  useEffect(() => {
    setEvaluationFormInitialized(false);
  }, [props.evaluationId, selectedIterationEngineFormTabId]);

  return (
    <section className="wildfire-admin-iteration-engine">
      {homeNCIUAEvaluation && (
        <FieldSchemaFactoryProvider
          schemaFactory={props.homeSchemaFactory}
          formOverrides={{
            onLabelClick: (fieldProps) =>
              setSelectedFieldAndGetComments?.(
                homeNCIUAEvaluation.id,
                fieldProps
              ),
            iterationEngineProps: {
              fieldKeysWithChangeRequests:
                getEvaluationFieldKeysForAllUnresolvedChangeRequests,
              shouldEnableForm: canEditForm,
              changeRequests: allChangeRequests,
            },
          }}
        >
          <div className="wildfire-iteration-engine">
            <div className="wildfire-iteration-engine__header">
              <HomeNCIUAIterationEngineHeader
                evaluationId={props.evaluationId}
                evaluation={homeNCIUAEvaluation}
                setEvaluation={setHomeNCIUAEvaluation}
                allChangeRequestsAreResolved={resolvedAllChangeRequests}
                hasNoUnaddressedChangeRequests={hasNoUnaddressedChangeRequests}
                addressedAtLeastOneChangeRequest={
                  addressedAtLeastOneChangeRequest
                }
                isEvaluationClaimedByCurrentUser={
                  homeNCIUAEvaluation.auditorId === fortifiedUserStore?.user?.id
                }
                refreshEvaluation={refreshHomeNCIUAEvaluation}
                longestOutstandingChangeRequest={
                  longestOutstandingChangeRequest
                }
              />
            </div>
            <div className="wildfire-iteration-engine__content">
              <div className="wildfire-iteration-engine__left-panel">
                <FortifiedIterationEngineChecklistAndTabs
                  evaluationStep={homeNCIUAEvaluation.step}
                  evaluationStatus={homeNCIUAEvaluation.status_AsString}
                  selectedTabId={selectedIterationEngineFormTabId}
                  setSelectedTabId={setSelectedIterationEngineFormTabId}
                  formTabs={formTabs}
                  evaluationStepDetails={[
                    {
                      id: EvaluationStep.Application,
                      label: "Application",
                    },
                    {
                      id: EvaluationStep.EligibilityReview,
                      label: "IBHS Eligibility Review",
                    },
                    {
                      id: EvaluationStep.ApplicationForms,
                      label: "Application Forms",
                    },
                    {
                      id: EvaluationStep.ApplicationFee,
                      label: "Payment",
                    },
                    {
                      id: EvaluationStep.FinalReview,
                      label: "IBHS Review",
                    },
                  ]}
                />
              </div>
              <div className="wildfire-iteration-engine__center-panel">
                <HomeNCIUAIterationEngineFormsContainer
                  fieldsContainerClassName="evaluation-forms-container"
                  selectedTabId={selectedIterationEngineFormTabId}
                  changeRequests={allChangeRequests}
                  evaluationId={props.evaluationId}
                  evaluationStep={homeNCIUAEvaluation.step}
                  getChangeRequests={getChangeRequests}
                  onEvaluationFormInitialized={() => {
                    setEvaluationFormInitialized(true);
                  }}
                  refreshHomeNCIUAEvaluation={refreshHomeNCIUAEvaluation}
                />
              </div>
              <div className="wildfire-iteration-engine__right-panel">
                <Tabs>
                  <Tab
                    id="tabs-checklist"
                    title="Auditor Checklist"
                    // Empty panel for now to see the white background 😎
                    panel={
                      <div style={{ height: "600px", width: "100%" }}></div>
                    }
                  />
                  <Tab
                    id="tabs-comments"
                    title="Comments"
                    panel={
                      <FortifiedIterationEngineCommentPanel
                        fieldsContainerClassName="evaluation-forms-container"
                        isOpen={evaluationFormInitialized}
                        changeRequests={allChangeRequests}
                        unresolvedChangeRequests={unresolvedChangeRequests}
                        setSelectedIterationEngineFormTabId={
                          setSelectedIterationEngineFormTabId
                        }
                        selectedIterationEngineFormTabId={
                          selectedIterationEngineFormTabId
                        }
                        selectedKey={selectedKey}
                        comments={comments}
                        createComment={(
                          comment,
                          evaluationFieldKey,
                          evaluationFieldLabel,
                          isPublic
                        ) =>
                          createComment(
                            homeNCIUAEvaluation.id,
                            comment,
                            evaluationFieldKey,
                            evaluationFieldLabel,
                            isPublic
                          )
                        }
                        selectedField={selectedField}
                        deleteComment={(commentId, evaluationFieldKey) =>
                          deleteComment(
                            homeNCIUAEvaluation.id,
                            commentId,
                            evaluationFieldKey
                          )
                        }
                        setSelectedKeyAndGetComments={(fieldKey) =>
                          setSelectedKeyAndGetComments!(
                            homeNCIUAEvaluation.id,
                            fieldKey
                          )
                        }
                        allChangeRequests={allChangeRequests}
                        changeRequestCount={changeRequestCount}
                        resolvedChangeRequestCount={resolvedChangeRequestCount}
                        resolvedChangeRequestsPercentage={
                          resolvedChangeRequestsPercentage
                        }
                        unresolveChangeRequest={unresolveChangeRequest}
                        resolveChangeRequest={resolveChangeRequest}
                        getChangeRequests={getChangeRequests}
                      />
                    }
                  />
                </Tabs>
              </div>
            </div>
          </div>
        </FieldSchemaFactoryProvider>
      )}
    </section>
  );
};

export default HomeNCIUAIterationEngine;

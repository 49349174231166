import { Schema } from "@ucl/library";
import { useContext, useEffect, useState } from "react";
import wildfireBaseApiClient from "../../../../../../../wildfire/lib/wildfireBaseApiClient";
import { UnsavedChangesBlockerContext } from "../../../../../../../wildfire/components/Provider/UnsavedChangesBlockerContext";
import {
  FormChecklistPart,
  FormChecklistStatus,
} from "../../../../../../../common/types/Forms/FormChecklist";
import { Evaluation_HomeStandardEvaluationFormModel } from "../../../../types/HomeEvaluationFormModel";
import { HomeSchemaFactoryV2 } from "../../../../../customHooks/useHomeFieldSchemaFactory";
import { HomeStandardEngineeringComplianceAttachedStructureFormContainerProps } from "./HomeStandardEngineeringComplianceAttachedStructureFormContainer";
import { BaseHomeEvaluationEngineeringComplianceAttachedStructureFormParts } from "../../../../../common/types/BaseHomeEvaluationEngineeringComplianceAttachedStructureFormModel";
import { AppHeaderContext } from "../../../../../../../wildfire/components/Provider/AppHeaderContext";

function useHomeStandardEngineeringComplianceAttachedStructureFormContainer(
  props: HomeStandardEngineeringComplianceAttachedStructureFormContainerProps
) {
  const [schemaFactory, setSchemaFactory] = useState<HomeSchemaFactoryV2>();

  const getSchema = async () => {
    const schema = await wildfireBaseApiClient.get<Schema>(`/wildfire/schema`);
    const newSchemaFactory = new HomeSchemaFactoryV2(schema);
    setSchemaFactory(newSchemaFactory);
  };

  useEffect(() => {
    if (!schemaFactory) {
      getSchema();
    }
    return () => {
      setDefaultHeader();
    };
  }, []);

  useEffect(() => {
    setFormPart(getBestFormPart());
  }, [props.formParts]);

  const { setHasUnsavedChanges } = useContext(UnsavedChangesBlockerContext);

  const [formPart, setFormPart] = useState<
    | BaseHomeEvaluationEngineeringComplianceAttachedStructureFormParts
    | undefined
  >(props.formParts);

  const [evaluationFormModel, setEvaluationFormModel] =
    useState<Evaluation_HomeStandardEvaluationFormModel>();

  const formModel =
    evaluationFormModel?.homeStandardEvaluation
      .engineeringComplianceAttachedStructureForm;

  const { setFotifiedHomeEvaluationHeader, setDefaultHeader } =
    useContext(AppHeaderContext);

  useEffect(() => {
    if (evaluationFormModel) {
      setFotifiedHomeEvaluationHeader(evaluationFormModel);
    }
  }, [evaluationFormModel]);

  const getNextUnsubmittedFormPart = () => {
    if (!formModel) {
      return null;
    }

    if (!formModel.isAttachedStructureComplete) {
      return BaseHomeEvaluationEngineeringComplianceAttachedStructureFormParts.EngineeringComplianceFormAttachedStructure;
    }

    // Form is complete
    return null;
  };

  const getBestFormPart =
    (): BaseHomeEvaluationEngineeringComplianceAttachedStructureFormParts => {
      // Default to the first part of the form if we can't find a better option
      const defaultFormPart = Object.values(
        BaseHomeEvaluationEngineeringComplianceAttachedStructureFormParts
      )[0];

      // If the application form part is provided (by URL), use it
      if (!!props.formParts) {
        return props.formParts;
      }

      // Otherwise, try to find the first unsubmitted part of the form
      // TODO: This doesn't work yet, needs to wait for applicationFormModel to load
      return getNextUnsubmittedFormPart() ?? defaultFormPart;
    };

  const getApplicationPartChecklistStatus = (
    formPart: BaseHomeEvaluationEngineeringComplianceAttachedStructureFormParts
  ): FormChecklistStatus => {
    if (formPart === getNextUnsubmittedFormPart()) {
      return FormChecklistStatus.NextUp;
    }

    switch (formPart) {
      case BaseHomeEvaluationEngineeringComplianceAttachedStructureFormParts.EngineeringComplianceFormAttachedStructure:
        return formModel?.isAttachedStructureComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
      default:
        return FormChecklistStatus.Unsubmitted;
    }
  };

  const checklistParts: FormChecklistPart<BaseHomeEvaluationEngineeringComplianceAttachedStructureFormParts>[] =
    [
      {
        formPart:
          BaseHomeEvaluationEngineeringComplianceAttachedStructureFormParts.EngineeringComplianceFormAttachedStructure,
        displayName: "Attached Structures",
        requiredFieldCount: formModel?.attachedStructureRequiredFieldCount,
      },
    ];

  return {
    applicationChecklistParts: checklistParts,
    getApplicationPartChecklistStatus,
    setEvaluationFormModel,
    setHasUnsavedChanges,
    formPart,
    getBestFormPart,
    schemaFactory,
  };
}

export default useHomeStandardEngineeringComplianceAttachedStructureFormContainer;

import {
  EnumRadioFieldValue,
  EnumSingleSelectFieldValue,
  SingleCheckboxFieldValue,
  TextAreaFieldValue,
} from "@ucl/library/lib/components/Fields/types/fieldTypes";

export enum BaseHomeEvaluationEngineeringComplianceContinuousLoadPathFormParts {
  ContinuousLoadPathForNewConstruction = "continuous-load-path-for-new-construction",
  ContinuousLoadPathForRetrofitOfExistingHome = "continuous-load-path-for-retrofit-of-existing-home",
}
export enum InstalledFoundationTypes {
  SlabOnGradeOrStemWall = 1,
  ElevatedFoundationOnPiersPilings = 2,
  Other = 3,
}

export interface BaseHomeEvaluationEngineeringComplianceContinuousLoadPathFormModel
  extends IEngineeringComplianceFormContinuousLoadPathForNewConstruction,
    IEngineeringComplianceFormContinuousLoadPathForRetrofitOfExistingHome {}

export interface IEngineeringComplianceFormContinuousLoadPathForNewConstruction {
  attestation234?: SingleCheckboxFieldValue;
  attestation235?: SingleCheckboxFieldValue;
  areAttestations234And235NotApplicable?: SingleCheckboxFieldValue;
  attestation236?: SingleCheckboxFieldValue;
  attestation237?: SingleCheckboxFieldValue;
  areAttestations236And237NotApplicable?: SingleCheckboxFieldValue;
  attestation238?: SingleCheckboxFieldValue;
  attestation239?: SingleCheckboxFieldValue;
  doesHomeIncludeConcreteCMUWalls?: EnumRadioFieldValue;
  attestation241?: SingleCheckboxFieldValue;
  attestation242?: SingleCheckboxFieldValue;
  attestation243?: SingleCheckboxFieldValue;
  attestation244?: SingleCheckboxFieldValue;
  installedFoundationType?: EnumSingleSelectFieldValue;
  installedFoundationTypeDescription?: string;
  attestation246?: SingleCheckboxFieldValue;
  attestation301?: SingleCheckboxFieldValue;
  attestation247?: SingleCheckboxFieldValue;
  isAttestation247NotApplicable?: SingleCheckboxFieldValue;
  attestation248?: SingleCheckboxFieldValue;
  attestation249?: SingleCheckboxFieldValue;
  werePrescriptiveDesignStandardsUtilized?: EnumRadioFieldValue;
  prescriptiveDesignStandardsDescription?: TextAreaFieldValue;
  attestation251?: SingleCheckboxFieldValue;
  attestation252?: SingleCheckboxFieldValue;
  attestation253?: SingleCheckboxFieldValue;
  attestation254?: SingleCheckboxFieldValue;
  attestation255?: SingleCheckboxFieldValue;
  attestation256?: SingleCheckboxFieldValue;
  installedRoofToWallConnectionsDescription?: EnumRadioFieldValue;
  attestation258?: SingleCheckboxFieldValue;
  isHomeMoreThan1Story?: EnumRadioFieldValue;
  attestation260?: SingleCheckboxFieldValue;
  installedWallAboveToWallBelowConnectionsDescriptionType?: EnumSingleSelectFieldValue;
  attestation262?: SingleCheckboxFieldValue;
  attestation263?: SingleCheckboxFieldValue;
  attestation264?: SingleCheckboxFieldValue;
  attestation265?: SingleCheckboxFieldValue;
}

export interface IEngineeringComplianceFormContinuousLoadPathForRetrofitOfExistingHome {
  attestation266?: SingleCheckboxFieldValue;
  attestation267?: SingleCheckboxFieldValue;
}

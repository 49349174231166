import { FieldSchemaFormBuilderV2, SchemaFactoryV2 } from "@ucl/library";
import { Evaluation_HomeStandardEvaluationFormModel } from "../../../types/HomeEvaluationFormModel";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { WildfireBoxContentExplorer } from "../../../../../../wildfire/components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";
import { ContinuousLoadPathPathwayTypes } from "../../../../common/types/BaseHomeEvaluationEligibilityFormModel";
import { YesOrNoSelection } from "../../../../../../wildfire/types/FormFields";
import { InstalledFoundationTypes } from "../../../../common/types/BaseHomeEvaluationEngineeringComplianceContinuousLoadPathFormModel";

const modelName = "Evaluation";
const subModelName = "HomeStandardEvaluation";
const formName = "EngineeringComplianceContinuousLoadPathForm";

const buildFieldKey = (fieldName: string) =>
  `${modelName}__${subModelName}__${formName}__${fieldName}`;

// Part 1: Installer Compliance Form Continuous Load Path For New Construction
const engineeringComplianceFormContinuousLoadPathForNewConstructionFields = (
  pathway_for_continuous_load_path_compliance: ContinuousLoadPathPathwayTypes,
  form: Evaluation_HomeStandardEvaluationFormModel
) => [
  {
    fieldKey: buildFieldKey("Attestation234"),
    hidden: !(
      pathway_for_continuous_load_path_compliance ===
      ContinuousLoadPathPathwayTypes.EngineeredCLPForNewConstruction
    ),
    isRequired:
      form.homeStandardEvaluation.engineeringComplianceContinuousLoadPathForm
        .areAttestations234And235NotApplicable != true,
    disabled:
      form.homeStandardEvaluation.engineeringComplianceContinuousLoadPathForm
        .areAttestations234And235NotApplicable == true,
  },
  {
    fieldKey: buildFieldKey("Attestation235"),
    hidden: !(
      pathway_for_continuous_load_path_compliance ===
      ContinuousLoadPathPathwayTypes.EngineeredCLPForNewConstruction
    ),
    isRequired:
      form.homeStandardEvaluation.engineeringComplianceContinuousLoadPathForm
        .areAttestations234And235NotApplicable != true,
    disabled:
      form.homeStandardEvaluation.engineeringComplianceContinuousLoadPathForm
        .areAttestations234And235NotApplicable == true,
  },
  {
    fieldKey: buildFieldKey("AreAttestations234And235NotApplicable"),
    hidden:
      pathway_for_continuous_load_path_compliance !==
        ContinuousLoadPathPathwayTypes.EngineeredCLPForNewConstruction ||
      ((form.homeStandardEvaluation.engineeringComplianceContinuousLoadPathForm
        .attestation234 == true ||
        form.homeStandardEvaluation.engineeringComplianceContinuousLoadPathForm
          .attestation235 == true) &&
        form.homeStandardEvaluation.engineeringComplianceContinuousLoadPathForm
          .areAttestations234And235NotApplicable != true),
  },
  {
    fieldKey: buildFieldKey("Attestation236"),
    hidden: !(
      pathway_for_continuous_load_path_compliance ===
      ContinuousLoadPathPathwayTypes.EngineeredCLPForNewConstruction
    ),
    isRequired:
      form.homeStandardEvaluation.engineeringComplianceContinuousLoadPathForm
        .areAttestations236And237NotApplicable != true,
    disabled:
      form.homeStandardEvaluation.engineeringComplianceContinuousLoadPathForm
        .areAttestations236And237NotApplicable == true,
  },
  {
    fieldKey: buildFieldKey("Attestation237"),
    hidden: !(
      pathway_for_continuous_load_path_compliance ===
      ContinuousLoadPathPathwayTypes.EngineeredCLPForNewConstruction
    ),
    isRequired:
      form.homeStandardEvaluation.engineeringComplianceContinuousLoadPathForm
        .areAttestations236And237NotApplicable != true,
    disabled:
      form.homeStandardEvaluation.engineeringComplianceContinuousLoadPathForm
        .areAttestations236And237NotApplicable == true,
  },
  {
    fieldKey: buildFieldKey("AreAttestations236And237NotApplicable"),
    hidden:
      pathway_for_continuous_load_path_compliance !==
        ContinuousLoadPathPathwayTypes.EngineeredCLPForNewConstruction ||
      ((form.homeStandardEvaluation.engineeringComplianceContinuousLoadPathForm
        .attestation236 == true ||
        form.homeStandardEvaluation.engineeringComplianceContinuousLoadPathForm
          .attestation237 == true) &&
        form.homeStandardEvaluation.engineeringComplianceContinuousLoadPathForm
          .areAttestations236And237NotApplicable != true),
  },
  {
    fieldKey: buildFieldKey("Attestation238"),
    hidden: !(
      pathway_for_continuous_load_path_compliance ===
      ContinuousLoadPathPathwayTypes.EngineeredCLPForNewConstruction
    ),
  },
  {
    fieldKey: buildFieldKey("Attestation239"),
    hidden: !(
      pathway_for_continuous_load_path_compliance ===
      ContinuousLoadPathPathwayTypes.EngineeredCLPForNewConstruction
    ),
  },
  {
    fieldKey: buildFieldKey("DoesHomeIncludeConcreteCMUWalls"),
    hidden: !(
      pathway_for_continuous_load_path_compliance ===
      ContinuousLoadPathPathwayTypes.EngineeredCLPForNewConstruction
    ),
  },
  {
    fieldKey: buildFieldKey("Attestation241"),
    hidden: !(
      pathway_for_continuous_load_path_compliance ===
        ContinuousLoadPathPathwayTypes.EngineeredCLPForNewConstruction &&
      form.homeStandardEvaluation.engineeringComplianceContinuousLoadPathForm
        .attestation249
    ),
  },
  {
    fieldKey: buildFieldKey("Attestation242"),
    hidden: !(
      pathway_for_continuous_load_path_compliance ===
        ContinuousLoadPathPathwayTypes.EngineeredCLPForNewConstruction &&
      form.homeStandardEvaluation.engineeringComplianceContinuousLoadPathForm
        .doesHomeIncludeConcreteCMUWalls == YesOrNoSelection.Yes
    ),
  },
  {
    fieldKey: buildFieldKey("Attestation243"),
    hidden: !(
      pathway_for_continuous_load_path_compliance ===
        ContinuousLoadPathPathwayTypes.EngineeredCLPForNewConstruction &&
      form.homeStandardEvaluation.engineeringComplianceContinuousLoadPathForm
        .doesHomeIncludeConcreteCMUWalls == YesOrNoSelection.Yes
    ),
  },
  {
    fieldKey: buildFieldKey("Attestation244"),
    hidden: !(
      pathway_for_continuous_load_path_compliance ===
        ContinuousLoadPathPathwayTypes.EngineeredCLPForNewConstruction &&
      form.homeStandardEvaluation.engineeringComplianceContinuousLoadPathForm
        .doesHomeIncludeConcreteCMUWalls == YesOrNoSelection.Yes
    ),
  },
  {
    fieldKey: buildFieldKey("InstalledFoundationType"),
    hidden: !(
      pathway_for_continuous_load_path_compliance ===
      ContinuousLoadPathPathwayTypes.EngineeredCLPForNewConstruction
    ),
  },
  {
    fieldKey: buildFieldKey("InstalledFoundationTypeDescription"),
    hidden: !(
      pathway_for_continuous_load_path_compliance ===
        ContinuousLoadPathPathwayTypes.EngineeredCLPForNewConstruction &&
      form.homeStandardEvaluation.engineeringComplianceContinuousLoadPathForm
        .installedFoundationType == InstalledFoundationTypes.Other
    ),
  },
  {
    fieldKey: buildFieldKey("Attestation246"),
    hidden: !(
      pathway_for_continuous_load_path_compliance ===
        ContinuousLoadPathPathwayTypes.EngineeredCLPForNewConstruction &&
      form.homeStandardEvaluation.engineeringComplianceContinuousLoadPathForm
        .installedFoundationType == InstalledFoundationTypes.Other
    ),
  },
  {
    fieldKey: buildFieldKey("Attestation301"),
    hidden: !(
      pathway_for_continuous_load_path_compliance ===
        ContinuousLoadPathPathwayTypes.EngineeredCLPForNewConstruction &&
      form.homeStandardEvaluation.engineeringComplianceContinuousLoadPathForm
        .installedFoundationType ==
        InstalledFoundationTypes.SlabOnGradeOrStemWall
    ),
  },
  {
    fieldKey: buildFieldKey("Attestation247"),
    hidden: !(
      pathway_for_continuous_load_path_compliance ===
        ContinuousLoadPathPathwayTypes.EngineeredCLPForNewConstruction &&
      form.homeStandardEvaluation.engineeringComplianceContinuousLoadPathForm
        .installedFoundationType ==
        InstalledFoundationTypes.SlabOnGradeOrStemWall
    ),
    isRequired:
      form.homeStandardEvaluation.engineeringComplianceContinuousLoadPathForm
        .isAttestation247NotApplicable != true,
    disabled:
      form.homeStandardEvaluation.engineeringComplianceContinuousLoadPathForm
        .isAttestation247NotApplicable == true,
  },
  {
    fieldKey: buildFieldKey("IsAttestation247NotApplicable"),
    hidden:
      !(
        pathway_for_continuous_load_path_compliance ===
          ContinuousLoadPathPathwayTypes.EngineeredCLPForNewConstruction &&
        form.homeStandardEvaluation.engineeringComplianceContinuousLoadPathForm
          .installedFoundationType ==
          InstalledFoundationTypes.SlabOnGradeOrStemWall
      ) ||
      (form.homeStandardEvaluation.engineeringComplianceContinuousLoadPathForm
        .attestation247 == true &&
        form.homeStandardEvaluation.engineeringComplianceContinuousLoadPathForm
          .isAttestation247NotApplicable != true),
  },
  {
    fieldKey: buildFieldKey("Attestation248"),
    hidden: !(
      pathway_for_continuous_load_path_compliance ===
        ContinuousLoadPathPathwayTypes.EngineeredCLPForNewConstruction &&
      form.homeStandardEvaluation.engineeringComplianceContinuousLoadPathForm
        .installedFoundationType ==
        InstalledFoundationTypes.ElevatedFoundationOnPiersPilings
    ),
  },
  {
    fieldKey: buildFieldKey("Attestation249"),
    hidden: !(
      pathway_for_continuous_load_path_compliance ===
        ContinuousLoadPathPathwayTypes.EngineeredCLPForNewConstruction &&
      (form.homeStandardEvaluation.engineeringComplianceContinuousLoadPathForm
        .installedFoundationType == InstalledFoundationTypes.Other ||
        form.homeStandardEvaluation.engineeringComplianceContinuousLoadPathForm
          .installedFoundationType ==
          InstalledFoundationTypes.ElevatedFoundationOnPiersPilings)
    ),
  },
  {
    fieldKey: buildFieldKey("WerePrescriptiveDesignStandardsUtilized"),
    hidden: !(
      pathway_for_continuous_load_path_compliance ===
      ContinuousLoadPathPathwayTypes.EngineeredCLPForNewConstruction
    ),
  },
  {
    fieldKey: buildFieldKey("PrescriptiveDesignStandardsDescription"),
    hidden: !(
      pathway_for_continuous_load_path_compliance ===
        ContinuousLoadPathPathwayTypes.EngineeredCLPForNewConstruction &&
      form.homeStandardEvaluation.engineeringComplianceContinuousLoadPathForm
        .werePrescriptiveDesignStandardsUtilized == YesOrNoSelection.Yes
    ),
  },
  {
    fieldKey: buildFieldKey("Attestation251"),
    hidden: !(
      pathway_for_continuous_load_path_compliance ===
        ContinuousLoadPathPathwayTypes.EngineeredCLPForNewConstruction &&
      form.homeStandardEvaluation.engineeringComplianceContinuousLoadPathForm
        .werePrescriptiveDesignStandardsUtilized == YesOrNoSelection.Yes
    ),
  },
  {
    fieldKey: buildFieldKey("Attestation252"),
    hidden: !(
      pathway_for_continuous_load_path_compliance ===
        ContinuousLoadPathPathwayTypes.EngineeredCLPForNewConstruction &&
      form.homeStandardEvaluation.engineeringComplianceContinuousLoadPathForm
        .werePrescriptiveDesignStandardsUtilized == YesOrNoSelection.Yes
    ),
  },
  {
    fieldKey: buildFieldKey("Attestation253"),
    hidden: !(
      pathway_for_continuous_load_path_compliance ===
        ContinuousLoadPathPathwayTypes.EngineeredCLPForNewConstruction &&
      form.homeStandardEvaluation.engineeringComplianceContinuousLoadPathForm
        .werePrescriptiveDesignStandardsUtilized == YesOrNoSelection.Yes
    ),
  },
  {
    fieldKey: buildFieldKey("Attestation254"),
    hidden: !(
      pathway_for_continuous_load_path_compliance ===
        ContinuousLoadPathPathwayTypes.EngineeredCLPForNewConstruction &&
      form.homeStandardEvaluation.engineeringComplianceContinuousLoadPathForm
        .werePrescriptiveDesignStandardsUtilized == YesOrNoSelection.Yes
    ),
  },
  {
    fieldKey: buildFieldKey("Attestation255"),
    hidden: !(
      pathway_for_continuous_load_path_compliance ===
        ContinuousLoadPathPathwayTypes.EngineeredCLPForNewConstruction &&
      form.homeStandardEvaluation.engineeringComplianceContinuousLoadPathForm
        .werePrescriptiveDesignStandardsUtilized == YesOrNoSelection.Yes
    ),
  },
  {
    fieldKey: buildFieldKey("Attestation256"),
    hidden: !(
      pathway_for_continuous_load_path_compliance ===
      ContinuousLoadPathPathwayTypes.EngineeredCLPForNewConstruction
    ),
  },
  {
    fieldKey: buildFieldKey("InstalledRoofToWallConnectionsDescription"),
    hidden: !(
      pathway_for_continuous_load_path_compliance ===
      ContinuousLoadPathPathwayTypes.EngineeredCLPForNewConstruction
    ),
  },
  {
    fieldKey: buildFieldKey("Attestation258"),
    hidden: !(
      pathway_for_continuous_load_path_compliance ===
      ContinuousLoadPathPathwayTypes.EngineeredCLPForNewConstruction
    ),
  },
  {
    fieldKey: buildFieldKey("IsHomeMoreThan1Story"),
    hidden: !(
      pathway_for_continuous_load_path_compliance ===
      ContinuousLoadPathPathwayTypes.EngineeredCLPForNewConstruction
    ),
  },
  {
    fieldKey: buildFieldKey("Attestation260"),
    hidden: !(
      pathway_for_continuous_load_path_compliance ===
        ContinuousLoadPathPathwayTypes.EngineeredCLPForNewConstruction &&
      form.homeStandardEvaluation.engineeringComplianceContinuousLoadPathForm
        .isHomeMoreThan1Story === YesOrNoSelection.Yes
    ),
  },
  {
    fieldKey: buildFieldKey(
      "InstalledWallAboveToWallBelowConnectionsDescriptionType"
    ),
    hidden: !(
      pathway_for_continuous_load_path_compliance ===
        ContinuousLoadPathPathwayTypes.EngineeredCLPForNewConstruction &&
      form.homeStandardEvaluation.engineeringComplianceContinuousLoadPathForm
        .isHomeMoreThan1Story === YesOrNoSelection.Yes
    ),
  },
  {
    fieldKey: buildFieldKey("Attestation262"),
    hidden: !(
      pathway_for_continuous_load_path_compliance ===
      ContinuousLoadPathPathwayTypes.EngineeredCLPForNewConstruction
    ),
  },
  {
    fieldKey: buildFieldKey("Attestation263"),
    hidden: !(
      pathway_for_continuous_load_path_compliance ===
      ContinuousLoadPathPathwayTypes.EngineeredCLPForNewConstruction
    ),
  },
  {
    fieldKey: buildFieldKey("Attestation264"),
    hidden: !(
      pathway_for_continuous_load_path_compliance ===
      ContinuousLoadPathPathwayTypes.EngineeredCLPForNewConstruction
    ),
  },
  {
    fieldKey: buildFieldKey("Attestation265"),
    hidden: !(
      pathway_for_continuous_load_path_compliance ===
      ContinuousLoadPathPathwayTypes.EngineeredCLPForNewConstruction
    ),
  },
];

const engineeringComplianceFormContinuousLoadPathForNewConstructionRequiredFields =
  [
    buildFieldKey("Attestation238"),
    buildFieldKey("Attestation239"),
    buildFieldKey("DoesHomeIncludeConcreteCMUWalls"),
    buildFieldKey("Attestation241"),
    buildFieldKey("Attestation242"),
    buildFieldKey("Attestation243"),
    buildFieldKey("Attestation244"),
    buildFieldKey("InstalledFoundationType"),
    buildFieldKey("InstalledFoundationTypeDescription"),
    buildFieldKey("Attestation246"),
    buildFieldKey("Attestation301"),
    buildFieldKey("Attestation247"),
    buildFieldKey("Attestation248"),
    buildFieldKey("Attestation249"),
    buildFieldKey("WerePrescriptiveDesignStandardsUtilized"),
    buildFieldKey("PrescriptiveDesignStandardsDescription"),
    buildFieldKey("Attestation251"),
    buildFieldKey("Attestation252"),
    buildFieldKey("Attestation253"),
    buildFieldKey("Attestation254"),
    buildFieldKey("Attestation255"),
    buildFieldKey("Attestation256"),
    buildFieldKey("InstalledRoofToWallConnectionsDescription"),
    buildFieldKey("Attestation258"),
    buildFieldKey("IsHomeMoreThan1Story"),
    buildFieldKey("Attestation260"),
    buildFieldKey("InstalledWallAboveToWallBelowConnectionsDescriptionType"),
    buildFieldKey("Attestation262"),
    buildFieldKey("Attestation263"),
    buildFieldKey("Attestation264"),
    buildFieldKey("Attestation265"),
  ];

export const engineeringComplianceFormContinuousLoadPathForNewConstructionBuilder =
  (
    factory: SchemaFactoryV2
  ): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
    return ((onValueChange, form, errors) => {
      const pathway_for_continuous_load_path_compliance =
        form.homeStandardEvaluation.eligibilityForm
          .continuousLoadPathPathwayType;

      const fields = [
        ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
          modelName,
          engineeringComplianceFormContinuousLoadPathForNewConstructionFields(
            pathway_for_continuous_load_path_compliance as ContinuousLoadPathPathwayTypes,
            form
          ),
          onValueChange,
          form,
          errors,
          engineeringComplianceFormContinuousLoadPathForNewConstructionRequiredFields
        ),
      ];
      return fields;
    }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
  };

// Part 2: engineering Compliance Form Continuous Load Path For Retrofit Of Existing Home
const engineeringComplianceFormContinuousLoadPathForRetrofitOfExistingHomeFields =
  (
    pathway_for_continuous_load_path_compliance: ContinuousLoadPathPathwayTypes
  ) => [
    {
      fieldKey: buildFieldKey("Attestation266"),
      hidden: !(
        pathway_for_continuous_load_path_compliance ===
        ContinuousLoadPathPathwayTypes.EngineeredRetrofitOfExistingHome
      ),
    },
    {
      fieldKey: buildFieldKey("Attestation267"),
      hidden: !(
        pathway_for_continuous_load_path_compliance ===
        ContinuousLoadPathPathwayTypes.EngineeredRetrofitOfExistingHome
      ),
    },
  ];

const engineeringComplianceFormContinuousLoadPathForRetrofitOfExistingHomeRequiredFields =
  [buildFieldKey("Attestation266"), buildFieldKey("Attestation267")];

export const engineeringComplianceFormContinuousLoadPathForRetrofitOfExistingHomeBuilder =
  (
    factory: SchemaFactoryV2
  ): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
    return ((onValueChange, form, errors) => {
      const pathway_for_continuous_load_path_compliance =
        form.homeStandardEvaluation.eligibilityForm
          .continuousLoadPathPathwayType;
      const fields = [
        ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
          modelName,
          engineeringComplianceFormContinuousLoadPathForRetrofitOfExistingHomeFields(
            pathway_for_continuous_load_path_compliance as ContinuousLoadPathPathwayTypes
          ),
          onValueChange,
          form,
          errors,
          engineeringComplianceFormContinuousLoadPathForRetrofitOfExistingHomeRequiredFields
        ),
      ];
      return fields;
    }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
  };

// Full form builder with all parts included
export const homeStandardEngineeringComplianceContinuousLoadPathBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const pathway_for_continuous_load_path_compliance =
      form.homeStandardEvaluation.eligibilityForm.continuousLoadPathPathwayType;

    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        [
          ...engineeringComplianceFormContinuousLoadPathForNewConstructionFields(
            pathway_for_continuous_load_path_compliance as ContinuousLoadPathPathwayTypes,
            form
          ),
          ...engineeringComplianceFormContinuousLoadPathForRetrofitOfExistingHomeFields(
            pathway_for_continuous_load_path_compliance as ContinuousLoadPathPathwayTypes
          ),
        ],
        onValueChange,
        form,
        errors,
        [
          ...engineeringComplianceFormContinuousLoadPathForNewConstructionRequiredFields,
          ...engineeringComplianceFormContinuousLoadPathForRetrofitOfExistingHomeRequiredFields,
        ],
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

// Full form builder with all parts included, for Iteration Engine
export const homeStandardIterationEngineEngineeringComplianceContinuousLoadPathFormBuilder =
  (
    factory: SchemaFactoryV2,
    beforeBuild?: (fields: BaseFieldProps<any>[]) => BaseFieldProps<any>[]
  ): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
    return ((onValueChange, form, errors) => {
      const pathway_for_continuous_load_path_compliance =
        form.homeStandardEvaluation.eligibilityForm
          .continuousLoadPathPathwayType;

      let fields = [
        ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
          modelName,
          [
            ...engineeringComplianceFormContinuousLoadPathForNewConstructionFields(
              pathway_for_continuous_load_path_compliance as ContinuousLoadPathPathwayTypes,
              form
            ),
            ...engineeringComplianceFormContinuousLoadPathForRetrofitOfExistingHomeFields(
              pathway_for_continuous_load_path_compliance as ContinuousLoadPathPathwayTypes
            ),
          ],
          onValueChange,
          form,
          errors,
          [
            ...engineeringComplianceFormContinuousLoadPathForNewConstructionRequiredFields,
            ...engineeringComplianceFormContinuousLoadPathForRetrofitOfExistingHomeRequiredFields,
          ],
          {
            FileUploader: WildfireBoxContentExplorer,
          }
        ),
      ];
      if (beforeBuild) {
        fields = beforeBuild(fields);
      }
      return fields;
    }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
  };

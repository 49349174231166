import { FieldFormV2Module } from "@ucl/library";
import { useCallback, useEffect, useRef, useState } from "react";
import { debounce, isNil } from "lodash";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import {
  errorStore,
  genericErrorMessage,
} from "../../../../../../../common/Components/Error/ErrorStore";
import { AppToaster } from "@ucl/library/lib/components/Toast/Toast";
import { Intent } from "@blueprintjs/core";
import { useNavigate } from "react-router";
import { fortifiedHomeNCIUARoutePrefix } from "../../../../../../../foritfied/pages/index/fortifiedHomeRouteConfig";
import { useHomeFieldSchemaFactory } from "../../../../../customHooks/useHomeFieldSchemaFactory";
import {
  BaseHomeRoofingComplianceRoofCoverFormParts,
  getActiveBaseHomeRoofingComplianceRoofCoverFormParts,
  RoofCoverTypes,
} from "../../../../../common/types/BaseHomeEvaluationRoofingComplianceRoofCoverFormModel";
import { Evaluation_HomeNCIUAEvaluationFormModel } from "../../../../evaluation/types/HomeNCIUAEvaluationFormModel";
import { homeNCIUAEvaluationRoofingComplianceRoofCoverAPIClient } from "../../../../evaluation/lib/apiClients/homeNCIUAEvaluationRoofingComplianceRoofCoverAPIClient";
import { homeNCIUAEvaluationAPIClient } from "../../../../evaluation/lib/apiClients/homeNCIUAEvaluationAPIClient";
import { HomeNCIUAEvaluationRoofingComplianceRoofCoverFormProps } from "./HomeNCIUAEvaluationRoofingComplianceRoofCoverForm";

function useHomeNCIUAEvaluationRoofingComplianceRoofCoverForm(
  props: HomeNCIUAEvaluationRoofingComplianceRoofCoverFormProps
) {
  const { builders, wieBuilders } = useHomeFieldSchemaFactory();
  const navigate = useNavigate();

  const formRef =
    useRef<FieldFormV2Module<Evaluation_HomeNCIUAEvaluationFormModel>>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [formModel, setFormModel] =
    useState<Evaluation_HomeNCIUAEvaluationFormModel>();

  const [isInitialized, setIsInitialized] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!isLoading && !isInitialized) {
      setIsInitialized(true);
      props.onInitialized?.();
    }
  }, [isLoading]);

  useEffect(() => {
    if (isInitialized) {
      props.onInitialized?.();
    }
  }, [isInitialized]);

  useEffect(() => {
    //Used to React to External Changes Pushed on the Form (Iteration Engine)
    if (formModel) {
      setFormModel({ ...formModel });
    }
  }, [wieBuilders]);

  useEffect(() => {
    fetchApplicationForm();
  }, []);

  useEffect(() => {
    if (!!formModel) {
      setFormModel(formModel);

      if (props.setFormModel) {
        props.setFormModel(formModel);
      }
    }
  }, [formModel]);

  // Differentiates between save and submit
  let shouldSubmit = false;
  const setShouldSubmit = (value: boolean) => {
    shouldSubmit = value;
  };

  const parts = getActiveBaseHomeRoofingComplianceRoofCoverFormParts(
    formModel?.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
      .roofCoverType as RoofCoverTypes[]
  );

  const isFirstPartOfForm = parts.indexOf(props.formPart!) === 0;
  const isLastPartOfForm = parts.indexOf(props.formPart!) === parts.length - 1;

  const noBuilders = !builders && !wieBuilders;

  const fetchApplicationForm = async () => {
    setIsLoading(true);
    await homeNCIUAEvaluationAPIClient
      .getHomeNCIUAEvaluationFormModel(props.evaluationId)
      .then((response) => {
        setFormModel((prevModel) => ({
          ...prevModel,
          ...response,
        }));
      })
      .catch(() => {
        errorStore.setErrorMessage(genericErrorMessage);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // Debounce setup
  const handleFormSubmitDebounced = useCallback(
    debounce(
      async (
        form: Evaluation_HomeNCIUAEvaluationFormModel,
        _value: any,
        fieldProps: BaseFieldProps<any>
      ) => {
        if (fieldProps.fieldKey) {
          await handleFormSubmit(form, fieldProps.fieldKey).then(async () => {
            await props.onFormSave?.(fieldProps.fieldKey || "", _value);
          });
        }
      },
      2000
    ),
    []
  );

  useEffect(() => {
    // Cleanup the debounce function on component unmount
    return () => handleFormSubmitDebounced.cancel();
  }, [handleFormSubmitDebounced]);

  const onFormFieldChange = async (
    form: Evaluation_HomeNCIUAEvaluationFormModel,
    _value: any,
    fieldProps: BaseFieldProps<any>
  ) => {
    // Call the debounced submit function
    if (!!_value) {
      handleFormSubmitDebounced(form, _value, fieldProps);
      props.setHasUnsavedChanges(true);
    }
  };

  const getApplicationFormPartBuilder = () => {
    if (props.isIterationEnginePage) {
      return wieBuilders?.HomeNCIUAIterationEngineRoofingComplianceRoofCoverForm;
    }
    switch (props.formPart) {
      case BaseHomeRoofingComplianceRoofCoverFormParts.RoofCoverInformation:
        return builders?.HomeNCIUARoofCoverInformation;
      case BaseHomeRoofingComplianceRoofCoverFormParts.AsphaltShingleRoofCoverDetails:
        return builders?.HomeNCIUAAsphaltShingleRoofCoverDetails;
      case BaseHomeRoofingComplianceRoofCoverFormParts.MetalRoofCoverDetails:
        return builders?.HomeNCIUAMetalRoofCoverDetails;
      case BaseHomeRoofingComplianceRoofCoverFormParts.ClayConcreteTileRoofCoverDetails:
        return builders?.HomeNCIUAClayConcreteTileRoofCoverDetails;
      case BaseHomeRoofingComplianceRoofCoverFormParts.WoodShakeSingleRoofCoverDetails:
        return builders?.HomeNCIUAWoodShakeSingleRoofCoverDetails;
      case BaseHomeRoofingComplianceRoofCoverFormParts.LowSlopeRoofCoverDetails:
        return builders?.HomeNCIUALowSlopeRoofCoverDetails;
      default:
        return builders?.HomeNCIUARoofingComplianceRoofCoverForm;
    }
  };

  const handleFormSubmit = async (
    form: Evaluation_HomeNCIUAEvaluationFormModel,
    fieldKey?: string
  ) => {
    setFormModel(form);
    await (shouldSubmit
      ? homeNCIUAEvaluationRoofingComplianceRoofCoverAPIClient.submitHomeNCIUARoofingComplianceRoofCoverForm(
          form.id,
          form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm,
          props.formPart
        )
      : homeNCIUAEvaluationRoofingComplianceRoofCoverAPIClient.updateHomeNCIUARoofingComplianceRoofCoverForm(
          form.id,
          form.homeNCIUAEvaluation.roofingComplianceRoofCoverForm,
          fieldKey
        )
    )
      .then(async (response) => {
        handleFormResponse(response);
      })
      .catch((error) => {
        if (error.response.status !== 400) {
          console.error(error);
          AppToaster.show({
            message: "Unexpected error occurred while saving the form",
            intent: Intent.DANGER,
          });
        }
        throw error;
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleFormResponse = async (
    response: Evaluation_HomeNCIUAEvaluationFormModel
  ) => {
    props.setHasUnsavedChanges(false);

    if (shouldSubmit) {
      setFormModel(response);

      if (isLastPartOfForm) {
        navigate(
          `${fortifiedHomeNCIUARoutePrefix}/checklist/${props.evaluationId}`
        );
      } else {
        const parts = getActiveBaseHomeRoofingComplianceRoofCoverFormParts(
          response.homeNCIUAEvaluation.roofingComplianceRoofCoverForm
            .roofCoverType as RoofCoverTypes[]
        );

        const idx = parts.indexOf(props.formPart!);
        const nextFormPart = parts[idx + 1];

        if (isNil(nextFormPart)) {
          // fallback
          homeNCIUAEvaluationRoofingComplianceRoofCoverAPIClient.submitHomeNCIUARoofingComplianceRoofCoverForm(
            response.id,
            response.homeNCIUAEvaluation.roofingComplianceRoofCoverForm,
            props.formPart
          );
        } else {
          navigate(
            `${fortifiedHomeNCIUARoutePrefix}/${props.evaluationId}/roofing-compliance-roof-cover/${nextFormPart}`
          );
        }
      }
    } else {
      handlePartialResponseFormUpdate(response);
    }
  };

  const handlePartialResponseFormUpdate = (
    response: Evaluation_HomeNCIUAEvaluationFormModel
  ) => {
    // This is used to combat save on blur issues. This only updates fields that are essential to the form.
    // These are set in the API response. Field Counts and sub section completion status
    setFormModel((prevState) => {
      if (!prevState) return prevState;

      const {
        areAllRequiredFieldsComplete,
        roofCoverInformationRequiredFieldCount,
        roofCoverInformationLastSubmittedAt,
        roofCoverInformationHasBeenSubmitted,
        isRoofCoverInformationComplete,
        asphaltShingleRoofCoverDetailsRequiredFieldCount,
        asphaltShingleRoofCoverDetailsLastSubmittedAt,
        asphaltShingleRoofCoverDetailsHasBeenSubmitted,
        isAsphaltShingleRoofCoverDetailsComplete,
        metalRoofCoverDetailsRequiredFieldCount,
        metalRoofCoverDetailsLastSubmittedAt,
        metalRoofCoverDetailsHasBeenSubmitted,
        isMetalRoofCoverDetailsComplete,
        clayConcreteTileRoofCoverDetailsRequiredFieldCount,
        clayConcreteTileRoofCoverDetailsLastSubmittedAt,
        clayConcreteTileRoofCoverDetailsHasBeenSubmitted,
        isClayConcreteTileRoofCoverDetailsComplete,
        woodShakeSingleRoofCoverDetailsRequiredFieldCount,
        woodShakeSingleRoofCoverDetailsLastSubmittedAt,
        woodShakeSingleRoofCoverDetailsHasBeenSubmitted,
        isWoodShakeSingleRoofCoverDetailsComplete,
        lowSlopeRoofCoverDetailsRequiredFieldCount,
        lowSlopeRoofCoverDetailsLastSubmittedAt,
        lowSlopeRoofCoverDetailsHasBeenSubmitted,
        isLowSlopeRoofCoverDetailsComplete,
        roofCoverType,
        roofCoverTypeMinSlope,
        requiredWindRatingZone1,
        requiredWindRatingZone2,
        requiredWindRatingZone3,
        allZonesRequiredWindRatingDocumentation,
        fullyInstalledRoofCoverPhotos,
        areAsphaltShingleManufacturerAndProductDetailsKnown,
        asphaltShingleManufacturerName,
        asphaltShingleProductName,
        shingleManufacturerAndProductPhoto,
        doesAsphaltShingleWindRatingMeetASTMClassFOrClassH,
        asphaltShingleWindRatingTypes: asphaltShingleWindRatingType,
        shingleWindRatingPhoto,
        asphaltShingleHailRatingType,
        shingleHailRatingPhoto,
        doAsphaltShingleNailsMeetFortifiedCorrosionResistanceRequirements,
        areAsphaltShinglesInstalledPerManufacturerRecommendationsForHighWindRegions,
        numberOfInstalledNailsPerAsphaltShingle,
        startStripInstallationAtEavesTypes: startStripInstallationAtEavesType,
        startStripInstallationAtGlobeRakesTypes:
          startStripInstallationAtGlobeRakesType,
        starterStripManufacturerAndProductPhotos,
        starterStripInstallationPhotos,
        asphaltShingleRoofCoverMinSlopePhoto,
        areMetalPanelManufacturerAndProductDetailsKnown,
        metalPanelManufacturerName,
        metalPanelProductName,
        metalPanelCertifiedTestReportProductApprovalNumber,
        metalPanelCertifiedTestReportDocumentation,
        metalPanelSelectNumberOfDifferentSystemsFromCertifiedTestReport,
        metalPanelFirstInstalledSystemNumberPerCertifiedTestReport,
        matelPanelFirstInstalledSystemDesignPressureOrMomentResistanceRating,
        metalPanelSecondInstalledSystemNumberPerCertifiedTestReport,
        metalPanelSecondInstalledSystemDesignPressureOrMomentResistanceRating,
        metalPanelThirdInstalledSystemNumberPerCertifiedTestReport,
        metalPanelThirdInstalledSystemDesignPressureOrMomentResistanceRating,
        metalPanelAreDesignRatingsOfInstalledSystemsListedInCertifiedTestReport,
        doMetalPanelsAndExposedFastenersMeetFortifiedCorrosionResistanceRequirements,
        installedMetalPanelMaterial,
        installedMetalPanelGaugeThickness,
        installedMetalPanelFastenerTypeAndSize,
        metalPanelFastenersPhoto,
        installedMetalPanelFastenerPatternAndSpacingZone1,
        installedMetalPanelFastenerPatternAndSpacingZone2,
        installedMetalPanelFastenerPatternAndSpacingZone3,
        metalPanelInstalledFastenerPatternAndSpacingPhotos,
        wasMetalPanelRoofCoverSystemInstalledInAccordanceToStandards,
        doesMetalPanelSystemHaveUL2218Class4ImpactRating,
        metalPanelRoofCoverMinSlopePhoto,
        areTheClayConcreteTileManufacturerAndProductDetailsKnown,
        clayConcreteTileManufacturerName,
        clayConcreteTileProductName,
        clayConcreteTileTestReportApprovalNumber,
        clayConcreteTileCertifiedTestReportDocumentation,
        clayConcreteTileNumberOfInstalledSystemsFromTestReport,
        clayConcreteTileFirstInstalledSystemNumberPerCertifiedTestReport,
        clayConcreteTileFirstInstalledSystemDesignPressureOrMomentResistanceRating,
        clayConcreteTileSecondInstalledSystemNumberPerCertifiedTestReport,
        clayConcreteTileSecondInstalledSystemDesignPressureOrMomentResistanceRating,
        clayConcreteTileThirdInstalledSystemNumberPerCertifiedTestReport,
        clayConcreteTileThirdInstalledSystemDesignPressureOrMomentResistanceRating,
        clayConcreteTileSystemsIdentifiedFromTestReport,
        clayConcreteTileAreDesignRatingsOfInstalledSystemsListedInCertifiedTestReport,
        doesClayConcreteTileRoofCoverSystemHaveFM4473Class4ImpactRating,
        clayConcreteTileCertifiedTestReportProductApprovalNumberForStructuralSupports,
        tileStructuralSupportsCertifiedTestReportDocumentation,
        mechanicalAdhesiveRoofTileAttachmentType,
        mechanicalFastenerType,
        roofTileFastenersPhoto,
        mechanicalFastenerSize,
        mechanicalFastenerQuantityPerTile,
        doMechanicalTileFastenersMeetFortifiedCorrosionResistanceRequirements,
        adhesiveFoamManufacturerName,
        adhesiveFoamProductName,
        adhesiveFoamTestReportApprovalNumber,
        isAdhesiveFoamInstallerCertifiedByFoamManufacturer,
        adhesiveFoamInstallerCertificationNumber,
        tileUnderlaymentCertifiedTestReportNumber,
        isTileAndFlashingInstalledPerFRSATRIInstructions,
        isTileAndFlashingInstalledPerTRIWSRCAInstructions,
        clayConcreteTileRoofCoverMinSlopePhoto,
        tileRoofCoverSystemInstallationPhoto,
        areTheWoodShakeShingleManufacturerAndProductDetailsKnown,
        woodShakeShingleManufacturerName,
        woodShakeShingleProductName,
        woodShakeShingleTestReportApprovalNumber,
        woodShakeShingleCertifiedTestReportDocumentation,
        woodShakeShingleDesignPressureWindRatingOfInstalledSystem,
        woodShakeShingleIsMeanRoofHeightLimitationPerManufacturerProductSpecifications,
        woodShakeShingleMeanRoofHeightLimitation,
        woodShakeShingleRequiredNumberOfNailsPerShingle,
        woodShakeShingleDoesWindRatingListedInCertifiedTestReportMeetOrExceedDesignConditions,
        doesWoodShakeShingleHaveUL2218Class4OrFM4473Class4ImpactRating,
        woodShakeShingleFastenerType,
        woodShakeShingleFastenersPhoto,
        woodShakeShingleFastenerSize,
        wereStaplesUsedToInstallWoodShakesShingles,
        woodShakeShingleAreStainlessSteelFastenersRequired,
        wereStainlessFastenersUsedToInstallWoodShakesShingles,
        isSealedRoofDeckMaterialCompatibleWithWoodShakeShingle,
        wasWoodShakeShingleRoofCoverSystemInstalledInAccordanceWithCertifiedTestReport,
        woodShakeShingleRoofCoverMinSlopePhoto,
        areTheLowSlopeRoofCoverManufacturerAndProductDetailsKnown,
        lowSlopeRoofCoverManufacturerName,
        lowSlopeRoofCoverProductName,
        lowSlopeRoofCoverTestReportApprovalNumber,
        lowSlopeRoofCoverCertifiedTestReportDocumentation,
        lowSlopeRoofCoverSelectNumberOfDifferentSystemsFromCertifiedTestReport,
        lowSlopeRoofCoverFirstInstalledSystemNumberPerCertifiedTestReport,
        lowSlopeRoofCoverFirstInstalledSystemDesignPressureOrMomentResistanceRating,
        lowSlopeRoofCoverSecondInstalledSystemNumberPerCertifiedTestReport,
        lowSlopeRoofCoverSecondInstalledSystemDesignPressureOrMomentResistanceRating,
        lowSlopeRoofCoverThirdInstalledSystemNumberPerCertifiedTestReport,
        lowSlopeRoofCoverThirdInstalledSystemDesignPressureOrMomentResistanceRating,
        lowSlopeRoofCoverAreDesignRatingsOfInstalledSystemsListedInCertifiedTestReport,
        lowSlopeRoofCoverDoExposedFastenersMeetFortifiedCorrosionResistanceRequirements,
        doesLowSlopeRoofCoverSystemHaveADesignatedImpactRating,
        lowSlopeRoofCoverFastenerTypeAndSize,
        lowSlopeRoofCoverFastenerSpacingZone1,
        lowSlopeRoofCoverFastenerSpacingZone2,
        lowSlopeRoofCoverFastenerSpacingZone3,
        wasLowSlopeRoofCoverInstalledInAccordanceWithCertifiedTestReport,
        lowSlopeRoofCoverMinSlopePhoto,
      } = response.homeNCIUAEvaluation.roofingComplianceRoofCoverForm;

      const { canProceedToApplicationFee } = response.homeNCIUAEvaluation;

      return {
        ...prevState,
        homeNCIUAEvaluation: {
          ...prevState.homeNCIUAEvaluation,
          canProceedToApplicationFee,
          roofingComplianceRoofCoverForm: {
            ...prevState.homeNCIUAEvaluation.roofingComplianceRoofCoverForm,
            areAllRequiredFieldsComplete,
            roofCoverInformationRequiredFieldCount,
            roofCoverInformationLastSubmittedAt,
            roofCoverInformationHasBeenSubmitted,
            isRoofCoverInformationComplete,
            asphaltShingleRoofCoverDetailsRequiredFieldCount,
            asphaltShingleRoofCoverDetailsLastSubmittedAt,
            asphaltShingleRoofCoverDetailsHasBeenSubmitted,
            isAsphaltShingleRoofCoverDetailsComplete,
            metalRoofCoverDetailsRequiredFieldCount,
            metalRoofCoverDetailsLastSubmittedAt,
            metalRoofCoverDetailsHasBeenSubmitted,
            isMetalRoofCoverDetailsComplete,
            clayConcreteTileRoofCoverDetailsRequiredFieldCount,
            clayConcreteTileRoofCoverDetailsLastSubmittedAt,
            clayConcreteTileRoofCoverDetailsHasBeenSubmitted,
            isClayConcreteTileRoofCoverDetailsComplete,
            woodShakeSingleRoofCoverDetailsRequiredFieldCount,
            woodShakeSingleRoofCoverDetailsLastSubmittedAt,
            woodShakeSingleRoofCoverDetailsHasBeenSubmitted,
            isWoodShakeSingleRoofCoverDetailsComplete,
            lowSlopeRoofCoverDetailsRequiredFieldCount,
            lowSlopeRoofCoverDetailsLastSubmittedAt,
            lowSlopeRoofCoverDetailsHasBeenSubmitted,
            isLowSlopeRoofCoverDetailsComplete,
            roofCoverType,
            roofCoverTypeMinSlope,
            requiredWindRatingZone1,
            requiredWindRatingZone2,
            requiredWindRatingZone3,
            allZonesRequiredWindRatingDocumentation,
            fullyInstalledRoofCoverPhotos,
            areAsphaltShingleManufacturerAndProductDetailsKnown,
            asphaltShingleManufacturerName,
            asphaltShingleProductName,
            shingleManufacturerAndProductPhoto,
            doesAsphaltShingleWindRatingMeetASTMClassFOrClassH,
            asphaltShingleWindRatingTypes: asphaltShingleWindRatingType,
            shingleWindRatingPhoto,
            asphaltShingleHailRatingType,
            shingleHailRatingPhoto,
            doAsphaltShingleNailsMeetFortifiedCorrosionResistanceRequirements,
            areAsphaltShinglesInstalledPerManufacturerRecommendationsForHighWindRegions,
            numberOfInstalledNailsPerAsphaltShingle,
            startStripInstallationAtEavesTypes:
              startStripInstallationAtEavesType,
            startStripInstallationAtGlobeRakesTypes:
              startStripInstallationAtGlobeRakesType,
            starterStripManufacturerAndProductPhotos,
            starterStripInstallationPhotos,
            asphaltShingleRoofCoverMinSlopePhoto,
            areMetalPanelManufacturerAndProductDetailsKnown,
            metalPanelManufacturerName,
            metalPanelProductName,
            metalPanelCertifiedTestReportProductApprovalNumber,
            metalPanelCertifiedTestReportDocumentation,
            metalPanelSelectNumberOfDifferentSystemsFromCertifiedTestReport,
            metalPanelFirstInstalledSystemNumberPerCertifiedTestReport,
            matelPanelFirstInstalledSystemDesignPressureOrMomentResistanceRating,
            metalPanelSecondInstalledSystemNumberPerCertifiedTestReport,
            metalPanelSecondInstalledSystemDesignPressureOrMomentResistanceRating,
            metalPanelThirdInstalledSystemNumberPerCertifiedTestReport,
            metalPanelThirdInstalledSystemDesignPressureOrMomentResistanceRating,
            metalPanelAreDesignRatingsOfInstalledSystemsListedInCertifiedTestReport,
            doMetalPanelsAndExposedFastenersMeetFortifiedCorrosionResistanceRequirements,
            installedMetalPanelMaterial,
            installedMetalPanelGaugeThickness,
            installedMetalPanelFastenerTypeAndSize,
            metalPanelFastenersPhoto,
            installedMetalPanelFastenerPatternAndSpacingZone1,
            installedMetalPanelFastenerPatternAndSpacingZone2,
            installedMetalPanelFastenerPatternAndSpacingZone3,
            metalPanelInstalledFastenerPatternAndSpacingPhotos,
            wasMetalPanelRoofCoverSystemInstalledInAccordanceToStandards,
            doesMetalPanelSystemHaveUL2218Class4ImpactRating,
            metalPanelRoofCoverMinSlopePhoto,
            areTheClayConcreteTileManufacturerAndProductDetailsKnown,
            clayConcreteTileManufacturerName,
            clayConcreteTileProductName,
            clayConcreteTileTestReportApprovalNumber,
            clayConcreteTileCertifiedTestReportDocumentation,
            clayConcreteTileNumberOfInstalledSystemsFromTestReport,
            clayConcreteTileFirstInstalledSystemNumberPerCertifiedTestReport,
            clayConcreteTileFirstInstalledSystemDesignPressureOrMomentResistanceRating,
            clayConcreteTileSecondInstalledSystemNumberPerCertifiedTestReport,
            clayConcreteTileSecondInstalledSystemDesignPressureOrMomentResistanceRating,
            clayConcreteTileThirdInstalledSystemNumberPerCertifiedTestReport,
            clayConcreteTileThirdInstalledSystemDesignPressureOrMomentResistanceRating,
            clayConcreteTileSystemsIdentifiedFromTestReport,
            clayConcreteTileAreDesignRatingsOfInstalledSystemsListedInCertifiedTestReport,
            doesClayConcreteTileRoofCoverSystemHaveFM4473Class4ImpactRating,
            clayConcreteTileCertifiedTestReportProductApprovalNumberForStructuralSupports,
            tileStructuralSupportsCertifiedTestReportDocumentation,
            mechanicalAdhesiveRoofTileAttachmentType,
            mechanicalFastenerType,
            roofTileFastenersPhoto,
            mechanicalFastenerSize,
            mechanicalFastenerQuantityPerTile,
            doMechanicalTileFastenersMeetFortifiedCorrosionResistanceRequirements,
            adhesiveFoamManufacturerName,
            adhesiveFoamProductName,
            adhesiveFoamTestReportApprovalNumber,
            isAdhesiveFoamInstallerCertifiedByFoamManufacturer,
            adhesiveFoamInstallerCertificationNumber,
            tileUnderlaymentCertifiedTestReportNumber,
            isTileAndFlashingInstalledPerFRSATRIInstructions,
            isTileAndFlashingInstalledPerTRIWSRCAInstructions,
            clayConcreteTileRoofCoverMinSlopePhoto,
            tileRoofCoverSystemInstallationPhoto,
            areTheWoodShakeShingleManufacturerAndProductDetailsKnown,
            woodShakeShingleManufacturerName,
            woodShakeShingleProductName,
            woodShakeShingleTestReportApprovalNumber,
            woodShakeShingleCertifiedTestReportDocumentation,
            woodShakeShingleDesignPressureWindRatingOfInstalledSystem,
            woodShakeShingleIsMeanRoofHeightLimitationPerManufacturerProductSpecifications,
            woodShakeShingleMeanRoofHeightLimitation,
            woodShakeShingleRequiredNumberOfNailsPerShingle,
            woodShakeShingleDoesWindRatingListedInCertifiedTestReportMeetOrExceedDesignConditions,
            doesWoodShakeShingleHaveUL2218Class4OrFM4473Class4ImpactRating,
            woodShakeShingleFastenerType,
            woodShakeShingleFastenersPhoto,
            woodShakeShingleFastenerSize,
            wereStaplesUsedToInstallWoodShakesShingles,
            woodShakeShingleAreStainlessSteelFastenersRequired,
            wereStainlessFastenersUsedToInstallWoodShakesShingles,
            isSealedRoofDeckMaterialCompatibleWithWoodShakeShingle,
            wasWoodShakeShingleRoofCoverSystemInstalledInAccordanceWithCertifiedTestReport,
            woodShakeShingleRoofCoverMinSlopePhoto,
            areTheLowSlopeRoofCoverManufacturerAndProductDetailsKnown,
            lowSlopeRoofCoverManufacturerName,
            lowSlopeRoofCoverProductName,
            lowSlopeRoofCoverTestReportApprovalNumber,
            lowSlopeRoofCoverCertifiedTestReportDocumentation,
            lowSlopeRoofCoverSelectNumberOfDifferentSystemsFromCertifiedTestReport,
            lowSlopeRoofCoverFirstInstalledSystemNumberPerCertifiedTestReport,
            lowSlopeRoofCoverFirstInstalledSystemDesignPressureOrMomentResistanceRating,
            lowSlopeRoofCoverSecondInstalledSystemNumberPerCertifiedTestReport,
            lowSlopeRoofCoverSecondInstalledSystemDesignPressureOrMomentResistanceRating,
            lowSlopeRoofCoverThirdInstalledSystemNumberPerCertifiedTestReport,
            lowSlopeRoofCoverThirdInstalledSystemDesignPressureOrMomentResistanceRating,
            lowSlopeRoofCoverAreDesignRatingsOfInstalledSystemsListedInCertifiedTestReport,
            lowSlopeRoofCoverDoExposedFastenersMeetFortifiedCorrosionResistanceRequirements,
            doesLowSlopeRoofCoverSystemHaveADesignatedImpactRating,
            lowSlopeRoofCoverFastenerTypeAndSize,
            lowSlopeRoofCoverFastenerSpacingZone1,
            lowSlopeRoofCoverFastenerSpacingZone2,
            lowSlopeRoofCoverFastenerSpacingZone3,
            wasLowSlopeRoofCoverInstalledInAccordanceWithCertifiedTestReport,
            lowSlopeRoofCoverMinSlopePhoto,
          },
        },
      };
    });
  };

  return {
    builders,
    wieBuilders,
    formRef,
    isLoading,
    isSubmitting,
    setIsSubmitting,
    formModel,
    isFirstPartOfForm,
    isLastPartOfForm,
    getApplicationFormPartBuilder,
    handleFormSubmit,
    onFormFieldChange,
    noBuilders,
    containerRef,
    setShouldSubmit,
  };
}

export default useHomeNCIUAEvaluationRoofingComplianceRoofCoverForm;

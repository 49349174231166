import {
  EnumRadioFieldProps,
  EnumSingleSelectFieldV2Props,
  FieldSchemaFormBuilderV2,
  NumericFieldProps,
  SchemaFactoryV2,
} from "@ucl/library";
import { Evaluation_HomeStandardEvaluationFormModel } from "../../../types/HomeEvaluationFormModel";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import {
  WildfireBoxContentExplorer,
  WildfireBoxContentExplorerProps,
} from "../../../../../../wildfire/components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";
import {
  ReadonlyWarningBannerField,
  ReadonlyWarningBannerFieldProps,
} from "../../../../../Common/components/Fields/ReadonlyWarningBanner/ReadonlyWarningBannerField";
import { YesOrNoSelection } from "../../../../../../wildfire/types/FormFields";
import { HazardTypes } from "../../../../../Common/types/Evaluation/BaseEvaluation";
import {
  BuildingElementTypes,
  DesignationLevelTypes,
} from "../../../../common/types/BaseHomeEvaluationEligibilityFormModel";
import { SoffitCoverTypes } from "../../../types/HomeStandardEvaluationSilverGoldComplianceGeneralInformationFormModel";
import { permissionStore } from "../../../../../../foritfied/stores/PermissionStore";

const modelName = "Evaluation";
const subModelName = "HomeStandardEvaluation";
const formName = "SilverGoldComplianceGeneralInformationForm";

const buildFieldKey = (fieldName: string) =>
  `${modelName}__${subModelName}__${formName}__${fieldName}`;

const siteDesignInformationFields = (
  form: Evaluation_HomeStandardEvaluationFormModel
) => [
  //1. Site Design Information
  // Did Engineer Specify Wind Pressures for Zone 4 and 5
  {
    fieldKey: buildFieldKey("DidEngineerSpecifyWindPressuresForZone4And5"),
    hidden: !(
      form.homeStandardEvaluation.eligibilityForm
        .isEngineerProvidingComplianceDesignInfoForSilverOrGoldRequirements ===
      YesOrNoSelection.Yes
    ),
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey("Zone4PositiveRequiredWallWindPressure"),
    hidden: !(
      form.homeStandardEvaluation.silverGoldComplianceGeneralInformationForm
        .didEngineerSpecifyWindPressuresForZone4And5 === YesOrNoSelection.Yes
    ),
    min: 0,
    placeholder: "xx.x",
    showIncrementButtons: false,
    format: "###.#",
  } as NumericFieldProps,
  {
    fieldKey: buildFieldKey("Zone4NegativeRequiredWallWindPressure"),
    hidden: !(
      form.homeStandardEvaluation.silverGoldComplianceGeneralInformationForm
        .didEngineerSpecifyWindPressuresForZone4And5 === YesOrNoSelection.Yes
    ),
    max: 0,
    placeholder: "-xx.x",
    showIncrementButtons: false,
    format: "###.#",
  } as NumericFieldProps,
  {
    fieldKey: buildFieldKey("Zone5PositiveRequiredWallWindPressure"),
    hidden: !(
      form.homeStandardEvaluation.silverGoldComplianceGeneralInformationForm
        .didEngineerSpecifyWindPressuresForZone4And5 === YesOrNoSelection.Yes
    ),
    min: 0,
    placeholder: "xx.x",
    showIncrementButtons: false,
    format: "###.#",
  } as NumericFieldProps,
  {
    fieldKey: buildFieldKey("Zone5NegativeRequiredWallWindPressure"),
    hidden: !(
      form.homeStandardEvaluation.silverGoldComplianceGeneralInformationForm
        .didEngineerSpecifyWindPressuresForZone4And5 === YesOrNoSelection.Yes
    ),
    max: 0,
    placeholder: "-xx.x",
    showIncrementButtons: false,
    format: "###.#",
  } as NumericFieldProps,
  {
    fieldKey: buildFieldKey("WallWindPressuresDocumentation"),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden: !(
      form.homeStandardEvaluation.silverGoldComplianceGeneralInformationForm
        .didEngineerSpecifyWindPressuresForZone4And5 === YesOrNoSelection.Yes
    ),
  } as WildfireBoxContentExplorerProps,
];

const siteDesignInformationRequiredFields = [
  buildFieldKey("DidEngineerSpecifyWindPressuresForZone4And5"),
  buildFieldKey("Zone4PositiveRequiredWallWindPressure"),
  buildFieldKey("Zone4NegativeRequiredWallWindPressure"),
  buildFieldKey("Zone5PositiveRequiredWallWindPressure"),
  buildFieldKey("Zone5NegativeRequiredWallWindPressure"),
  buildFieldKey("WallWindPressuresDocumentation"),
];

export const silverGoldSiteDesignInformationFormBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        siteDesignInformationFields(form),
        onValueChange,
        form,
        errors,
        siteDesignInformationRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
          WarningBanner: ReadonlyWarningBannerField,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

const windowsAndSkylightsFieldConfig = (
  form: Evaluation_HomeStandardEvaluationFormModel
) => [
  //2. Windows and Skylights
  buildFieldKey("AreSkylightsOrLightTunnelsPresent"),
  {
    fieldKey: buildFieldKey("AreSkylightsImpactRated"),
    hidden: !(
      form.homeStandardEvaluation.silverGoldComplianceGeneralInformationForm
        .areSkylightsOrLightTunnelsPresent === YesOrNoSelection.Yes &&
      form.hazard === HazardTypes.Hurricane
    ),
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey("AreSkylightsImpactRatedWarningBanner"),
    hidden: !(
      form.homeStandardEvaluation.silverGoldComplianceGeneralInformationForm
        .areSkylightsImpactRated === YesOrNoSelection.No
    ),
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey("SkylightDebrisImpactRatingPhotos"),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden: !(
      form.homeStandardEvaluation.silverGoldComplianceGeneralInformationForm
        .areSkylightsOrLightTunnelsPresent === YesOrNoSelection.Yes &&
      form.hazard === HazardTypes.Hurricane
    ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("SkylightDebrisImpactRatingDocumentation"),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden: !(
      form.homeStandardEvaluation.silverGoldComplianceGeneralInformationForm
        .areSkylightsOrLightTunnelsPresent === YesOrNoSelection.Yes &&
      form.hazard === HazardTypes.Hurricane
    ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("AreWindowsImpactRatedOrProtected"),
    hidden: !(form.hazard === HazardTypes.Hurricane),
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey("AreWindowsImpactRatedOrProtectedWarningBanner"),
    hidden: !(
      form.homeStandardEvaluation.silverGoldComplianceGeneralInformationForm
        .areWindowsImpactRatedOrProtected === YesOrNoSelection.No
    ),
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey("WindowLabelsAndImpactRatedCoverPhotos"),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden: !(
      form.homeStandardEvaluation.silverGoldComplianceGeneralInformationForm
        .areWindowsImpactRatedOrProtected === YesOrNoSelection.Yes
    ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("WindowRatingAndImpactRatedCoverDocumentation"),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden: !(
      form.homeStandardEvaluation.silverGoldComplianceGeneralInformationForm
        .areWindowsImpactRatedOrProtected === YesOrNoSelection.Yes
    ),
  } as WildfireBoxContentExplorerProps,
];

const windowsAndSkylightsRequiredFields = [
  buildFieldKey("AreSkylightsOrLightTunnelsPresent"),
  buildFieldKey("AreSkylightsImpactRated"),
  buildFieldKey("AreSkylightsImpactRatedWarningBanner"),
  buildFieldKey("SkylightDebrisImpactRatingPhotos"),
  buildFieldKey("SkylightDebrisImpactRatingDocumentation"),
  buildFieldKey("AreWindowsImpactRatedOrProtected"),
  buildFieldKey("AreWindowsImpactRatedOrProtectedWarningBanner"),
  buildFieldKey("WindowLabelsAndImpactRatedCoverPhotos"),
  buildFieldKey("WindowRatingAndImpactRatedCoverDocumentation"),
];

export const silverGoldWindowsAndSkylightsFormBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        windowsAndSkylightsFieldConfig(form),
        onValueChange,
        form,
        errors,
        windowsAndSkylightsRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
          WarningBanner: ReadonlyWarningBannerField,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

const entryDoorsFieldConfig = (
  form: Evaluation_HomeStandardEvaluationFormModel
) => [
  //3. Entry Doors
  {
    fieldKey: buildFieldKey("AreEntryDoorsImpactRatedOrProtected"),
    hidden: !(form.hazard === HazardTypes.Hurricane),
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey("AreEntryDoorsImpactRatedOrProtectedWarningBanner"),
    hidden: !(
      form.homeStandardEvaluation.silverGoldComplianceGeneralInformationForm
        .areEntryDoorsImpactRatedOrProtected === YesOrNoSelection.No
    ),
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey("EntryDoorLabelAndImpactRatedCoverPhotos"),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden: !(
      form.homeStandardEvaluation.silverGoldComplianceGeneralInformationForm
        .areEntryDoorsImpactRatedOrProtected === YesOrNoSelection.Yes
    ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("EntryDoorRatingAndImpactRatedCoverDocumentation"),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden: !(
      form.homeStandardEvaluation.silverGoldComplianceGeneralInformationForm
        .areEntryDoorsImpactRatedOrProtected === YesOrNoSelection.Yes
    ),
  } as WildfireBoxContentExplorerProps,
];

const entryDoorsRequiredFields = [
  buildFieldKey("AreEntryDoorsImpactRatedOrProtected"),
  buildFieldKey("AreEntryDoorsImpactRatedOrProtectedWarningBanner"),
  buildFieldKey("EntryDoorLabelAndImpactRatedCoverPhotos"),
  buildFieldKey("EntryDoorRatingAndImpactRatedCoverDocumentation"),
];

export const silverGoldEntryDoorsFormBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        entryDoorsFieldConfig(form),
        onValueChange,
        form,
        errors,
        entryDoorsRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
          WarningBanner: ReadonlyWarningBannerField,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

const garageFieldConfig = (
  form: Evaluation_HomeStandardEvaluationFormModel
) => [
  //4. Garage
  buildFieldKey("DoesHomeHaveGarageDoors"),
  {
    fieldKey: buildFieldKey("DoesGarageDoorsHaveGlazing"),
    hidden: !(
      form.homeStandardEvaluation.silverGoldComplianceGeneralInformationForm
        .doesHomeHaveGarageDoors === YesOrNoSelection.Yes &&
      form.hazard === HazardTypes.Hurricane
    ),
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey("AreGarageDoorsImpactRatedOrProtected"),
    hidden: !(
      form.homeStandardEvaluation.silverGoldComplianceGeneralInformationForm
        .doesHomeHaveGarageDoors === YesOrNoSelection.Yes &&
      form.hazard === HazardTypes.Hurricane &&
      form.homeStandardEvaluation.silverGoldComplianceGeneralInformationForm
        .doesGarageDoorsHaveGlazing === YesOrNoSelection.Yes
    ),
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey(
      "AreGarageDoorsImpactRatedOrProtectedWarningBanner"
    ),
    hidden: !(
      form.homeStandardEvaluation.silverGoldComplianceGeneralInformationForm
        .areGarageDoorsImpactRatedOrProtected === YesOrNoSelection.No
    ),
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey("IsGarageDoorDesignPressureRated"),
    hidden: !(
      form.homeStandardEvaluation.silverGoldComplianceGeneralInformationForm
        .doesHomeHaveGarageDoors === YesOrNoSelection.Yes
    ),
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey("DesignPressureRatingOfGarageDoorsPositive"),
    hidden: !(
      form.homeStandardEvaluation.silverGoldComplianceGeneralInformationForm
        .doesHomeHaveGarageDoors === YesOrNoSelection.Yes &&
      form.homeStandardEvaluation.silverGoldComplianceGeneralInformationForm
        .isGarageDoorDesignPressureRated === YesOrNoSelection.Yes
    ),
    min: 0,
    placeholder: "xx.x",
    showIncrementButtons: false,
    format: "###.#",
  } as NumericFieldProps,
  {
    fieldKey: buildFieldKey("DesignPressureRatingOfGarageDoorsNegative"),
    hidden: !(
      form.homeStandardEvaluation.silverGoldComplianceGeneralInformationForm
        .doesHomeHaveGarageDoors === YesOrNoSelection.Yes &&
      form.homeStandardEvaluation.silverGoldComplianceGeneralInformationForm
        .isGarageDoorDesignPressureRated === YesOrNoSelection.Yes
    ),
    max: 0,
    placeholder: "-xx.x",
    showIncrementButtons: false,
    format: "###.#",
  } as NumericFieldProps,
  {
    fieldKey: buildFieldKey("DoesGarageDoorPressureRatingMeetOrExceed"),
    hidden: !(
      form.homeStandardEvaluation.silverGoldComplianceGeneralInformationForm
        .doesHomeHaveGarageDoors === YesOrNoSelection.Yes &&
      form.homeStandardEvaluation.silverGoldComplianceGeneralInformationForm
        .isGarageDoorDesignPressureRated === YesOrNoSelection.Yes
    ),
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey(
      "DoesGarageDoorPressureRatingMeetOrExceedWarningBanner"
    ),
    hidden: !(
      form.homeStandardEvaluation.silverGoldComplianceGeneralInformationForm
        .doesGarageDoorPressureRatingMeetOrExceed === YesOrNoSelection.No
    ),
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey("GarageDoorLabelAndImpactRatedCoverPhotos"),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden: !(
      form.homeStandardEvaluation.silverGoldComplianceGeneralInformationForm
        .doesHomeHaveGarageDoors === YesOrNoSelection.Yes
    ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("GarageDoorRatingAndImpactRatedCoverDocumentation"),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden: !(
      form.homeStandardEvaluation.silverGoldComplianceGeneralInformationForm
        .doesHomeHaveGarageDoors === YesOrNoSelection.Yes
    ),
  } as WildfireBoxContentExplorerProps,
];

const garageRequiredFields = [
  buildFieldKey("DoesHomeHaveGarageDoors"),
  buildFieldKey("DoesGarageDoorsHaveGlazing"),
  buildFieldKey("AreGarageDoorsImpactRatedOrProtected"),
  buildFieldKey("AreGarageDoorsImpactRatedOrProtectedWarningBanner"),
  buildFieldKey("IsGarageDoorDesignPressureRated"),
  buildFieldKey("DesignPressureRatingOfGarageDoorsPositive"),
  buildFieldKey("DesignPressureRatingOfGarageDoorsNegative"),
  buildFieldKey("DoesGarageDoorPressureRatingMeetOrExceed"),
  buildFieldKey("DoesGarageDoorPressureRatingMeetOrExceedWarningBanner"),
  buildFieldKey("GarageDoorLabelAndImpactRatedCoverPhotos"),
  buildFieldKey("GarageDoorRatingAndImpactRatedCoverDocumentation"),
];

export const silverGoldGarageFormBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        garageFieldConfig(form),
        onValueChange,
        form,
        errors,
        garageRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
          WarningBanner: ReadonlyWarningBannerField,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

const gableEndsFieldConfig = (
  form: Evaluation_HomeStandardEvaluationFormModel
) => [
  //5. Gable Ends
  {
    fieldKey: buildFieldKey("GableEndSheathing"),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden:
      !form.homeStandardEvaluation.eligibilityForm.buildingElementTypes?.includes(
        BuildingElementTypes.GablesOver3Feet
      ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("GableEndBracing"),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden:
      !form.homeStandardEvaluation.eligibilityForm.buildingElementTypes?.includes(
        BuildingElementTypes.GablesOver3Feet
      ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("GableEndOverhangs"),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden: !(
      form.homeStandardEvaluation.eligibilityForm.buildingElementTypes?.includes(
        BuildingElementTypes.GableOverhangs
      ) && form.hazard === HazardTypes.Hurricane
    ),
  } as WildfireBoxContentExplorerProps,
];

const gableEndsRequiredFields = [
  buildFieldKey("GableEndSheathing"),
  buildFieldKey("GableEndBracing"),
  buildFieldKey("GableEndOverhangs"),
];

export const silverGoldGableEndsFormBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        gableEndsFieldConfig(form),
        onValueChange,
        form,
        errors,
        gableEndsRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
          WarningBanner: ReadonlyWarningBannerField,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

const soffitsFieldConfig = (
  form: Evaluation_HomeStandardEvaluationFormModel
) => [
  //6. Soffits
  {
    fieldKey: buildFieldKey("SoffitCoverType"),
    hidden: !(form.hazard === HazardTypes.Hurricane),
  } as EnumSingleSelectFieldV2Props,
  {
    fieldKey: buildFieldKey("FlexibleSoffitCoverPhoto"),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden: !(
      form.homeStandardEvaluation.silverGoldComplianceGeneralInformationForm
        .soffitCoverType === SoffitCoverTypes.Flexible &&
      form.hazard === HazardTypes.Hurricane
    ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("RigidSoffitCoverPhoto"),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden: !(
      form.homeStandardEvaluation.silverGoldComplianceGeneralInformationForm
        .soffitCoverType === SoffitCoverTypes.Rigid &&
      form.hazard === HazardTypes.Hurricane
    ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("DoesSoffitOverhangExtendMoreThan12Inches"),
    hidden: !(
      form.homeStandardEvaluation.silverGoldComplianceGeneralInformationForm
        .soffitCoverType === SoffitCoverTypes.Flexible &&
      form.hazard === HazardTypes.Hurricane
    ),
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey("SoffitBracingPhoto"),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden: !(
      form.homeStandardEvaluation.silverGoldComplianceGeneralInformationForm
        .doesSoffitOverhangExtendMoreThan12Inches === YesOrNoSelection.Yes &&
      form.hazard === HazardTypes.Hurricane
    ),
  } as WildfireBoxContentExplorerProps,
];

const soffitsRequiredFields = [
  buildFieldKey("SoffitCoverType"),
  buildFieldKey("FlexibleSoffitCoverPhoto"),
  buildFieldKey("RigidSoffitCoverPhoto"),
  buildFieldKey("DoesSoffitOverhangExtendMoreThan12Inches"),
  buildFieldKey("SoffitBracingPhoto"),
];

export const silverGoldSoffitsFormBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        soffitsFieldConfig(form),
        onValueChange,
        form,
        errors,
        soffitsRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
          WarningBanner: ReadonlyWarningBannerField,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

const chimneysFieldConfig = (
  form: Evaluation_HomeStandardEvaluationFormModel
) => [
  //7. Chimneys
  {
    fieldKey: buildFieldKey("ChimneyDetails"),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden:
      !form.homeStandardEvaluation.eligibilityForm.buildingElementTypes?.includes(
        BuildingElementTypes.Chimneys
      ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("ChimneyLocation"),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden:
      !form.homeStandardEvaluation.eligibilityForm.buildingElementTypes?.includes(
        BuildingElementTypes.Chimneys
      ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("ChimneyFramingAndAnchorage"),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden:
      !form.homeStandardEvaluation.eligibilityForm.buildingElementTypes?.includes(
        BuildingElementTypes.Chimneys
      ),
  } as WildfireBoxContentExplorerProps,
];

const chimneysRequiredFields = [
  buildFieldKey("ChimneyDetails"),
  buildFieldKey("ChimneyLocation"),
  buildFieldKey("ChimneyFramingAndAnchorage"),
];

export const silverGoldChimneysFormBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        chimneysFieldConfig(form),
        onValueChange,
        form,
        errors,
        chimneysRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
          WarningBanner: ReadonlyWarningBannerField,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

const attachedStructuresFieldConfig = (
  form: Evaluation_HomeStandardEvaluationFormModel
) => [
  //8. Attached Structures
  {
    fieldKey: buildFieldKey("AttachedStructureRoofToBeam"),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden: !(
      form.homeStandardEvaluation.eligibilityForm.buildingElementTypes?.includes(
        BuildingElementTypes.SingleLevelAttachedStructures
      ) ||
      form.homeStandardEvaluation.eligibilityForm.buildingElementTypes?.includes(
        BuildingElementTypes.MultiLevelAttachedStructures
      )
    ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("SingleLevelAttachedStructureBeamToColumn"),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden:
      !form.homeStandardEvaluation.eligibilityForm.buildingElementTypes?.includes(
        BuildingElementTypes.SingleLevelAttachedStructures
      ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("SingleLevelAttachedStructureColumnToFoundation"),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden:
      !form.homeStandardEvaluation.eligibilityForm.buildingElementTypes?.includes(
        BuildingElementTypes.SingleLevelAttachedStructures
      ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey(
      "MultiLevelAttachedStructureRoofBeamToUpperLevelColumns"
    ),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden:
      !form.homeStandardEvaluation.eligibilityForm.buildingElementTypes?.includes(
        BuildingElementTypes.MultiLevelAttachedStructures
      ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey(
      "MultiLevelAttachedStructureUpperLevelColumnToLowerLevelColumnOrMiddleFloorBeams"
    ),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden:
      !form.homeStandardEvaluation.eligibilityForm.buildingElementTypes?.includes(
        BuildingElementTypes.MultiLevelAttachedStructures
      ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey(
      "MultiLevelAttachedStructureMiddleFloorBeamToLowerLevelColumn"
    ),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden:
      !form.homeStandardEvaluation.eligibilityForm.buildingElementTypes?.includes(
        BuildingElementTypes.MultiLevelAttachedStructures
      ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey(
      "MultiLevelAttachedStructureLowerLevelColumnToFoundation"
    ),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden:
      !form.homeStandardEvaluation.eligibilityForm.buildingElementTypes?.includes(
        BuildingElementTypes.MultiLevelAttachedStructures
      ),
  } as WildfireBoxContentExplorerProps,
];

const attachedStructuresRequiredFields = [
  buildFieldKey("AttachedStructureRoofToBeam"),
  buildFieldKey("SingleLevelAttachedStructureBeamToColumn"),
  buildFieldKey("SingleLevelAttachedStructureColumnToFoundation"),
  buildFieldKey("MultiLevelAttachedStructureRoofBeamToUpperLevelColumns"),
  buildFieldKey(
    "MultiLevelAttachedStructureUpperLevelColumnToLowerLevelColumnOrMiddleFloorBeams"
  ),
  buildFieldKey("MultiLevelAttachedStructureMiddleFloorBeamToLowerLevelColumn"),
  buildFieldKey("MultiLevelAttachedStructureLowerLevelColumnToFoundation"),
];

export const silverGoldAttachedStructuresFormBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        attachedStructuresFieldConfig(form),
        onValueChange,
        form,
        errors,
        attachedStructuresRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
          WarningBanner: ReadonlyWarningBannerField,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

const designPressureRatedOpeningsFieldConfig = (
  form: Evaluation_HomeStandardEvaluationFormModel
) => [
  //9. Design Pressue Rated Openings
  {
    fieldKey: buildFieldKey("DoWindowPressureRatingsMeetOrExceed"),
    hidden: !(
      form?.homeStandardEvaluation.eligibilityForm.designationLevel ===
        DesignationLevelTypes.Gold && form?.hazard === HazardTypes.Hurricane
    ),
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey("DoWindowPressureRatingsMeetOrExceedWarningBanner"),
    hidden: !(
      form.homeStandardEvaluation.silverGoldComplianceGeneralInformationForm
        .doWindowPressureRatingsMeetOrExceed === YesOrNoSelection.No
    ),
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey("DoEntryDoorPressureRatingsMeetOrExceed"),
    hidden: !(
      form.homeStandardEvaluation.eligibilityForm.designationLevel ===
        DesignationLevelTypes.Gold && form.hazard === HazardTypes.Hurricane
    ),
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey(
      "DoEntryDoorPressureRatingsMeetOrExceedWarningBanner"
    ),
    hidden: !(
      form.homeStandardEvaluation.silverGoldComplianceGeneralInformationForm
        .doEntryDoorPressureRatingsMeetOrExceed === YesOrNoSelection.No
    ),
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey("DoSkylightsDesignPressuresMeetOrExceed"),
    hidden: !(
      form.homeStandardEvaluation.silverGoldComplianceGeneralInformationForm
        .areSkylightsOrLightTunnelsPresent === YesOrNoSelection.Yes &&
      form.hazard === HazardTypes.Hurricane &&
      form.homeStandardEvaluation.eligibilityForm.designationLevel ===
        DesignationLevelTypes.Gold
    ),
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey(
      "DoSkylightsDesignPressuresMeetOrExceedWarningBanner"
    ),
    hidden: !(
      form.homeStandardEvaluation.silverGoldComplianceGeneralInformationForm
        .doSkylightsDesignPressuresMeetOrExceed === YesOrNoSelection.No
    ),
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey(
      "AdditionalDesignPressureRatingDocumentationForOpenings"
    ),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden: !(
      form.hazard === HazardTypes.Hurricane &&
      form.homeStandardEvaluation.eligibilityForm.designationLevel ===
        DesignationLevelTypes.Gold
    ),
  } as WildfireBoxContentExplorerProps,
];

const designPressureRatedOpeningsRequiredFields = [
  buildFieldKey("DoWindowPressureRatingsMeetOrExceed"),
  buildFieldKey("DoWindowPressureRatingsMeetOrExceedWarningBanner"),
  buildFieldKey("DoEntryDoorPressureRatingsMeetOrExceed"),
  buildFieldKey("DoEntryDoorPressureRatingsMeetOrExceedWarningBanner"),
  buildFieldKey("DoSkylightsDesignPressuresMeetOrExceed"),
  buildFieldKey("DoSkylightsDesignPressuresMeetOrExceedWarningBanner"),
];

export const silverGoldDesignPressureRatedOpeningsFormBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        designPressureRatedOpeningsFieldConfig(form),
        onValueChange,
        form,
        errors,
        designPressureRatedOpeningsRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
          WarningBanner: ReadonlyWarningBannerField,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

const wallSheathingFieldConfig = (
  form: Evaluation_HomeStandardEvaluationFormModel
) => [
  //10. Wall Sheathing
  {
    fieldKey: buildFieldKey("WallSheathingTypeAndThickness"),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden: !(
      form.hazard === HazardTypes.Hurricane &&
      form.homeStandardEvaluation.eligibilityForm.designationLevel ===
        DesignationLevelTypes.Gold
    ),
  } as WildfireBoxContentExplorerProps,
];

const wallSheathingRequiredFields = [
  buildFieldKey("WallSheathingTypeAndThickness"),
];

export const silverGoldWallSheathingFormBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        wallSheathingFieldConfig(form),
        onValueChange,
        form,
        errors,
        wallSheathingRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
          WarningBanner: ReadonlyWarningBannerField,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

const continuousLoadPathFieldConfig = (
  form: Evaluation_HomeStandardEvaluationFormModel
) => [
  //11. Continuous Load Path
  {
    fieldKey: buildFieldKey("RoofToWallConnection"),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden: !(
      form.hazard === HazardTypes.Hurricane &&
      form.homeStandardEvaluation.eligibilityForm.designationLevel ===
        DesignationLevelTypes.Gold
    ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("WallAboveToWallBelowConnection"),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden: !(
      form.hazard === HazardTypes.Hurricane &&
      form.homeStandardEvaluation.eligibilityForm.designationLevel ===
        DesignationLevelTypes.Gold
    ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("LowerWallToFoundationConnection"),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden: !(
      form.hazard === HazardTypes.Hurricane &&
      form.homeStandardEvaluation.eligibilityForm.designationLevel ===
        DesignationLevelTypes.Gold
    ),
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("HoldDownsTensionTiesAtBuildingCorners"),
    defaultToUpload: false,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
    hidden: !(
      form.hazard === HazardTypes.Hurricane &&
      form.homeStandardEvaluation.eligibilityForm.designationLevel ===
        DesignationLevelTypes.Gold
    ),
  } as WildfireBoxContentExplorerProps,
];

const continuousLoadPathRequiredFields = [
  buildFieldKey("RoofToWallConnection"),
  buildFieldKey("LowerWallToFoundationConnection"),
  buildFieldKey("HoldDownsTensionTiesAtBuildingCorners"),
];

export const silverGoldContinuousLoadPathFormBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        continuousLoadPathFieldConfig(form),
        onValueChange,
        form,
        errors,
        continuousLoadPathRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
          WarningBanner: ReadonlyWarningBannerField,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

// Full form builder with all parts included
export const silverGoldComplianceGeneralInformationFormBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        [
          ...siteDesignInformationFields(form),
          ...windowsAndSkylightsFieldConfig(form),
          ...entryDoorsFieldConfig(form),
          ...garageFieldConfig(form),
          ...gableEndsFieldConfig(form),
          ...soffitsFieldConfig(form),
          ...chimneysFieldConfig(form),
          ...attachedStructuresFieldConfig(form),
          ...designPressureRatedOpeningsFieldConfig(form),
          ...wallSheathingFieldConfig(form),
          ...continuousLoadPathFieldConfig(form),
        ],
        onValueChange,
        form,
        errors,
        [
          ...siteDesignInformationRequiredFields,
          ...windowsAndSkylightsRequiredFields,
          ...entryDoorsRequiredFields,
          ...garageRequiredFields,
          ...gableEndsRequiredFields,
          ...soffitsRequiredFields,
          ...chimneysRequiredFields,
          ...attachedStructuresRequiredFields,
          ...designPressureRatedOpeningsRequiredFields,
          ...wallSheathingRequiredFields,
          ...continuousLoadPathRequiredFields,
        ],
        {
          FileUploader: WildfireBoxContentExplorer,
          WarningBanner: ReadonlyWarningBannerField,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

// Full form builder with all parts included, for Iteration Engine
export const silverGoldComplianceGeneralInformationFormIterationEngineBuilder =
  (
    factory: SchemaFactoryV2,
    beforeBuild?: (fields: BaseFieldProps<any>[]) => BaseFieldProps<any>[]
  ): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
    return ((onValueChange, form, errors) => {
      let fields = [
        ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
          modelName,
          [
            ...siteDesignInformationFields(form),
            ...windowsAndSkylightsFieldConfig(form),
            ...entryDoorsFieldConfig(form),
            ...garageFieldConfig(form),
            ...gableEndsFieldConfig(form),
            ...soffitsFieldConfig(form),
            ...chimneysFieldConfig(form),
            ...attachedStructuresFieldConfig(form),
            ...designPressureRatedOpeningsFieldConfig(form),
            ...wallSheathingFieldConfig(form),
            ...continuousLoadPathFieldConfig(form),
          ],
          onValueChange,
          form,
          errors,
          [
            ...siteDesignInformationRequiredFields,
            ...windowsAndSkylightsRequiredFields,
            ...entryDoorsRequiredFields,
            ...garageRequiredFields,
            ...gableEndsRequiredFields,
            ...soffitsRequiredFields,
            ...chimneysRequiredFields,
            ...attachedStructuresRequiredFields,
            ...designPressureRatedOpeningsRequiredFields,
            ...wallSheathingRequiredFields,
            ...continuousLoadPathRequiredFields,
          ],
          {
            FileUploader: WildfireBoxContentExplorer,
            WarningBanner: ReadonlyWarningBannerField,
          }
        ),
      ];
      if (beforeBuild) {
        fields = beforeBuild(fields);
      }
      return fields;
    }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
  };

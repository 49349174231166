import { Button, Intent } from "@blueprintjs/core";
import React, { useContext, useEffect, useState } from "react";
import { ChangeRequestStatus } from "../../../../foritfied/types/evaluation/ChangeRequest";
import { AppToaster } from "../../../../foritfied/components/Toast/Toast";
import {
  fieldTypes,
  PermissionsContext,
  PermissionsContextProps,
  RichTextEditorField,
  RichTextEditorValueType,
} from "@ucl/library";
import "./styles.scss";
import { ChangeRequestV2 } from "../types/ChangeRequestV2";
import { IconNames } from "@blueprintjs/icons";
import { CommentModeSelector } from "../../../../foritfied/components/Comment/CommentModeSelector";
import { ToolbarSettingsModel } from "@syncfusion/ej2-dropdowns";
import { useFeatureToggle } from "../../../customHooks/useFeatureToggle";
import { openCommentTemplateBrowseDialog } from "../../../../fortifiedV2/Common/components/Dialogs/CommentTemplate/CommentTemplateBrowseDialog";
import { getFormTypeFromField } from "../../../../common/types/FormTypes";
import { permissionStore } from "../../../../foritfied/stores/PermissionStore";

export interface IterationEngineCommentInputProps {
  canResolveChangeRequests: boolean;
  resolveChangeRequest: (changeRequestId: string) => Promise<void>;
  onUnresolveChangeRequest: (changeRequestId: string) => Promise<void>;
  onSubmitComment?: (
    message: string,
    evaluationFieldKey: string,
    evaluationFieldLabel: string,
    isPublic: boolean
  ) => Promise<void>;
  changeRequest?: ChangeRequestV2;
  hasAssociatedChangeRequests: boolean;
  selectedField?: fieldTypes.CoreFieldProps;
  currentKey: string | undefined;
  moveToNextChangeRequest: () => void;
  getChangeRequests: (() => Promise<void>) | undefined;
}

const IterationEngineCommentInput: React.FC<
  IterationEngineCommentInputProps
> = (props) => {
  const { hasPermission } = useContext(
    PermissionsContext
  ) as PermissionsContextProps<PermissionsKey>;

  const { areInternalCommentsEnabled, isFHAuditorCommentTemplateEnabled } =
    useFeatureToggle();

  const hasInternalCommentPermisson =
    permissionStore.canViewCommentModeSelector ||
    hasPermission("CanSelectCommentVisiblity");

  const canViewInternalComments =
    hasInternalCommentPermisson && areInternalCommentsEnabled;

  const [isResolvingOrUnResolving, setisResolvingOrUnResolving] =
    useState(false);

  const [isSavingComment, setisSavingComment] = useState(false);

  const [unsavedComment, setUnsavedComment] = useState("");

  // Comments default to public for all users
  const [isPublic, setIsPublic] = useState<boolean>(true);

  useEffect(() => {
    setUnsavedComment("");
  }, [props.selectedField]);

  const showResolveButton =
    props.changeRequest &&
    props.changeRequest.status !== ChangeRequestStatus.Resolved &&
    props.canResolveChangeRequests;

  const showUnresolveButton =
    props.changeRequest &&
    props.changeRequest.status === ChangeRequestStatus.Resolved &&
    props.canResolveChangeRequests;

  const fieldLabelToSave = props.hasAssociatedChangeRequests
    ? props.changeRequest?.evaluationFieldLabel
    : props.selectedField?.label;

  // Handlers
  const handleResolveClick = async () => {
    setisResolvingOrUnResolving(true);
    if (!props.changeRequest) {
      return;
    }

    await props
      .resolveChangeRequest(props.changeRequest.id)
      .then(() => {
        props.moveToNextChangeRequest();
        AppToaster.show({
          message: "Change Request Resolved Successfully!",
          intent: "success",
        });
      })
      .catch(() => {
        AppToaster.show({
          message: "Error resolving change request",
          intent: "danger",
        });
      })
      .finally(() => {
        setisResolvingOrUnResolving(false);
      });
  };

  const handleUnresolveClick = async () => {
    if (!props.changeRequest) {
      return;
    }

    setisResolvingOrUnResolving(true);
    await props
      .onUnresolveChangeRequest(props.changeRequest.id)
      .then(() => {
        AppToaster.show({
          message: "Change Request Unresolved Successfully!",
          intent: "success",
        });
      })
      .catch(() => {
        AppToaster.show({
          message: "Error Unresolving change request",
          intent: "danger",
        });
      })
      .finally(() => {
        setisResolvingOrUnResolving(false);
      });
  };

  const handleSubmitComment = async () => {
    if (props.onSubmitComment) {
      setisSavingComment(true);
      await props
        .onSubmitComment(
          unsavedComment,
          props.currentKey || "",
          fieldLabelToSave || "",
          isPublic
        )
        .then(() => {
          if (isPublic) {
            props.getChangeRequests && props.getChangeRequests();
          }
        });
      setUnsavedComment("");
      setisSavingComment(false);
    }
  };

  const formType = getFormTypeFromField(
    props.selectedField?.fieldKey || props.currentKey || ""
  );

  return (
    <div className="comment-input">
      <div className="comment-input-buttons">
        {props.hasAssociatedChangeRequests && (
          <>
            {showResolveButton && (
              <Button
                text="Resolve"
                className="comment-input_resolve-button"
                disabled={isResolvingOrUnResolving}
                loading={isResolvingOrUnResolving}
                intent={Intent.SUCCESS}
                onClick={async () => await handleResolveClick()}
                icon={IconNames.Tick}
              />
            )}
            {showUnresolveButton && (
              <Button
                text="Unresolve"
                intent={Intent.DANGER}
                className="comment-input_unresolve-button"
                disabled={isResolvingOrUnResolving}
                loading={isResolvingOrUnResolving}
                onClick={async () => await handleUnresolveClick()}
                icon={IconNames.DISABLE}
              />
            )}
          </>
        )}
        {canViewInternalComments && (
          <CommentModeSelector
            isPublic={isPublic}
            setIsPublic={setIsPublic}
            isPublicDisabled={false}
          />
        )}
      </div>
      <RichTextEditorField
        className="comment-textarea"
        valueType={RichTextEditorValueType.html}
        value={unsavedComment}
        onSubmit={(value) => {
          setUnsavedComment(value ?? "");
        }}
        richTextEditorSettings={{
          height: "200px",
          placeholder: "Add a new comment...",
          enableHtmlSanitizer: true,
          autoSaveOnIdle: true,
          saveInterval: 50,
          toolbarSettings: {
            items: [
              "OrderedList",
              "UnorderedList",
              "Bold",
              "Underline",
              "Italic",
              "StrikeThrough",
              "Formats",
              "ClearFormat",
            ],
          } as ToolbarSettingsModel,
          pasteCleanupSettings: {
            prompt: false,
            plainText: false,
            keepFormat: true,
            allowedStyleProps: [""],
            deniedAttrs: ["class", "id", "style"],
          },
        }}
      />
      <div className="comment-input_actions">
        {formType && isFHAuditorCommentTemplateEnabled && (
          <Button
            text="Browse Templates"
            intent="none"
            className="comment-input__submit-button"
            icon={IconNames.FolderOpen}
            onClick={() => {
              openCommentTemplateBrowseDialog(formType, setUnsavedComment);
            }}
          />
        )}
        <Button
          text="Submit Comment"
          intent="primary"
          className="comment-input__submit-button"
          disabled={!unsavedComment.trim() || isResolvingOrUnResolving}
          loading={isSavingComment}
          onClick={async () => await handleSubmitComment()}
        />
      </div>
    </div>
  );
};

export default IterationEngineCommentInput;

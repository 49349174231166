import { PrimaryCard } from "@ucl/library";
import { useContext, useEffect, useState } from "react";
import { Loading } from "@ucl/library/lib/components/Loading/Loading";
import classNames from "classnames";
import { EvaluationStep } from "../../../../../../foritfied/types/evaluation/Evaluation";
import HomeStandardRedesignationForm from "../RedesignationForm/Form/HomeStandardRedesignationForm";
import { UnsavedChangesBlockerContext } from "../../../../../../wildfire/components/Provider/UnsavedChangesBlockerContext";
import HomeStandardRedesignationHomeownerAuthorizationForm from "../HomeownerAuthorizationForm/Form/HomeStandardRedesignationHomeownerAuthorizationForm";

interface HomeStandardRedesignationIterationEngineFormsProps {
  selectedTabId: string;
  evaluationId: string;
  evaluationStep: EvaluationStep;
  onFormSave: (fieldKey: string) => Promise<void>;
  onAllFormsInitialized: () => void;
  fieldsContainerClassName: string;
}

const HomeStandardRedesignationIterationEngineForms: React.FC<
  HomeStandardRedesignationIterationEngineFormsProps
> = (props) => {
  const [redesignationForm, setRedesignationForm] = useState<boolean>(false);

  const [
    homeownerAuthorizationFormInitiaized,
    setHomeownerAuthorizationFormInitiaized,
  ] = useState<boolean>();

  const [allFormsInitialized, setAllFormsInitialized] = useState(false);

  useEffect(() => {
    const formsInitialized =
      redesignationForm || homeownerAuthorizationFormInitiaized;

    if (formsInitialized) {
      setTimeout(() => {
        setAllFormsInitialized(true);
        props.onAllFormsInitialized();
      }, 600);
    } else {
      setAllFormsInitialized(false);
    }
  }, [redesignationForm, homeownerAuthorizationFormInitiaized]);

  const { setHasUnsavedChanges } = useContext(UnsavedChangesBlockerContext);

  const getFormAndHeaderContent = () => {
    switch (props.selectedTabId) {
      case "HomeStandardRedesignationHomeownerAuthorizationForm":
        return {
          headerContent: "Homeowner Authorization Form",
          form: (
            <HomeStandardRedesignationHomeownerAuthorizationForm
              evaluationId={props.evaluationId}
              isIterationEnginePage={true}
              hideFormControls={true}
              onInitialized={() => {
                setHomeownerAuthorizationFormInitiaized(true);
              }}
              onFormSave={async (fieldKey) => {
                props.onFormSave(fieldKey);
              }}
              setHasUnsavedChanges={setHasUnsavedChanges}
            />
          ),
        };
      default:
        return {
          headerContent: "Redesignation Form",
          form: (
            <HomeStandardRedesignationForm
              evaluationId={props.evaluationId}
              isIterationEnginePage={true}
              hideFormControls={true}
              onInitialized={() => {
                setRedesignationForm(true);
              }}
              onFormSave={async (fieldKey) => {
                props.onFormSave(fieldKey);
              }}
              setHasUnsavedChanges={setHasUnsavedChanges}
            />
          ),
        };
    }
  };

  const { headerContent, form } = getFormAndHeaderContent();

  return (
    <>
      {!allFormsInitialized && <Loading />}
      <div
        className={classNames(props.fieldsContainerClassName, {
          "evaluation-forms-container-initialized": allFormsInitialized,
        })}
      >
        <PrimaryCard headerContent={headerContent}>{form}</PrimaryCard>
      </div>
    </>
  );
};

export default HomeStandardRedesignationIterationEngineForms;

import { PrimaryCard, SecondaryCard } from "@ucl/library";
import { Loading } from "@ucl/library/lib/components/Loading/Loading";
import { Navigate } from "react-router-dom";
import FormChecklistProgress from "../../../../../../Common/components/Forms/FormChecklistProgress/FormChecklistProgress";
import useHomeStandardBasicInformationFormContainer from "./useHomeStandardBasicInformationFormContainer";
import { HomeBasicInformationFormParts } from "../../../../../common/types/BaseHomeEvaluationBasicInformationFormModel";
import HomeStandardEvaluationBasicInformationForm from "../Form/HomeStandardEvaluationBasicInformationForm";
import { fortifiedHomeStandardRoutePrefix } from "../../../../../../../foritfied/pages/index/fortifiedHomeRouteConfig";
import { FieldSchemaFactoryProvider } from "../../../../../customHooks/useHomeFieldSchemaFactory";
import DuplicateAddressWarning from "../../../../../../../common/Components/Banners/DuplicateAddressWarning/DuplicateAddressWarning";
import GoToChecklistButton from "../../../../../common/components/Buttons/GoToChecklist/GoToIterationEngineButton";

export interface HomeStandardBasicInformationFormContainerProps {
  evaluationId: string;
  basicInformationFormPart?: HomeBasicInformationFormParts;
}

export const HomeStandardBasicInformationFormContainer = (
  props: HomeStandardBasicInformationFormContainerProps
) => {
  const {
    checklistParts,
    getApplicationPartChecklistStatus,
    setEvaluationFormModel,
    setHasUnsavedChanges,
    formPart,
    getBestFormPart,
    schemaFactory,
    hasDuplicateAddress,
    onFieldChange,
  } = useHomeStandardBasicInformationFormContainer(props);

  return (
    <>
      {!formPart ||
      !Object.values(HomeBasicInformationFormParts).includes(formPart) ? (
        // TODO: Show error page if the application form part is not valid
        <Navigate
          to={`${fortifiedHomeStandardRoutePrefix}/${
            props.evaluationId
          }/basic-information/${getBestFormPart().valueOf()}`}
        />
      ) : !schemaFactory ? (
        <Loading />
      ) : (
        <div className="wildfire-form-page">
          <div className="wildfire-form-progress">
            <div className="wildfire-form-progress__steps">
              <FormChecklistProgress
                checklistParts={checklistParts}
                evaluationId={props.evaluationId}
                formURL={`${fortifiedHomeStandardRoutePrefix}/${props.evaluationId}/basic-information`}
                title={"Basic Information"}
                activeFormPart={formPart}
                getChecklistStatus={getApplicationPartChecklistStatus}
              />
            </div>
          </div>
          <section className="wildfire-form-content">
            <div className="wildfire-form-content__header"></div>
            <PrimaryCard
              className="wildfire-form-primary-card"
              headerContent={
                <div className="wildfire-form-primary-card__header">
                  Basic Information:{" "}
                  {
                    checklistParts.find((value) => value.formPart === formPart)
                      ?.displayName
                  }
                  <GoToChecklistButton
                    evaluationId={props.evaluationId}
                    productTypeRoute={fortifiedHomeStandardRoutePrefix}
                  />
                </div>
              }
            >
              <DuplicateAddressWarning
                hasDuplicateAddress={hasDuplicateAddress}
                warningText={
                  "There is already a Fortified Home Evaluation for this address. Please ensure that you are not submitting a duplicate application."
                }
              />
              <SecondaryCard
                className="wildfire-form-secondary-card"
                hideBody={false}
                isCollapsible={false}
              >
                <FieldSchemaFactoryProvider schemaFactory={schemaFactory}>
                  <HomeStandardEvaluationBasicInformationForm
                    key={formPart}
                    evaluationId={props.evaluationId}
                    isIterationEnginePage={false}
                    onFormSave={onFieldChange}
                    formPart={formPart}
                    disableForm={false}
                    setFormModel={setEvaluationFormModel}
                    setHasUnsavedChanges={setHasUnsavedChanges}
                    formChecklistParts={checklistParts}
                  />
                </FieldSchemaFactoryProvider>
              </SecondaryCard>
            </PrimaryCard>
          </section>
        </div>
      )}
    </>
  );
};
export default HomeStandardBasicInformationFormContainer;

import {
  DateFieldValue,
  NumericFieldValue,
  SingleCheckboxFieldValue,
  TextFieldValue,
} from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { YesOrNoSelection } from "../../../../wildfire/types/FormFields";

export enum BaseHomeEvaluationInstallerComplianceChimneyFormParts {
  InstallerComplianceFormChimneyGeneralInformation = "installer-compliance-form-chimney-general-information",
  InstallerComplianceFormEngineeredChimney = "installer-compliance-form-engineered-chimney",
  InstallerComplianceFormPrescriptiveChimney = "installer-compliance-form-prescriptive-chimney",
}

export interface BaseHomeEvaluationInstallerComplianceChimneyFormModel
  extends IInstallerComplianceChimneyFormGeneralInformation,
    IInstallerComplianceFormEngineeredChimney,
    IInstallerComplianceFormPrescriptiveChimney {}

export interface IInstallerComplianceChimneyFormGeneralInformation {
  permitNumber?: TextFieldValue;
  dateInstallationWasCompleted?: DateFieldValue;
}

export interface IInstallerComplianceFormEngineeredChimney {
  attestation292?: SingleCheckboxFieldValue;
  attestation293?: SingleCheckboxFieldValue;
  attestation294?: SingleCheckboxFieldValue;
}

export interface IInstallerComplianceFormPrescriptiveChimney {
  areChimneysInstalledPerFCTD1?: YesOrNoSelection;
  attestation295?: SingleCheckboxFieldValue;
  attestation296?: SingleCheckboxFieldValue;
  attestation297?: SingleCheckboxFieldValue;
  strapManufacturerAndModel?: TextFieldValue;
  maxAllowableUpliftLoadPerStrapPerManufacturer?: NumericFieldValue;
}

export enum InstallerGableStudsBracedTypes {
  NoAdditionalBracing = 1,
  BracedByRetrofitStuds = 2,
  Other = 3,
}

import {
  DateFieldValue,
  EnumRadioFieldValue,
  EnumSingleSelectFieldValue,
  EnumMultiSelectFieldValue,
  NumericFieldValue,
  SingleCheckboxFieldValue,
  TextFieldValue,
} from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { WildfireBoxContentExplorerValue } from "../../../../wildfire/components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";

export enum BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts {
  BasicInformation = "basic-information",
  PermitInformation = "permit-information",
  SiteConditions = "site-conditions",
  RoofStructure = "roof-structure",
  RoofSheathing = "roof-sheathing",
  SealedRoofDeck = "sealed-roof-deck",
  DripEdge = "drip-edge",
  AtticVents = "attic-vents",
  PhotoVoltaicPanels = "photovoltaic-panels",
  SkyLights = "skylights",
}

export interface BaseHomeEvaluationRoofingComplianceGeneralInformationFormModel
  extends BasicInformation,
    PermitInformation,
    SiteConditions,
    RoofStructure,
    RoofSheathing,
    SealedRoofDeck,
    DripEdge,
    AtticVents,
    PhotoVoltaicPanels,
    SkyLights {}

interface BasicInformation {
  roofInstallationDate?: DateFieldValue;
  distanceToSaltWaterShoreline?: EnumSingleSelectFieldValue;
  numberOfDifferentRoofCoverTypes?: NumericFieldValue;
  wasPermitIssued?: EnumRadioFieldValue;
}

interface PermitInformation {
  permitNumber?: TextFieldValue;
  permitDate?: DateFieldValue;
  permitIssuedBy?: TextFieldValue;
  dateOfFinalBldgDeptInspection?: DateFieldValue;
}

interface SiteConditions {
  buildingCodeAndEdition?: TextFieldValue;
  asce7Edition?: EnumSingleSelectFieldValue;
  ultimateSiteDesignWindSpeed?: NumericFieldValue;
  siteExposureCategory?: EnumSingleSelectFieldValue;
  meanRoofHeight?: NumericFieldValue;
}

interface RoofStructure {
  maxRoofStructuralMemberSpacing?: NumericFieldValue;
  maximumRoofStructuralMemberSpacingPhoto?: WildfireBoxContentExplorerValue;
}

interface RoofSheathing {
  roofSheathingTypes?: EnumMultiSelectFieldValue;
  roofSheathingTypeWhenOther?: TextFieldValue;
  minimumRoofSheathingThickness?: NumericFieldValue;
  minimumRoofSheathingThicknessPhotos?: WildfireBoxContentExplorerValue;
  selectedStandardDetailsThatMatchesInstalledSheathingAttachments?: EnumMultiSelectFieldValue;
  roofSheathingFastenerTypePhoto?: WildfireBoxContentExplorerValue;
  roofSheathingAttachmentPhoto?: WildfireBoxContentExplorerValue;
  doesRoofDeckAttachmentNotMatchFortifiedPrescribedRequirements?: SingleCheckboxFieldValue;
  roofDeckAttachmentEngineeringAnalysisRequiredDocumentation?: WildfireBoxContentExplorerValue;
}

interface SealedRoofDeck {
  isEntireRoofLowSloped?: EnumRadioFieldValue;
  selectedStandardDetailsThatMatchesSealedRoofDeck?: EnumMultiSelectFieldValue;
  doesSealedRoofDeckNotMatchFortifiedPrescribedRequirements?: SingleCheckboxFieldValue;
  sealedRoofDeckTapedSeamsAndUnderlaymentInstallationPhotos?: WildfireBoxContentExplorerValue;
  sealedRoofDeck2PlyInstallationPhotos?: WildfireBoxContentExplorerValue;
  sealedRoofDeckSelfAdheredMembraneInstallationPhotos?: WildfireBoxContentExplorerValue;
  sealedRoofDeckSprayFoamInstallationPhotos?: WildfireBoxContentExplorerValue;
  sealedRoofDeckTapedSeamsWithTileInstallationPhotos?: WildfireBoxContentExplorerValue;
  sealedRoofDeckSelfAdheredMembraneWithTileInstallationPhotos?: WildfireBoxContentExplorerValue;
  sealedRoofDeckUnderlaymentLabelPhotos?: WildfireBoxContentExplorerValue;
  sealedRoofDeckNoneOfTheAbovePhotos?: WildfireBoxContentExplorerValue;
  sealedRoofDeckClosedCellFoamDocumentation?: WildfireBoxContentExplorerValue;
  fullyInstalledRoofCoverPhotos?: WildfireBoxContentExplorerValue;
}

interface DripEdge {
  areInstalledDripEdgeNewAndCodeCompliantMaterials?: EnumRadioFieldValue;
  standardDetailThatMatchesDripEdgeInstallation?: EnumSingleSelectFieldValue;
  newDripEdgeInstallationPhotos?: WildfireBoxContentExplorerValue;
  wasNewDripEdgeAndRoofFlashingInstalledPerManufacturerSpecifications?: EnumRadioFieldValue;
  isDripEdgeInstalled?: EnumRadioFieldValue;
  existingDripEdgeInstallationPhotos?: WildfireBoxContentExplorerValue;
}

interface AtticVents {
  areRoofMountedVentsOrGableEndsPresent?: EnumRadioFieldValue;
  roofVentilation?: EnumMultiSelectFieldValue;
  doAllRoofMountedVentsMeetTAS100A?: EnumRadioFieldValue;
  roofVentilationPhoto?: WildfireBoxContentExplorerValue;
  areAllRoofMountedVentsInstalledPerManufacturerRequirements?: EnumRadioFieldValue;
  doAllGableEndVentsHaveARemovablePerFortified2020HomeStandard?: EnumRadioFieldValue;
  gableEndsVentsPhoto?: WildfireBoxContentExplorerValue;
}

interface PhotoVoltaicPanels {
  areRoofMountedPhotoVoltaicPanelsPresent?: EnumRadioFieldValue;
  werePVPanelsInstalledAndAnchoredPerAnchorageDetails?: EnumRadioFieldValue;
  pvPanelImpactRating?: EnumSingleSelectFieldValue;
  pvAnchorageDocumentation?: WildfireBoxContentExplorerValue;
  pvHailRatingDocumentation?: WildfireBoxContentExplorerValue;
}

interface SkyLights {
  areSkylightsPresent?: EnumRadioFieldValue;
  skylightExistsAndRatingsAndInstallationAreUnknown?: SingleCheckboxFieldValue;
  wereSkylightsInstalledPerManufacturerInstructions?: EnumRadioFieldValue;
  skylightImpactRating?: EnumSingleSelectFieldValue;
  skylightHailRatingPhotos?: WildfireBoxContentExplorerValue;
  skylightHailRatingDocumentation?: WildfireBoxContentExplorerValue;
}

export enum FortifiedHomeASCECodeTypes {
  ASCE710 = 4,
  ASCE716 = 5,
  ASCE722 = 6,
}

export enum RoofSheathingTypes {
  Plywood = 1,
  OSB = 2,
  WoodBoardDimensionalLumber = 3,
  Other = 4,
}

export enum StandardDetailSheathingAttachmentTypes {
  FRS1 = 1,
  FRS2 = 2,
  FRS3 = 3,
  FRR3 = 4,
  FRR4 = 5,
  FRR5 = 6,
  FSRD1 = 7,
  NoneOfTheAbove = 8,
}

export enum StandardDetailSealedRoofDeckCoverTypes {
  FSRD1 = 1,
  FSRD2 = 2,
  FSRD3 = 3,
  FSRD4 = 4,
  FSRD5 = 5,
  FSRD6 = 6,
  FSRD7 = 7,
  FSRD8 = 8,
  NoneOfTheAbove = 9,
}

export enum StandardDetailEdgeInstallationTypes {
  FDE1 = 1,
  FDE3 = 2,
  NoneOfTheAbove = 3,
}

export enum AtticVentTypes {
  RoofMountedVents = 1,

  GableEndsVents = 2,
}

export enum SkyLightImpactRatingTypes {
  /**
   * ASTM E1886 and ASTM E1996 missile B,C,D, or E
   * External Source: ASTM E1886 and ASTM E1996 missile B,C,D, or E
   */
  ASTM_E1886_E1996_Missile = 1,

  /**
   * ANSI/FM 4431 w Severe Hail Rating
   * External Source: ANSI/FM 4431 w Severe Hail Rating
   */
  ANSI_FM4431_SevereHail = 2,

  /**
   * Miami-Dade County NOA
   * External Source: Miami-Dade County NOA
   */
  MiamiDadeCountyNOA = 3,

  /**
   * none of the above
   * External Source: none of the above
   */
  NoneOfTheAbove = 4,
}

export enum RoofCoverMinSlopeTypes {
  MinSlope012 = 1,
  MinSlope112 = 2,
  MinSlope212 = 3,
  MinSlope312 = 4,
  MinSlope412 = 5,
  MinSlope512 = 6,
  MinSlope612 = 7,
  MinSlope712 = 8,
  MinSlope812 = 9,
}

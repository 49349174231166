import { Classes, Tab, Tabs } from "@blueprintjs/core";
import { WildfireUserGrid } from "../Grid/UserGrid/WildfireUserGrid";
import { WildfireCommentTemplateGrid } from "../Grid/CommentTemplateGrid/WildfireCommentTemplateGrid";
import { featureToggleApiClient } from "../../../common/Components/FeatureToggle/class/featureToggleApiClient";
import { useEffect, useState } from "react";
import { FeatureToggleSet } from "../../../common/Components/FeatureToggle/FeatureToggles";

export interface WildfireAdminTabsProps {}

export const WildfireAdminTabs: React.FC<WildfireAdminTabsProps> = ({}) => {
  const [featureToggles, setFeatureToggles] = useState<FeatureToggleSet>();

  const getFeatureToggles = async () => {
    const toggles = await featureToggleApiClient.getFeatureToggleSet();

    setFeatureToggles(toggles);
  };

  useEffect(() => {
    getFeatureToggles();
  }, []);

  return (
    <Tabs
      large
      animate
      id="wildfire-admin-tabs"
      renderActiveTabPanelOnly
      className={`${Classes.FOCUS_DISABLED} admin-tabs`}
    >
      <Tab id="users" title="Users" panel={<WildfireUserGrid />} />
      {featureToggles?.FHAuditorCommentTemplate && (
        <Tab
          id="comment-templates"
          title="Comment Templates"
          panel={<WildfireCommentTemplateGrid />}
        />
      )}
    </Tabs>
  );
};

import React from "react";
import { Button, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { permissionStore } from "../../../../../../foritfied/stores/PermissionStore";
import { dialogsViewerStore } from "../../../../../../foritfied/stores/DialogsViewerStore";
import { CommentType } from "../../../../../../common/apiClients/comments/types";
import { BaseEvaluation } from "../../../../../Common/types/Evaluation/BaseEvaluation";

interface ViewInternalNotesButtonProps {
  evaluation: BaseEvaluation;
}

const ViewInternalNotesButton: React.FC<ViewInternalNotesButtonProps> = (
  props
) => {
  return (
    <>
      {permissionStore.canViewInternalNotes && (
        <Button
          text="View Internal Notes"
          intent={Intent.PRIMARY}
          icon={IconNames.Comment}
          large
          onClick={() => {
            dialogsViewerStore.setIsCommentsDialogOpen(true, {
              evaluationId: props.evaluation.id,
              fortifiedId: props.evaluation?.fortifiedId || "",
              productType_AsString:
                props.evaluation?.productType_AsString || "",
              commentType: CommentType.Auditor,
            });
          }}
        ></Button>
      )}
    </>
  );
};

export default ViewInternalNotesButton;

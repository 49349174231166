import {
  EvaluationStatus,
  EvaluationStep,
} from "../../../../foritfied/types/evaluation/Evaluation";

export enum ProductSubtypes {
  CommercialEvaluation = 1,
  CommercialRedesignation = 2,
  MultifamilyEvaluation = 3,
  MultifamilyRedesignation = 4,
  HomeStandardEvaluation = 5,
  HomeStandardRedesignation = 6,
  HomeNCIUAEvaluation = 7,
  HomeNCIUARedesignation = 8,
}

export interface BaseEvaluation {
  id: string;
  step: EvaluationStep;
  status_AsString: EvaluationStatus;
  fortifiedId?: string;
  approvedAt?: Date;
  isApproved?: boolean;
  expiresAt?: string;
  auditorId?: string;
  externalFolderId?: string;
  isWithdrawn?: boolean;
  hasFinalDetermination?: boolean;
  auditorNotes: string;
  productType_AsString: string;

  //FC FMF Fields
  isPaymentApplicable: boolean;
  hasPaymentBeenSubmitted: boolean;
  isComplexCommercialEvaluation: boolean;
  hasFCFMFPaymentWorkflow: boolean;
  isComplexCommercialEvaluationBuilding: boolean;
  complexCommercialAndMultifamilyEvaluationId: string;
  areBuildingsFinishedBeingProcessed: boolean;

  buildingAddress_AsString: string;
  shouldShowAutoRejectBanner: boolean;
  isRedesignation: boolean;
  previousEvaluationExpirationDateType_AsString: string;
  hazard: HazardTypes;
  hasDuplicateAddress: boolean;
}

export enum HazardTypes {
  None = 0,
  Hurricane = 1,
  HighWind = 2,
}

import { Classes, Tab, Tabs } from "@blueprintjs/core";
import { PaymentCreditGrid } from "../components/PaymentCreditGrid/PaymentCreditGrid";
import { UserGrid } from "../components/UserGrid/UserGrid";
import { CommentTemplatesGrid } from "../components/CommentTemplatesGrid/CommentTemplatesGrid";
import { useEffect, useState } from "react";
import { FeatureToggleSet } from "../../common/Components/FeatureToggle/FeatureToggles";
import { featureToggleApiClient } from "../../common/Components/FeatureToggle/class/featureToggleApiClient";

export interface AdminTabsProps {}

export const AdminTabs: React.FC<AdminTabsProps> = ({}) => {
  const [featureToggles, setFeatureToggles] = useState<FeatureToggleSet>();

  const getFeatureToggles = async () => {
    const toggles = await featureToggleApiClient.getFeatureToggleSet();

    setFeatureToggles(toggles);
  };

  useEffect(() => {
    getFeatureToggles();
  }, []);

  return (
    <Tabs
      large
      animate
      id="admin-tabs"
      renderActiveTabPanelOnly
      className={`${Classes.FOCUS_DISABLED} admin-tabs`}
    >
      <Tab id="users" title="Users" panel={<UserGrid />} />
      <Tab
        id="paymentcredits"
        title="Payment Credits"
        panel={<PaymentCreditGrid />}
      />
      {featureToggles?.FHAuditorCommentTemplate && (
        <Tab
          id="comment-templates"
          title="Comment Templates"
          panel={<CommentTemplatesGrid />}
        />
      )}
    </Tabs>
  );
};

import {
  FieldSchemaFormBuilderV2,
  NumericFieldProps,
  SchemaFactoryV2,
} from "@ucl/library";
import { Evaluation_HomeStandardEvaluationFormModel } from "../../../types/HomeEvaluationFormModel";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import {
  WildfireBoxContentExplorer,
  WildfireBoxContentExplorerProps,
} from "../../../../../../wildfire/components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";
import { get } from "lodash";
import {
  ReadonlyWarningBannerField,
  ReadonlyWarningBannerFieldProps,
} from "../../../../../Common/components/Fields/ReadonlyWarningBanner/ReadonlyWarningBannerField";
import { permissionStore } from "../../../../../../foritfied/stores/PermissionStore";

const modelName = "Evaluation";
const subModelName = "HomeStandardEvaluation";
const formName = "EngineeringComplianceGeneralInformationForm";

const buildFieldKey = (fieldName: string) =>
  `${modelName}__${subModelName}__${formName}__${fieldName}`;

const engineeringComplianceGeneralInformationFields = (
  form: Evaluation_HomeStandardEvaluationFormModel,
  errors?: { [key: string]: string[] }
) => [
  buildFieldKey("BuildingCodeAndEdition"),
  buildFieldKey("Asce7Edition"),
  {
    fieldKey: buildFieldKey("DesignWindSpeed"),
    format: "0",
    min: 0,
    max: 300,
    showIncrementButtons: false,
  } as NumericFieldProps,
  {
    fieldKey: buildFieldKey("DesignWindSpeedOver200WarningBanner"),
    hidden:
      !form.homeStandardEvaluation.engineeringComplianceGeneralInformationForm
        .designWindSpeed ||
      form.homeStandardEvaluation.engineeringComplianceGeneralInformationForm
        .designWindSpeed < 200,
  } as ReadonlyWarningBannerFieldProps,
  {
    fieldKey: buildFieldKey("DesignWindSpeedBelow115WarningBanner"),
    hidden:
      !form.homeStandardEvaluation.engineeringComplianceGeneralInformationForm
        .designWindSpeed ||
      form.homeStandardEvaluation.engineeringComplianceGeneralInformationForm
        .designWindSpeed > 115,
  } as ReadonlyWarningBannerFieldProps,
  buildFieldKey("ExposureCategory"),
  {
    fieldKey: buildFieldKey("Zone1CAndCRoofPressuresPositive"),
    className: "display-inline",
    decimals: 1,
    format: "0.0",
    showIncrementButtons: false,
    hidden:
      form.homeStandardEvaluation.eligibilityForm
        .isProfessionalEngineerProvidingCAndCRoofUplifDesingPressures === 2,
  },
  {
    fieldKey: buildFieldKey("Zone1CAndCRoofPressuresNegative"),
    className: "display-inline",
    decimals: 1,
    format: "###.#",
    showIncrementButtons: false,
    hidden:
      form.homeStandardEvaluation.eligibilityForm
        .isProfessionalEngineerProvidingCAndCRoofUplifDesingPressures === 2,
  },
  {
    fieldKey: buildFieldKey("Zone2CAndCRoofPressuresPositive"),
    className: "display-inline",
    decimals: 1,
    format: "###.#",
    showIncrementButtons: false,
    hidden:
      form.homeStandardEvaluation.eligibilityForm
        .isProfessionalEngineerProvidingCAndCRoofUplifDesingPressures === 2,
  },
  {
    fieldKey: buildFieldKey("Zone2CAndCRoofPressuresNegative"),
    className: "display-inline",
    decimals: 1,
    format: "###.#",
    showIncrementButtons: false,
    hidden:
      form.homeStandardEvaluation.eligibilityForm
        .isProfessionalEngineerProvidingCAndCRoofUplifDesingPressures === 2,
  },
  {
    fieldKey: buildFieldKey("Zone3CAndCRoofPressuresPositive"),
    className: "display-inline",
    decimals: 1,
    format: "###.#",
    showIncrementButtons: false,
    hidden:
      form.homeStandardEvaluation.eligibilityForm
        .isProfessionalEngineerProvidingCAndCRoofUplifDesingPressures === 2,
  },
  {
    fieldKey: buildFieldKey("Zone3CAndCRoofPressuresNegative"),
    className: "display-inline",
    decimals: 1,
    format: "###.#",
    showIncrementButtons: false,
    hidden:
      form.homeStandardEvaluation.eligibilityForm
        .isProfessionalEngineerProvidingCAndCRoofUplifDesingPressures === 2,
  },
  {
    fieldKey: buildFieldKey("CAndCRoofDesignUpliftPressuresDocumentation"),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "CAndCRoofDesignUpliftPressuresDocumentation.FileCount"
    ),
    hidden:
      form.homeStandardEvaluation.eligibilityForm
        .isProfessionalEngineerProvidingCAndCRoofUplifDesingPressures === 2,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
  } as WildfireBoxContentExplorerProps,
  buildFieldKey(
    "DidTheEngineerSpecifyTheRequiredWallWindPressuresForZone4And5"
  ),
  {
    fieldKey: buildFieldKey("Zone4CAndCWallWindPressuresPositive"),
    className: "display-inline",
    decimals: 1,
    format: "###.#",
    showIncrementButtons: false,
    hidden:
      form.homeStandardEvaluation.engineeringComplianceGeneralInformationForm
        .didTheEngineerSpecifyTheRequiredWallWindPressuresForZone4And5 === 2,
  },
  {
    fieldKey: buildFieldKey("Zone4CAndCWallWindPressuresNegative"),
    className: "display-inline",
    decimals: 1,
    format: "###.#",
    showIncrementButtons: false,
    hidden:
      form.homeStandardEvaluation.engineeringComplianceGeneralInformationForm
        .didTheEngineerSpecifyTheRequiredWallWindPressuresForZone4And5 === 2,
  },
  {
    fieldKey: buildFieldKey("Zone5CAndCWallWindPressuresPositive"),
    className: "display-inline",
    decimals: 1,
    format: "###.#",
    showIncrementButtons: false,
    hidden:
      form.homeStandardEvaluation.engineeringComplianceGeneralInformationForm
        .didTheEngineerSpecifyTheRequiredWallWindPressuresForZone4And5 === 2,
  },
  {
    fieldKey: buildFieldKey("Zone5CAndCWallWindPressuresNegative"),
    className: "display-inline",
    decimals: 1,
    format: "###.#",
    showIncrementButtons: false,
    hidden:
      form.homeStandardEvaluation.engineeringComplianceGeneralInformationForm
        .didTheEngineerSpecifyTheRequiredWallWindPressuresForZone4And5 === 2,
  },
  {
    fieldKey: buildFieldKey("CAndCWallDesignWindPressuresDocumentation"),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "CAndCWallDesignWindPressuresDocumentation.FileCount"
    ),
    hidden:
      form.homeStandardEvaluation.engineeringComplianceGeneralInformationForm
        .didTheEngineerSpecifyTheRequiredWallWindPressuresForZone4And5 === 2,
    canDelete: permissionStore.canDeleteFilesFromUploaderV2(form.step),
  } as WildfireBoxContentExplorerProps,
];

const engineeringComplianceGeneralInformationRequiredFields = [
  buildFieldKey("BuildingCodeAndEdition"),
  buildFieldKey("Asce7Edition"),
  buildFieldKey("DesignWindSpeed"),
  buildFieldKey("ShowWarning_DesignWindSpeedGreaterThan200"),
  buildFieldKey("ShowWarning_DesignWindSpeedBelow115"),
  buildFieldKey("ExposureCategory"),
  buildFieldKey("Zone1CAndCRoofPressuresPositive"),
  buildFieldKey("Zone1CAndCRoofPressuresNegative"),
  buildFieldKey("Zone2CAndCRoofPressuresPositive"),
  buildFieldKey("Zone2CAndCRoofPressuresNegative"),
  buildFieldKey("Zone3CAndCRoofPressuresPositive"),
  buildFieldKey("Zone3CAndCRoofPressuresNegative"),
  buildFieldKey(
    "DidTheEngineerSpecifyTheRequiredWallWindPressuresForZone4And5"
  ),
  buildFieldKey("Zone4CAndCWallWindPressuresPositive"),
  buildFieldKey("Zone4CAndCWallWindPressuresNegative"),
  buildFieldKey("Zone5CAndCWallWindPressuresPositive"),
  buildFieldKey("Zone5CAndCWallWindPressuresNegative"),
  buildFieldKey("CAndCRoofDesignUpliftPressuresDocumentation"),
  buildFieldKey("CAndCWallDesignWindPressuresDocumentation"),
];

// Full form builder with all parts included
export const engineeringComplianceGeneralInformationFormBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        engineeringComplianceGeneralInformationFields(form, errors),
        onValueChange,
        form,
        errors,
        engineeringComplianceGeneralInformationRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
          WarningBanner: ReadonlyWarningBannerField,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

// Full form builder with all parts included, for Iteration Engine
export const engineeringComplianceGeneralInformationFormIterationEngineBuilder =
  (
    factory: SchemaFactoryV2,
    beforeBuild?: (fields: BaseFieldProps<any>[]) => BaseFieldProps<any>[]
  ): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
    return ((onValueChange, form, errors) => {
      let fields = [
        ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
          modelName,
          engineeringComplianceGeneralInformationFields(form, errors),
          onValueChange,
          form,
          errors,
          engineeringComplianceGeneralInformationRequiredFields,
          {
            FileUploader: WildfireBoxContentExplorer,
            WarningBanner: ReadonlyWarningBannerField,
          }
        ),
      ];
      if (beforeBuild) {
        fields = beforeBuild(fields);
      }
      return fields;
    }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
  };
